import flatpickr from 'flatpickr';
import Cleave from 'cleave.js';

export default () => {
  if ($('.find-rp-dashboard').length) {
    window.rpCompDatepicker = flatpickr('#completion-date', {
      dateFormat: 'm/d/Y',
      allowInput: true
    });

    // eslint-disable-next-line no-unused-vars
    const dateInput = new Cleave('#completion-date', {
      date: true,
      delimiter: '/',
      datePattern: ['m', 'd', 'Y']
    });

    $('.js-toggle-course-submit').on('click', (e) => {
      e.preventDefault();
      $('.find-rp-dashboard-search').removeClass('-active');
      $('.find-rp-dashboard-submit').addClass('-active');
      $(e.currentTarget).hide();
      updateHeaderText('Program Details');
    });

    $('.js-cancel-coursework-submit').on('click', (e) => {
      e.preventDefault();
      $('.find-rp-dashboard-search').addClass('-active');
      $('.find-rp-dashboard-submit').removeClass('-active');
      updateHeaderText('Find a Program');
      $('.js-toggle-course-submit').show();
    });

    // auto tabbing for ssn fields
    $('#ssn input').on('keydown keyup', (e) => {
      const key = e.key,
            testAlpha = RegExp('[A-z]'),
            testNumeric = RegExp('[0-9]')
          ;

      if (key.length === 1 && testAlpha.test(key)) {
        e.preventDefault();
      }

      if (testNumeric.test(key) && e.type === 'keyup') {
        if ($(e.currentTarget).next().length !== 0) {
          $(e.currentTarget).next().focus();
        }
      }

    });
  }

  function updateHeaderText(text) {
    $('.find-rp-dashboard .dashboard-table-header-title').text(text);
  }

};