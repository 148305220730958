export default () => {

  const prefix = '.hero-verify-cfp';

  if ($(prefix).length) {
    $('.js-hero-reset').on('click', (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget),
            $fields = $this.parents(prefix + '-form')
              .find('input, select')
              .not('.button, [type="hidden"]');

      $fields.val('');

    });
  }

};
