export default () => {
  if ($('.hover-grid-item').length) {
    $('.hover-grid').map((i, component) => {

      $(component).find('.hover-grid-item').on('mouseenter mouseleave mousedown focus', (e) => {
        const $this = $(e.currentTarget);
        let cssPositioning = {
          opacity: 0
        };

        if ($this.hasClass('-link')) {
          $this.addClass('-hovered');
          cssPositioning = {
            top: $this[0].offsetTop,
            left: $this[0].offsetLeft,
            height: $this.height() + 'px',
            width: $this.width() + 'px',
            opacity: 1
          };
        }

        if (e.type === 'mouseleave') {
          cssPositioning.opacity = 0;
          $this.removeClass('-hovered');
        }

        $(component).find('.hover-grid-slidebar').css(cssPositioning);
      });

      // resets slidebar when mouseleaves
      $(component).find('.hover-grid-right-inner').on('mouseleave focusout', () => {
        $(component).find('.hover-grid-slidebar').css({
          opacity: 0
        });
      });
    });
  }
};
