/* eslint-disable no-unused-vars */
import CandidateDashboard from './candidate-dashboard';
import dashboardMobileTabs from '../dashboard-mobile-tabs/dashboard-mobile-tabs';
import dashboardTabs from '../subnav-tabs/subnav-tabs';

export default () => {
  // entry point for candidate dashboard
  // window.lastOpenedTab = 0;

  if ($('.hero-dashboard[data-status="candidate"]').length) {

    // dashboard mobile tabs functionality
    dashboardMobileTabs();
    // dashboard desktop tabs functionality
    dashboardTabs();

    const Cd = new CandidateDashboard();
    Cd.init();
  }

  if ($('.home-tracker-candidate').length) {
    const Cd = new CandidateDashboard(true);
    Cd.init();
  }

};