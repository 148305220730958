import tippy from 'tippy.js';

export default () => {
  if ($('.glossary').length) {
    const glossaryTerms = [];
    // grabbing glossary item terms and associated ids and building array to loop through
    $('.glossary-item-title').map((i, term) => {
      glossaryTerms.push({
        term: $(term).text().toLowerCase(),
        id: $(term).attr('id')
      });
    });

    // getting each section we want to search for terms in
    const contentSections = $('.code-standards-parent-list .wysiwyg-content');

    // adding prototype to string for finding index based on a regular expression,
    // so that we can find exact instance of the term vs just first match, i.e. material matching materially incorrectly
    String.prototype.regexIndexOf = function(regex, startpos) {
      const indexOf = this.substring(startpos || 0).search(regex);
      return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
    };

    // looping through the terms
    glossaryTerms.forEach(({ term, id }) => {
      // regex to match exact term, including if it's pluralized i.e. client's
      const expression = `(${term}+?)(\\b)`;
      const regTest = RegExp(expression);

      // loop through each content section
      contentSections.map((i, content) => {

        const text = $(content).html();

        // finding the term in the content
        if (regTest.test(text.toLowerCase())) {
          // we lowercase the term and then the content, saving to a variable
          // finding the terms location based on index and then inserting tooltip markup around the term and associated glossary id
          const string = text.toLowerCase(),
                termLength = term.length,
                startIndex = string.regexIndexOf(regTest),
                endIndex = startIndex + termLength,
                termInText = text.substring(startIndex, endIndex),
                startString = text.substring(0, startIndex),
                endString = text.substring(endIndex),
                insert = `<span class="tooltip-tippy" data-tp-id="${id}">${termInText}</span>`,
                replacement = startString.concat(insert, endString);
          // replacing existing markup with inserted tooltip
          $(content).html(replacement);
        }

      });
    });

    // adding tooltip listener with tippy
    tippy('.tooltip-tippy', {
      trigger: 'click',
      animation: 'translate',
      content(ref) {
        const id = $(ref).attr('data-tp-id'),
              $glossaryRef = $(`#${id}`),
              term = $glossaryRef.text(),
              // finding the glossary definition text
              innerText = $glossaryRef.next().find('*').text().replace(/(\r\n|\n|\r)/gm, ''),
              // trimming the text to 110 characters and not cutting words in half using a regex
              tooltip = innerText.replace(/^(.{110}[^\s]*).*/, '$1...');
        // the actual template markup for what the tooltip looks like
        const template = `
          <div class="tooltip-container -tippy">
            <div class="tooltip-inner">
              <a href="#" class="tooltip-close"><span class="sr-text">Close Tooltip</span></a>
              <div class="tooltip-title">${term}</div>
              <p class="-small -gray-dark">${tooltip}</p>
              <a href="#${id}" class="tooltip-glossary-link -gray-line">See full glossary</a>
            </div>

          </div>`;
        return template;
      }
    });
  }
};
