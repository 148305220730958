export default () => {
  $('.subnav-tabs-link').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget),
          itemIndex = $this.data('index'),
          tabSections = $('.dashboard-section-tabs').find('.subnav-tabs-desktop')
        ;
    $('.subnav-tabs-link').removeClass('-active');
    $this.addClass('-active');
    tabSections.attr('data-desktop-active', 'false');
    tabSections.eq(itemIndex).attr('data-desktop-active', 'true');

    // update mobile tabs
    window.lastOpenedTab = itemIndex;
    $('.content-accordion-item').removeClass('-open');
    const $dashSection = $('.subnav-tabs-desktop')
      .eq(window.lastOpenedTab);

    $dashSection
      .find('.content-accordion-item')
      .addClass('-open');

    $dashSection
      .find('.content-accordion-item-content')
      .css('display', 'block');
  });
};
