/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import SearchBase from '@components/search';
import flatpickr from 'flatpickr';
import gtmHelper from '@plugins/gtm-helper';

export default class FacetedSearchBase extends SearchBase {
  /**
   * Initialize the Search.
   */
  constructor(config, prefix = '') {
    // All available default query parameters.
    config.keywordParam       = 'q';
    config.pagerParam         = 'pg';

    super(config);
    this._pageClassModifiers(prefix);


    // Event callback for Fetch requests which builds the results and pager.
    $(this)
      // Build sorts
      .on('HandleResponse', this._buildSorts.bind(this))
    ;

    // UI based events
    $('html')
      // Facet changes
      .on('change', this.facetInputs, this._facetTrigger.bind(this))
      // Sort changes
      .on('change', this.sortSelect, this._sortingTrigger.bind(this))
      // clearing active filters/facets
      .on('click', `${this.activeFilters} .js-filter-clear`, this._clearActiveFilter.bind(this))
      // keyword searching
      .on('click', this.searchSubmit, this._keywordSearch.bind(this))
      .on('keyup', this.searchInput, (e) => {
        if (e.keyCode === 13) {
          $(this.searchSubmit).click();
        }
      })
      // mobile sorting trigger
      .on('click', '.faceted-search-results-mobile-sort-option', (e) => {
        e.preventDefault();
        const value = $(e.currentTarget).attr('data-value');
        $(this.sortSelect).val(value);
        $(this.sortSelect).trigger('change');
        $('.faceted-search-results-mobile-sort').hide();
        $('html').attr('data-sorting-open', 'false');
      })
      // click event to show/load more facets
      // updates amount to show in the facetCounts object
      // by name of facet and rebuilds the facets
      .on('click', this.showMoreFacets, (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget),
              facetName = $this.attr('data-name'),
              numberToAdd = parseInt($this.attr('data-number'))
            ;

        this.facetCounts[facetName] += numberToAdd;
        this._buildFacets();
      })
      // daterange search events
      .on('click', this.dateRangeSubmit, (e) => {

        const $inputs = $(e.currentTarget).parent().find('input[type="hidden"]');
        $inputs.map((index, input) => {

          const name = $(input).attr('name');
          this.query[name] = $(input).val();
        });

        this._paginationScrollTop(e);
        return this._updatePushState();
      })
      .on('click', this.clearAll, this._clearFilters.bind(this))
    ;

    // used for counting number of checkboxes and adding load more option
    this.facetCounts = {};

    this.paginationEvents();
  }

  _pageClassModifiers(prefix) {
    this.searchInput = prefix + ' .js-search-field';
    this.searchSubmit = prefix + ' .js-search-submit';

    this.keywordSubmitButton = prefix + ' .js-ce-search-submit';
    // container to add items to not sure if this is needed or should be different?
    this._searchContainer = prefix + ' .faceted-search-results-container';
    // container to add items to
    this._searchContainerItems = prefix + ' .faceted-search-results-container-listing';
    // container to show number of results
    this.searchResultsText = prefix + ' .faceted-search-results-heading-right-results span';
    // loader container
    this.searchLoading = prefix + ' .faceted-search-results-container .loading';
    this.noKeywords = prefix + ' .faceted-search-results-no-keywords';
    // no results message
    this.noResults = prefix + ' .faceted-search-results-none';
    this.paginationContainer = prefix + ' .faceted-search-results-container .pagination';
    // facet container
    this.facetContainer = prefix + ' .faceted-search-results-filters-facets';
    // facet inputs
    this.facetInputs = `${prefix} .faceted-search-results-filters-facets .-checks input,
                        ${prefix} .faceted-search-results-filters-facets .-radios input,
                        ${prefix} .faceted-search-results-filters-facets select`;
    // sort container
    this.sortContainer = prefix + ' .faceted-search-results-heading-sort';
    // sort select
    this.sortSelect = prefix + ' .faceted-search-results-heading-sort select';
    // active filters
    this.activeFilters = prefix + ' .faceted-search-results-container-filtered-by';
    this.activeFiltersLinks = prefix + ' .faceted-search-results-container-filtered-by-container';
    this.showActiveFilters = false;

    // show more facets
    this.showMoreFacets = prefix + ' .js-show-more-facets';
    this.showMoreOption = false;

    this.clearAll = `${prefix} .js-filter-clear-all`;
    // daterange
    this.dateRangeSubmit = prefix + ' .js-daterange-submit';
  }

  paginationEvents() {
    // UI based click events for pagination.

    $('html')
      .on('click', this.paginationContainer + ' a', this._paginationScrollTop.bind(this))
      .on('click', this.paginationContainer + ' .pagination-prev', this.previousPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-next', this.nextPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-pages a', this._paginationTrigger.bind(this))
    ;
  }

  /**
   * Triggers the updating of the search on facet click/change.
   *
   * @private
   * @method _facetTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _facetTrigger(event) {
    event.preventDefault();

    const element = $(event.target),
          {name, value, id} = element[0];
    this.lastFacetId = id;

    if (element.is(':checked')) {
      let rename = name;
      const formatNames = ['plain_live_sub_type', 'plain_ctt_code', 'plain_self_study_sub_type'];

      if (formatNames.indexOf(name) !== -1) {
        rename = 'format';
      }

      const dataMap = {
        event: 'Facet Selection',
        pageType: this.cssPrefix,
        facetGrouping: rename,
        facetValue: value
      };

      gtmHelper(dataMap);
    }

    const newValue = value.toLowerCase() === 'all' ? '' : value;
    this._helperUpdateFacet(name, newValue);

    $('html, body').animate({
      scrollTop: $(this.searchContainer).offset().top - 30
    }, 300);
  }

  /**
   * Builds an individual facet HTML element on load.
   *
   * @private
   * @method _buildFacetItem
   * @param { String } wrapper jQuery element.
   * @param { Object } facet The facet context for one option.
   */
  _buildFacetItem(wrapper, { enabled, intro, name, options, title, type }) {
    let data = [];

    // Default section modifier class on facet sections
    let sectionModifierClass = '';

    let initFlatpickr = false;

    // Don't build disabled facets or sub types
    if (!enabled || name.indexOf('sub_type') !== -1) {
      return;
    }

    /* eslint-disable indent */
    let loadMore = '';
    switch (type) {
      // Build checkbox facets
      case 'checkboxes':
        if (this.showMoreOption) {

          if (!Object.prototype.hasOwnProperty.call(this.facetCounts, name)) {
            this.facetCounts[name] = 8;
          }

          if (options.length > this.facetCounts[name]) {
            const difference = options.length - this.facetCounts[name];
            const numberLeft = difference > 25 ? 25 : difference;
            loadMore = `<a href="#" class="search-facet-show-more js-show-more-facets" data-name="${name}" data-number="${numberLeft}">Show ${numberLeft} More</a>`;
          }
        }

        data = data.concat('<div class="checks">', options.map(this._buildTypeCheckbox.bind(this, name)), '</div>');
        sectionModifierClass = 'checks';
        break;

      // Build select dropdown
      case 'select': {
        const selectLabel    = `<select id="${name}" name="${name}" title="${title}"><option value="" selected>Select ${name}</option>`;
        data = data.concat(selectLabel, options.map(this._searchBuildTypeSelectOption.bind(this, name)), '</select>');
        sectionModifierClass = 'select';
        break;
      }

      // build dateranger, specifically for ACH
      case 'daterange':
        data = data.concat(options.map(this._buildTypeDaterange.bind(this)));
        sectionModifierClass = 'daterange';
        loadMore = '<input class="button js-daterange-submit" type="submit" title="Search" value="Search">';
        initFlatpickr = true;
        break;
    }
    /* eslint-enable indent */

    // Join all of the individual facets created and store for injection into the wrapper
    const ouput = data.join('');

    // Add title to the facet section if it's not an empty string
    let titleMarkup = '';
    if (title !== '') {
      titleMarkup =  `<div class="search-facet-head">
                        <h6 class="h6">${title}</h6>
                        <a href="#" class="search-facet-toggle"><span class="sr-text">Toggle Dropdown</span></a>
                      </div>`;
    }

    let introMarkup = '';
    if (intro !== '') {
      introMarkup =  `<p class="-small -gray-dark">${intro}</p>`;
    }

    // Add the facet section to the wrapper
    wrapper.append(`
      <div class="search-facet -${sectionModifierClass} -${name}">
        ${titleMarkup}
        <div class="search-facet-dropdown">
          ${introMarkup}
          ${ouput}
          ${loadMore}
        </div>
      </div>
    `);

    if (initFlatpickr) {
      flatpickr('.js-flatpickr-input', {
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d'
      });
    }
  }

  /**
   * Formats and constructs an individual checkbox element.
   *
   * @private
   * @method _buildTypeCheckbox
   * @param { String } name The name of the facet.
   * @param { Object } item The facet option context.
   * @return { HTML } The formated HTML checkbox.
   */
  _buildTypeCheckbox(name, { label, value, count, tooltip = false, showcount = true }, index) {
    let displayProp = '';
    if (this.showMoreOption) {
      displayProp = index < this.facetCounts[name] ? '' : ' style="display: none;"';
    }

    const tooltipActive = tooltip.length > 0,
          tooltipClass = tooltipActive ? ' -tooltip' : '',
          tooltipMarkup = tooltipActive ? this._buildTooltip(tooltip) : '',
          labelHTML = `<label class="${tooltipClass}" for="${name}-${value}">${label}</label>${tooltipMarkup}`,
          isChecked = this._isFacetActive(name, value) ? ' checked' : '',
          contents  = `<input id="${name}-${value}" type="checkbox" name="${name}" value="${value}"${isChecked}>${labelHTML}`;
    if (isChecked) {
      const credits = name === 'credit_hours' ? ' credit hour(s)' : '';
      $(this.activeFiltersLinks).append(`
        <a href="#" class="-filter-button js-filter-clear" data-type="checkbox" data-id="${name}-${value}">${label}${credits}</a>
      `);
      this.showActiveFilters = true;
    }
    let childChecks = '',
        parent = '';

    // this facet has sub options that we loop through and add into another container within the checks container
    if (name === 'plain_ctt_code') {
      parent = ' -parent';
      const subName = `plain_${value}_sub_type`;
      const matchingObject = this.facets.find(facet => facet.name === subName);
      childChecks = matchingObject.options.map(this._buildTypeCheckbox.bind(this, subName)).join('');
    }

    return `<div class="check"${displayProp}><div class="search-facet-dropdown-label${parent}"><div>${contents}</div><span class="search-facet-option-count">${showcount === true ? this._commafy(count) : ''}</span></div>${childChecks}</div>`;
  }

  /**
   * Formats and constructs an individual checkbox element.
   *
   * @private
   * @method _searchBuildTypeSelect
   * @param { String } name The name of the facet.
   * @param { Object } item The facet option context.
   * @return { HTML } The formated HTML checkbox.
   */
  _searchBuildTypeSelectOption(name, { label, value }) {
    const selected = this._isFacetActive(name, value) ? ' selected' : '',
          contents = `<option name="${label}" value="${value}"${selected}>${label}</option>`;
    // adds a clear button for active select item
    if (selected) {
      $(this.activeFiltersLinks).append(`
        <a href="#" class="-filter-button js-filter-clear" data-type="select" data-id="${name}">${label}</a>
      `);
      this.showActiveFilters = true;
    }
    return contents;
  }

  /**
   * Formats and constructs 2 date fields for a date range
   *
   * @private
   * @method _searchBuildTypeDaterange
   */
  _buildTypeDaterange({name, label}) {

    const value = typeof this.query[name] !== 'undefined' && this.query[name] !== null ? this.query[name] : '';
    let formattedDate = '';
    if (value.length) {
      const dateArray = value.split('-');
      formattedDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      $(this.activeFiltersLinks).append(`
      <a href="#" class="-filter-button js-filter-clear" data-type="daterange" data-name="${name}" data-id="${value}">${label}: ${formattedDate}</a>
    `);
      this.showActiveFilters = true;
    }

    const template = `<div class="field-container -date">
                        <label for="decision_date_lte">${label}</label>
                        <input class="js-flatpickr-input" type="date" name="${name}" id="${name}" value="${value}" placeholder="mm / dd / yyy">
                      </div>`;
    return template;
  }

  /**
   * Formats and constructs an individual tooltip.
   *
   * @private
   * @method _buildTooltip
   * @param { String } tooltip The tooltip text
   */
  _buildTooltip(tooltip) {
    return `<div class="tooltip">
            <a href="#" class="tooltip-toggle"><span class="sr-text">Toggle Tooltip</span></a>
            <div class="tooltip-container">
              <div class="tooltip-inner">
                <a href="#" class="tooltip-close"><span class="sr-text">Close Tooltip</span></a>
                <p class="-small -gray-dark">${tooltip}</p>
              </div>
              <div class="tooltip-triangle"></div>
            </div>
          </div>`;
  }


  /* ========================================================================
   *  Sorting
   * ======================================================================*/

  /**
   * Builds the sorting HTML on load.
   *
   * @private
   * @method _buildSorts
   */
  _buildSorts() {
    const wrapper = $(this.sortSelect);
    wrapper.empty();
    $('.faceted-search-results-mobile-sort-inner').empty();
    this.sorts.map(this._buildSortItem.bind(this, wrapper));
  }

  /**
   * Builds the sorting select list.
   *
   * @private
   * @method _buildSortItem
   * @param { String } wrapper jQuery element.
   * @param { Object } sort The individual sort item.
   */
  _buildSortItem(wrapper, { label, order, value }) {
    const selected   = this._isFacetActive('s', value) ? 'selected' : '';
    wrapper.append(`<option value="${value}" data-order="${order}" ${selected}>${label}</option>`);
    const mobileWrapper = $('.faceted-search-results-mobile-sort-inner');
    mobileWrapper.append(`<a href="#" class="h6 faceted-search-results-mobile-sort-option${selected ? ' -active' : ''}" data-value="${value}">${label}</a>`);
  }

  /**
   * Trigger the search to update on sorting change.
   *
   * @private
   * @method _sortingTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _sortingTrigger(event) {
    event.preventDefault();

    const element = $(event.target),
          sort    = element[0].value,
          order   = element[0].selectedOptions[0].getAttribute('data-order');

    this._helperUpdateSorting(sort, order);
  }


  /* ========================================================================
   *  Clearing
   * ======================================================================*/
  /**

  /**
   * Clears an individual axctive filter on click
   *
   * @private
   * @method _clearActiveFilter
   */
  _clearActiveFilter(event) {
    event.preventDefault();
    event.stopPropagation();
    const $target = $(event.currentTarget);
    const facetType = $target.data('type');
    const id = $target.data('id');

    switch (facetType) {
    case 'all':
      $('.faceted-search-results-container-filtered-by-container a').not($target).click();
      break;
    case 'checkbox':
      $('#' + id).trigger('change');
      break;
    case 'select':
      $('#' + id).val('').trigger('change');
      break;
    case 'keyword':
      $('.' + id).val('');
      $(this.searchSubmit).click();
      break;
    case 'daterange':
      $('#' + $target.data('name')).val('');
      $('.js-daterange-submit').click();
      break;
    default:
      break;
    }
  }

  _buildActiveFilters() {
    // show the active filters if this variable is still true
    $(this.activeFiltersLinks).append('<a href="#" class="-filter-button -all js-filter-clear-all" data-type="all" data-id="all">Clear All</a>');
    // ACH Order Type Administrative alert
    if ($('#order_type-administrative').is(':checked')) {
      $(this.activeFiltersLinks).append('<article class="search-facet-alert -show"><span class="icon icon-info-triangle"></span>Case Histories of Administrative Orders are for information purposes only and should not be cited.</article>\n ');
    } else {
      // If unchecked, remove the class from the target div
      $('.search-facet-alert').removeClass('-show');
    }
    this.showActiveFilters ? $(this.activeFilters).show().css('opacity', 1) : $(this.activeFilters).hide();
  }


  /* ========================================================================
   *  Search Response
   * ======================================================================*/

  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {
    // empty active filters and reset boolean
    $(this.activeFiltersLinks).empty();
    this.showActiveFilters = false;

    const { results } = response,
          { keywords, firstResult, lastResult, totalResults } = this,
          $container = $(this._searchContainerItems),
          $resultsNum = $(this.searchResultsText),
          $noKeywords = $(this.noKeywords);


    // Empty out the current search results and stats.
    $container.empty();
    $resultsNum.empty();

    $resultsNum.html(this._commafy(totalResults));

    if (keywords) {
      const keywordsClean = decodeURIComponent(keywords);
      $noKeywords.hide();
      $(this.searchInput).val(keywordsClean);
      $(this.activeFiltersLinks).prepend($(`
        <a href="#" class="-filter-button js-filter-clear" data-type="keyword" data-id="js-search-field"></a>
      `).text(keywordsClean));
      this.showActiveFilters = true;
    } else {
      $(this.searchInput).val('');
    }

    // Stop if there are no results to show.
    if (!results.length) {
      $(this.noResults).show();
      $(this.sortContainer).hide();
      $(this.paginationContainer).hide();
      return;
    }

    // Hide no results message
    $(this.noResults).hide();
    $(this.sortContainer).show();

    this._templateResults(results);

    // Show show more button on Anonymous Case History result items
    $('.ach-results-item').each(function() {
      const textBlock = $(this).find('.ach-results-item-summary');
      const showMoreButton = $(this).find('.show-more');

      // Check if more than 4 lines, then show the button
      if (textBlock[0].scrollHeight > textBlock[0].clientHeight) {
        showMoreButton.show();
      }

      showMoreButton.on('click', function() {
        textBlock.toggleClass('expanded');
        showMoreButton.text(textBlock.hasClass('expanded') ? 'Show Less' : 'Show More');
      });
    });
    // Show pagination
    $(this.paginationContainer).css('display', 'flex');

  }
}

