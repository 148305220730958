/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "magnificPopup" }] */
import magnificPopup from 'magnific-popup';

export default () => {
  // MARKETING MODAL (e.g., promote a survey, event registration, etc)
  // can be included on any page, with its content being administered on a per-page basis

  // appears automatically on page load so long as it exists on the given page,
  // and ONLY if not previously dismissed (i.e., localStorage set to be hidden)

  // When dismissed, we set a stringified object for each modal instance.
  // The name of the localStorage item will correspond to the component instance's
  // ID attribute, which is always "js-marketing-modal-id-[INSTANCE_ID_HERE]"
  // In the localStorage item object, the following values will be stashed:
  // (NOTE: these are CMS-managed values; with no fallbacks... if these aren't set
  // in admin, the modal lifetime is considered irrelevant):
  // ** data-token-lifetime (integer; informed by units of measurement attr below)
  // ** data-token-uom (m|h|d; context for the integer)
  // ** data-sitecore-version-num (string; indicates that any previous localStorage
  //    for the same modal ID should be disregarded/cleared, as the admin has made
  //    content changes)

  // There should only ever be one marketing modal per page. So, just in case
  // there are multiple of the same component on the page, just get the first one
  if ($('.js-marketing-modal').length > 0) {
    const $pageMrktgModal = $('.js-marketing-modal').first(),
          pageMrktgModalID = $pageMrktgModal.attr('id'),
          $pageMrktgModalLinks = $pageMrktgModal.find('a[href]'),
          pageMrktgModalVersionNum = $pageMrktgModal.attr('data-sitecore-version-num'),
          pageMrktgModalLifetimeInt = parseInt($pageMrktgModal.attr('data-token-lifetime')),
          pageMrktgModalLifetimeUOM = $pageMrktgModal.attr('data-token-uom');

    let saveLocalStorage = false,
        showThisModal = true;

    // console.log('Sitecore version num: ' + pageMrktgModalVersionNum + '; Lifetime integer: ' + pageMrktgModalLifetimeInt + '; Lifetime Unit of Measurement: ' + pageMrktgModalLifetimeUOM);

    // ONLY SET localStorage item if the CMS-managed values we need are set properly
    // if these items don't have values set, this modal will never expire (will always display)
    if (pageMrktgModalLifetimeInt > 0 && pageMrktgModalLifetimeUOM && pageMrktgModalVersionNum) {
      // console.log('Will set localStorage item because all of the CMS-managed settings we need to control lifetime ARE PRESENT.');
      saveLocalStorage = true;
    } else if (localStorage.getItem(pageMrktgModalID)) {
      // if this marketing modal doesn't have all these localStorage settings,
      // let's remove any previously set localStorage items matches for this
      // modal instance (if exists) because a lack of these settings on the
      // instance means the modal should never expire
      // console.log('Removing localStorage item because all of the CMS-managed settings we need to control lifetime are not present (Sitecore version num: ' + pageMrktgModalVersionNum + '; Lifetime integer: ' + pageMrktgModalLifetimeInt + '; Lifetime Unit of Measurement: ' + pageMrktgModalLifetimeUOM + '). THIS MEANS MODAL WILL ALWAYS SHOW.');
      localStorage.removeItem(pageMrktgModalID);
    }

    // check if a localStorage item match for this modal instance exists
    // and if the Sitecore version number for it has changed, remove the old
    // localStorage item so that the new version of this modal content can be displayed
    if (localStorage.getItem(pageMrktgModalID)) {
      // console.log('localStorage item does exist.');

      // check cookied Sitecore version number & compare with current Sitecore version number
      const thisStorageDataObj = JSON.parse(localStorage.getItem(pageMrktgModalID)),
            __storedSitecoreVersionNum = thisStorageDataObj.sitecoreVersionNum,
            __timestampHiddenThru = thisStorageDataObj.timestampHiddenThru,
            currentTimestamp = Date.now();

      if (pageMrktgModalVersionNum && __storedSitecoreVersionNum !== pageMrktgModalVersionNum) {
        // console.log('Removing localStorage item for this modal because its Sitecore version number has changed. (Previous version: ' + __storedSitecoreVersionNum + '; Current version: ' + pageMrktgModalVersionNum + ')');
        localStorage.removeItem(pageMrktgModalID);
      }

      // Modal visibility logic based on existing localStorage...
      // Compare current timestamp with this modal's localStorage item's timestampHiddenThru
      // set modal to hidden if localStorage settings indicate the validThru timestamp
      // is older than current timestamp
      if (__timestampHiddenThru && parseInt(__timestampHiddenThru) >= currentTimestamp) {
        // console.log('Setting modal to hidden based on localStorage timestamp (' + __timestampHiddenThru + ') being greater than current timestamp (' + currentTimestamp + ')');
        showThisModal = false;
      }
    }

    // NOTE: the following logic makes it so clicking any link contained
    // within this modal's content will close this modal and be cookied to not show again.
    $pageMrktgModalLinks.on('click', function() {
      // close modal — this also fires the cookie logic contained
      // in the callback established in $.magnificPopup.open() below.
      $.magnificPopup.close();
    });

    // only show modal if not previously dismissed (set to hide for X time)
    if (showThisModal === true) {
      $.magnificPopup.open({
        items: {
          src: '.js-marketing-modal'
        },
        type: 'inline',
        callbacks: {
          /* eslint-disable object-shorthand */
          close: function() {
            // ONLY SET localStorage item if the CMS-managed values we need are set properly
            // otherwise, if no CMS-managed settings are set, modal never expires (will always show)
            if (saveLocalStorage === true) {
              // Do the calculation now based on CMS-managed lifetims settings
              // to obtain timestamp when modal should be valid through....
              const modalValidThruDate = new Date();

              if (pageMrktgModalLifetimeUOM === 'd') {
                // If lifetime is in days, this modal instance expires X days from now
                modalValidThruDate.setDate(modalValidThruDate.getDate() + pageMrktgModalLifetimeInt);
              } else if (pageMrktgModalLifetimeUOM === 'h') {
                // If lifetime is in hours, this modal instance expires X hours from now
                modalValidThruDate.setHours(modalValidThruDate.getHours() + pageMrktgModalLifetimeInt);
              } else if (pageMrktgModalLifetimeUOM === 'm') {
                // If lifetime is in minutes, this modal instance expires X minutes from now
                modalValidThruDate.setMinutes(modalValidThruDate.getMinutes() + pageMrktgModalLifetimeInt);
              }

              const modalHiddenThruTimestamp = modalValidThruDate.getTime();

              // console.log('In close callback. Current timestamp: ' + Date.now() + '. Timestamp for expiration to cookie will be: ' + modalHiddenThruTimestamp + ' (duration unit is: ' + pageMrktgModalLifetimeUOM + '; Human-readable expiration: ' + modalValidThruDate + ')');

              // Stash in localStorage (note: all vals have to be strings to work):
              // *** Timestamp dismissed
              //     timestamp when modal was closed and dismissal cookie was set

              // *** timestampHiddenThru
              //     a timestamp for when modal dismissal expires and modal is visible again

              // *** humanReadableHiddenThru
              //     just for testing purposes; easier to not have to convert timestamp

              // *** sitecoreVersionNum
              //     Sitecore version num of modal instance

              // *** tokenLifetime
              //     CMS-managed setting for localStorage item lifetime (int);
              //     how long until modal is visible again

              // *** tokenLifetimeUOM
              //     CMS-managed setting for localStorage item unit of measurement (m|h|d);
              //     how long until modal is visible again
              const thisModalInfo = {
                timestampDismissed: String(Date.now()),
                timestampHiddenThru: String(modalHiddenThruTimestamp),
                humanReadableHiddenThru: String(modalValidThruDate),
                sitecoreVersionNum: String(pageMrktgModalVersionNum),
                tokenLifetime: String(pageMrktgModalLifetimeInt),
                tokenLifetimeUOM: pageMrktgModalLifetimeUOM
              };

              // set localStorage item for this modal instance
              localStorage.setItem(pageMrktgModalID, JSON.stringify(thisModalInfo));
            }
          }
        }
      });
    }
  }

  // VIDEO MODALS (opened with a trigger click)
  if ($('.js-video-modal-trigger').length) {
    $('.js-video-modal-trigger').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-zoom-out',
      iframe: {
        patterns: {
          youtube: {
            markup: `<div class="mfp-iframe-scaler">
                      <div class="mfp-close no-bg"></div>
                      <iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>
                    </div>`,
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1'
          }
        }
      }
    });
  }


  // debug modal login, JS is in the sitecore js files
  // if ($('.my-account-link').length) {
  //   $('.my-account-link').magnificPopup({
  //     items: {
  //       src: '#modal-login-form'
  //     },
  //     type: 'inline',
  //     tLoading: '',
  //     closeOnContentClick: false,
  //     closeOnBgClick: false
  //   });
  // }


  // GENERIC MODALS
  // (opened from a trigger click; multiple on page appears to be allowed given the ID uses data-attr)
  // modal port from static-lmap
  const $modalTriggers = $('.modal-trigger'),
        $body = $('body');

  if ($modalTriggers.length) {

    $modalTriggers.each((index, item) => {
      const idSelector = $(item).data('mfp-src');

      if (idSelector) {

        $modalTriggers.magnificPopup({
          items: {
            src: idSelector
          },
          type: 'inline',
          tLoading: '',
          callbacks: {

            /* eslint-disable object-shorthand */
            beforeOpen: function() {
              this.bgOverlay.addClass('-light');
            }
          }
        });
      }
    });
  }


  // FORCED MODALS (no click trigger; based on matched page path set in .env)
  /* eslint-disable no-undef, no-unused-vars */
  const forcedModalPath = process.env.VERIFY_CFP_FORCED_DISCLAMER_PARENT_PATH;
  const isForcedPage = forcedModalPath ? window.location.pathname.startsWith(forcedModalPath) : null;

  if (isForcedPage) {

    if (getHasAgreed()) {
      // don't show modal
      return;
    }

    // show modal
    $.magnificPopup.open({
      items: {
        src: '#modal-forced'
      },
      enableEscapeKey: false,
      closeOnBgClick: false,
      closeMarkup: '<button title="%title%" id="forced-modal-close" type="button" class="mfp-close">&#215;</button>',
      callbacks: {
        /* eslint-disable object-shorthand */
        beforeOpen: function() {
          freezeScroll(true);
          this.bgOverlay.addClass('-light');

        },
        close: handleForcedClose
      }
    });

    $('#forced-modal-accept').on('click', () => {
      window.sessionStorage.setItem('forced_terms_accepted', new Date());
      freezeScroll(false);
      $.magnificPopup.close();
    });

    $('#forced-modal-cancel').on('click', handleForcedClose);
  }

  function handleForcedClose() {
    freezeScroll(false);

    if (getHasAgreed()) {
      return;
    }

    const { referrer } = document,
          { hostname } = window.location;

    if (referrer.includes(hostname)) {
      window.open(document.referrer, '_self');
    } else {
      window.location.href = '/';
    }
  }

  function getHasAgreed() {
    return window.sessionStorage.getItem('forced_terms_accepted');
  }

  function freezeScroll(bool) {
    $body.attr('data-overlay', bool);

    if (bool) {
      window.addEventListener('touchmove', noScroll, { passive: false });
    } else {
      window.removeEventListener('touchmove', noScroll, { passive: false });
    }
  }

  function noScroll() {
    window.scrollTo(0, 0);
  }

};
