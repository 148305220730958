export default () => {
  if ($('.content-accordion-item-title').length) {
    $('html')
      .on('click', '.content-accordion-item-title', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget),
              $parent = $this.parent();
        $parent.toggleClass('-open');
        $parent.hasClass('-open') ? $this.attr('aria-expanded', 'true').attr('aria-pressed', 'true') : $this.attr('aria-expanded', 'false').attr('aria-pressed', 'false');
        $this.next().slideToggle();
      })
      .on('keydown keyup', '.content-accordion-item-title:focus', (e) => {
        if (e.keyCode === 32 && e.type === 'keydown') {
          e.preventDefault();
        } else if (e.keyCode === 32 && e.type === 'keyup') {
          $(e.currentTarget).click();
        }
      })
    ;
  }
};
