export default () => {
  if ($('.mobile-nav').length) {
    $('html')
      // open nav
      .on('click', '.js-open-mobile-nav', () => {
        $('html').attr('data-mobile-nav-open', 'true');
      })
      // close nav
      .on('click', '.js-mobile-nav-close', () => {
        $('html').attr('data-mobile-nav-open', 'false');
      })
      // opens next level of items
      .on('click', '.mobile-nav-open-next-level', (e) => {
        e.preventDefault();
        const _this = $(e.currentTarget);
        _this.next().attr('data-opened', 'true');

        // reset slidebar
        $('.mobile-nav-slidebar').css({
          top: 0,
          height: '27px',
          opacity: 0
        });
      })
      // goes back a level mobile nav items
      .on('click', '.mobile-nav-back-level', (e) => {
        e.preventDefault();

        const _this = $(e.currentTarget);
        _this.parent().attr('data-opened', 'false');
      })
      // open mobile search
      .on('click', '.js-mobile-search-toggle', () => {
        $('.mobile-nav-search-slideout').attr('data-mobile-search-open', 'true');
        $('.js-search-field').focus();
      })
      .on('click', '.js-mobile-search-close', () => {
        $('.mobile-nav-search-slideout').attr('data-mobile-search-open', 'false');
        $('.js-mobile-search-toggle').focus();
      })
    ;

    // applies hover states for dropdown menu sidebars
    $('.mobile-nav-link').on('mouseenter focus', (e) => {
      const offTop = $(e.currentTarget).parent()[0].offsetTop,
            linkHeight = $(e.currentTarget).height()
      ;

      // hide slidebar if it's a button
      if ($(e.currentTarget).is('.button, .js-utility-nav-close')) {
        $('.mobile-nav-slidebar').css({
          top: offTop,
          height: '27px',
          opacity: 0
        });
      } else {
        $('.mobile-nav-slidebar').css({
          top: (offTop),
          height: (linkHeight + 2) + 'px',
          opacity: 1
        });
      }
    });

    // resets slidebar when dropdown is closed
    $('.slidebar-container').on('mouseleave focusout', () => {
      $('.mobile-nav-slidebar').css({
        top: 0,
        height: '27px',
        opacity: 0
      });
    });

    // hide slidebar when hovering/focus next arrows
    $('.mobile-nav-open-next-level').on('mouseenter focus', () => {
      $('.mobile-nav-slidebar').css({
        opacity: 0
      });
    });
  }
};
