/* eslint-disable no-unused-vars */
import ProDashboard from './pro-dashboard';

export default () => {

  if ($('.hero-dashboard[data-status="certified"]').length) {
    const Cd = new ProDashboard();
    Cd.init();
  }

  if ($('.home-tracker-pro').length) {
    const Cd = new ProDashboard(true);
    Cd.init();
  }

};