/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import FacetedSearchBase from '../../faceted-search-results/faceted-search-base';

export default class FindRpResults extends FacetedSearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.RP_ENDPOINT;
    config.searchURL          = process.env.RP_URL;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    // Facets
    config.facetOptions = [{
      name: 'delivery_methods',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'program_types',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'state',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.find-rp-results';
    super(config, prefix);

    this.cssPrefix = prefix;

    // UI based events
    $('html')
      // landing page hero events
      .on('click', '.js-find-rp-search .js-search-submit', this._keywordSearch.bind(this))
      .on('keyup', '.js-find-rp-search .js-search-field-hero', (e) => {
        if (e.keyCode === 13) {
          $('.js-search-submit').click();
        }
      })
      .on('click', '.js-find-rp-search .js-hero-submit', this._heroFacetSearch.bind(this))
      .on('keyup', '.js-find-rp-search input[type="checkbox"]', (e) => {
        if (e.keyCode === 13) {
          $('.js-find-rp-search .js-hero-submit').click();
        }
      })
      .on('change', '.js-find-rp-search input[value="classroom"]', (e) => {
        if ($(e.currentTarget).prop('checked') === true) {
          $('.-state-container').show();
        } else {
          $('.-state-container').hide();
          $('.-state-container select').val('');
        }
      })
    ;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    // Build and output each individual search result.
    if (this._isFacetActive('s', 'num_graduates_sort_tl_desc')) {
      results.map((item) => {
        const program = this._templateProgram(item);
        const template = `
          <div class="rp-result-item" data-multi="false">
            <div class="rp-result-item-heading">
              <h2 class="h3 rp-result-item-org">${item.organization_name}</h2>
            </div>
            ${program}
          </div>
        `;

        $container.append(template);

      });

    } else {
      let prevItemOrg = '';

      const combinedTemplate = results.map((item, index) => {
        const program = this._templateProgram(item),
              nextItemOrg = index !== (results.length - 1) ? results[index + 1].organization_name : '';
        let heading = '',
            ending = '';

        if (prevItemOrg !== item.organization_name) {
          heading = `
            <div class="rp-result-item" data-multi="true">
              <div class="rp-result-item-heading">
                <h2 class="h3 rp-result-item-org">${item.organization_name}</h2>
              </div>
          `;
        }

        if (item.organization_name !== nextItemOrg) {
          ending = '</div>';
        }

        const template = heading + program + ending;
        prevItemOrg = item.organization_name;
        return template;
      });

      $container.append(combinedTemplate.join(''));
    }
  }

  /**
   *  Used to template the middle consistent part of a result
   *
   * @method _templateProgram
   * @param {Object} item
   */

  _templateProgram(item) {
    let rating = '',
        costsLink = '',
        contactName = '',
        address2 = '',
        description = '',
        totalGrads = '',
        website = ''
      ;

    if (item.rating_display) {
      rating = `<p class="-small -gray-dark -italic">${item.rating_display}</p>`;
    }

    if (item.financial_website) {
      costsLink = `<div class="rp-result-item-program-info-link">
                    <a href="${item.financial_website}" target="_blank" class="-caps -arrow-after"><span>Program Costs</span></a>
                  </div>`;
    }

    if (item.program_contact_ind_fullname) {
      const title = item.program_contact_title ? `, ${item.program_contact_title}` : '';
      contactName = `${item.program_contact_ind_fullname}${title}<br>`;
    }

    if (item.address_line_2) {
      address2 = `${item.address_line_2}<br>`;
    }

    if (item.graduates) {
      totalGrads =  `<div class="rp-result-item-program-info-column-inner">
                      <div class="h6 -gray-dark">Total Graduates in ${item.grad_year}</div>
                      <p class="-small -gray-dark">${item.graduates}</p>
                    </div>`;
    }

    if (item.program_guide_description) {
      description = `<div class="h6 -gray-dark">Description</div>
                     <p class="-small -gray-dark">${item.program_guide_description}</p>`;
    }

    if (item.website) {
      website = `<div class="rp-result-item-program-info-link">
                  <a href="${item.website}" target="_blank" class="-caps -arrow-after"><span>Program Website</span></a>
                </div>`;
    }

    const program = `
      <div class="rp-result-item-program">
        <div class="h5 -gray-dark rp-result-item-program-title">${item.program_title}</div>
        <div class="rp-result-item-program-info">
          <div class="rp-result-item-program-info-column -description">
            ${description}
            ${rating}
            <div class="rp-result-item-program-info-links">
              ${website}
              ${costsLink}
            </div>
          </div>

          <div class="rp-result-item-program-info-column -contact">
            <div class="h6 -gray-dark">Contact</div>
            <p class="-small -gray-dark">
              ${contactName}
              <a href="mailto:${item.email}" class="">${item.email}</a><br>
              ${item.address_line_1}<br>
              ${address2}
              ${item.city}, ${item.state} ${item.postal_code}<br>
              <a href="tel:${item.phone_number_complete}">${item.phone_number_complete}</a>
            </p>
          </div>

          <div class="rp-result-item-program-info-column -details">
            <div class="rp-result-item-program-info-column-inner">
              <div class="h6 -gray-dark">Program Type</div>
              <p class="-small -gray-dark">${item.program_type}</p>
            </div>
            <div class="rp-result-item-program-info-column-inner">
              <div class="h6 -gray-dark">Format(s)</div>
              <p class="-small -gray-dark">${item.delivery_method}</p>
            </div>
            ${totalGrads}
          </div>
        </div>
      </div>
    `;

    return program;
  }

  /**
   * Triggered by on submit click on hero landing page to build url and send selecte facets to search results page
   *
   * @method _heroFacetSearch
   * @param {Object} e (event)
   */

  _heroFacetSearch(e) {
    e.preventDefault();
    const paramObject = {};
    $('.js-find-rp-search input:checked').map((i, item) => {
      const name = $(item).attr('name'),
            val  = $(item).val();
      if (!(name in paramObject)) {
        paramObject[name] = [];
      }
      paramObject[name].push(val);
    });
    $('.js-find-rp-search option:selected').map((i, item) => {
      const name = $(item).parent().attr('name'),
            val  = $(item).val();
      if (!(name in paramObject)) {
        paramObject[name] = [];
      }
      paramObject[name].push(val);
    });

    if (window.location.pathname !== this._searchURL) {
      const { defaultQueryParams, query, queryParams } = this,
            defaults = Object.keys(defaultQueryParams),
            options = defaults.concat(queryParams),
            compact = this.compact(options),
            uniqe   = this.uniq(compact)
      ;

      this.queryParams = uniqe;
      this.query       = this._initQuery();

      Object.keys(paramObject).map((item) => {
        this.query[item] = paramObject[item].join(',');
      });

      return window.location = this._buildURL();
    }
  }
}
