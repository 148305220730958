/* global homeHeroItems */
export default () => {
  if ($('.hero-home').length && typeof homeHeroItems !== 'undefined') {
    const randNum = Math.floor(Math.random() * homeHeroItems.length);
    $('.hero-home-right-headshot-img').attr('src', homeHeroItems[randNum].image.url);
    $('.hero-home-right-headshot-img').attr('alt', homeHeroItems[randNum].image.alt);
    $('.hero-home-right-quote-text').text(homeHeroItems[randNum].quote);
    $('.hero-home-bg-right').css('background-image', `url(${homeHeroItems[randNum].bg_image})`);
    $('.hero-home-right').css('background-image', `url(${homeHeroItems[randNum].bg_image})`);
    if (homeHeroItems[randNum].cite.length) {
      $('.hero-home-right-quote-citation').text(homeHeroItems[randNum].cite);
    } else {
      $('.hero-home-right-quote-citation').remove();
    }
  }
};
