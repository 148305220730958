import moment from 'moment';

export default class DashboardBase {
  constructor(heroOnly = false) {
    this.heroOnly = heroOnly;
    this.response = {};
    this.content = {};

    $('html')
      .on('error', 'img', (e) => {
        $(e.currentTarget).attr('src', $(e.currentTarget).attr('data-default'));
      })
    ;

    // on error event for image profile photo, called inline
    window.handleImg = (el) => {
      el.src = el.dataset.default;
    };
  }

  init() {
    if ($('html').attr('data-experience-editor') === 'true') {
      return;
    } else if (this.heroOnly) {
      this.getData();
    } else {
      this.refreshDashboard(true);
      this.eventListeners();
    }
  }

  updateDashboardCallback(success, reason) {
    if (success) {
      this.refreshDashboard();
    } else {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh:', reason);
    }
  }

  refreshDashboard(initial = false) {
    if (initial) {
      this.loading(true);
    }

    $.ajax({
      url: this.contentEndpoint

    }).done((data) => {
      this.content = data;
      // console.log(data);
      this.getData();

      this.enableDashboard($('.-is-loading'));
    });
  }

  getData() {
    // eslint-disable-next-line no-console
    console.log('No method set');
  }

  updateHero(response) {
    const $firstName = $('.dashboard-first-name')
        ;
    $firstName.html(response.ind_first_name);

    $('#dashboard-profile-avatar').attr('src', response.photoUrl);
  }

  insertProgressButton(text, url = '#') {
    const page = window.location.pathname === this.url ? '' : this.url;
    return `<a href="${page}${url}" class="button -small">${text}</a>`;
  }

  // -----------------------------
  //  UTILITIES
  // -----------------------------
  /**
   *
   * @param {boolean} isLoading
   */
  loading(isLoading) {
    if (isLoading) {
      $('html').attr('data-dashboard-loading', 'true');
    } else {
      $('html').attr('data-dashboard-loading', 'false');
      $('a.button.-loading').map((i, btn) => {
        $(btn).removeClass('-loading')
          .text($(btn).attr('data-text'));
      });

    }
  }

  /**
   * Disables dashboard while sending an update to the api, is-loading class adds a spinner/loader somewhere on the field
   * @param {boolean} processing
   * @param {element} el - should be element container for input that's being changed.
   */
  disableDashboard(el) {
    $('html').attr('data-dashboard-disabled', 'true');
    el.addClass('-is-loading');
    $('input, select').attr('disabled', true);
  }

  /**
   * Re-enables dashboard after api update call is complete
   * @param {boolean} processing
   * @param {element} el - should be element container for input that's being changed.
   */
  enableDashboard(el) {
    if (el.length)
      el.removeClass('-is-loading');
    $('html').attr('data-dashboard-disabled', 'false');
    $('input, select').attr('disabled', false);
  }

  /**
   *
   * @param {*} arr
   * @param {*} response
   */
  updateValues(arr) {
    arr.map(({selector, property, number = false, string = false}) => {
      let val = this.response[property];
      if (number)
        val = this.commafy(this.response[property]);
      if (string)
        val = property;

      $(selector).html(val);
    });
  }

  /**
   * Returns markup for an arrow link given text and url
   * @param {string} text - visible link text
   * @param {string} url - url for href
   */
  arrowLink(text, url) {
    return `<a href="${url}" class="-caps -arrow-after"><span>${text}</span></a>`;
  }

  /**
   * Adds commas to numbers with 4+ digits
   *
   * @method commafy
   * @param {Number} num
   */
  commafy(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Adds commas to numbers with 4+ digits
   *
   * @method returnDateObject
   * @param {string} date - utc formatted date string
   */
  returnDateObject(date) {
    const dateArray = moment.utc(date).format('MMMM/D/YYYY').split('/');
    return {
      month: dateArray[0],
      day: dateArray[1],
      year: dateArray[2]
    };
  }

  returnDateRangeString(start, end) {
    let startYear = '',
        endMonth = ''
      ;

    if (start.year !== end.year) {
      startYear = `, ${start.year}`;
    }

    if (start.month !== end.month) {
      endMonth = `${end.month} `;
    }

    const startString = `${start.month} ${start.day}${startYear}`;
    const endString = `${endMonth}${end.day}, ${end.year}`;

    return `${startString}-${endString}`;
  }

  checkProp(obj, prop) {
    if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] !== null && obj[prop].length) {
      return true;
    } else {
      return false;
    }
  }
}