export default () => {
  if ($('.js-screen-res-check').length) {
    $('.js-screen-res-check').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      const width = window.screen.width,
            height = window.screen.height;

      if ($this.next().hasClass('screen-res')) {
        $this.next().remove();
      }

      $(e.currentTarget).after(`<p class="screen-res">Your screen resolution is <strong>${width} x ${height}</strong>.</p>`);
    });
  }
};