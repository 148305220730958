export default () => {
  // setting delay here because sometimes it seems to fire before the html is fully loaded
  if ($('.site-header').length) {
    setTimeout(() => {
      repositionDropdowns();
    }, 500);

    // applies hover states for dropdown menu sidebars
    $('.site-header-main-nav-dropdown a').on('mouseenter focus', (e) => {
      const offTop = $(e.currentTarget).parent()[0].offsetTop,
            linkHeight = $(e.currentTarget).height()
      ;

      // hide slidebar if it's a button
      if ($(e.currentTarget).is('.button, .js-utility-nav-close')) {
        $('.site-header-main-nav-dropdown-slidebar').css({
          top: offTop,
          height: '27px',
          opacity: 0
        });
      } else {
        $('.site-header-main-nav-dropdown-slidebar').css({
          top: (offTop - 2),
          height: (linkHeight + 2) + 'px',
          opacity: 1
        });
      }
    });

    // resets slidebar when dropdown is closed
    $('.site-header-main-nav-dropdown').on('mouseleave focusout', () => {
      $('.site-header-main-nav-dropdown-slidebar').css({
        top: 0,
        height: '27px',
        opacity: 0
      });
    });

    // enables keyboard tabbing
    // $('html').on('focus blur', '.site-header-main-nav-item.-has-children', (e) => {
    //   const $target = $(e.currentTarget);
    //   e.type === 'focusin' ? $target.addClass('-dropdown-open') : $target.removeClass('-dropdown-open');
    // });

    const $navItems = $('.site-header-main-nav-item.-has-children');
    $navItems.each((index, navItem) => {
      $(navItem)
        .on('focus', ':focusable', (e) => {
          $navItems.removeClass('-dropdown-open');
          $(e.delegateTarget).addClass('-dropdown-open');
        })
        .on('blur', ':focusable', (e) => {
          $(e.relatedTarget).parent(navItem).siblings().removeClass('-dropdown-open');
        })
      ;
    });

    $('.site-header-search-button').on('focus', () => {
      $('.site-header-main-nav-item').removeClass('-dropdown-open');
    });

    // toggle search
    $('.js-search-toggle').on('click', (e) => {
      e.stopPropagation();
      // e.preventDefault();
      $('html').attr('data-header-search-open', 'true');
      $('.-utility-menu').removeClass('-open');
      setTimeout(() => {
        $('#search-input-header').focus();
      }, 300);
    });

    // close search button
    $('.js-search-input-close').on('click', (e) => {
      e.stopPropagation();
      if ($('html').attr('data-header-search-open') === 'true') {
        $('html').attr('data-header-search-open', 'false');
      }
    });

    // close on html clicks outside of
    $(document).on('click', () => {
      $('.js-search-input-close').click();
    });

    // need this so clicks in search container don't close search
    $('.js-search-field').on('click', (e) => {
      e.stopPropagation();
    });

    // opens utility nav on click or keyboard enter
    $('.js-utility-nav-toggle').on('click', (e) => {
      e.stopPropagation();
      $('html').attr('data-utility-menu-open', 'true');
      $('html').attr('data-header-search-open', 'false');
    });

    $('.js-utility-nav-close').on('click', (e) => {
      e.stopPropagation();
      $('html').attr('data-utility-menu-open', 'false');
    });

    // site search JS
    $('html')
      .on('click', '.site-header-search-container .js-search-submit', (e) => {
        if ($('#search-input-header').val() === '') {
          e.stopPropagation();
          return;
        }
        initSearch();
      })
      .on('click', '.mobile-nav-search .js-search-submit', () => {
        initSearch();
      })
      .on('keyup', '#search-input-header, #search-input-mobile', (e) => {
        const val = $(e.currentTarget).val();
        if ($(e.currentTarget).attr('id') === 'search-input-header') {
          $('#search-input-mobile').val(val);
        } else {
          $('#search-input-header').val(val);
        }

        if (e.keyCode === 13) {
          initSearch();
        }
      })
    ;
  }

  function initSearch() {


    const searchUrl = $('.site-header-search-container').attr('data-action'),
          param = $('#search-input-header').attr('name'),
          term = encodeURIComponent($('#search-input-header').val());

    if (term.length) {
      window.location = `${searchUrl}?${param}=${term}`;
    }
  }

  const delay = 50;

  $(window).on('resize', () => {
    const currentWinSize = $(window).outerWidth();
    setTimeout(() => {
      const newWinSize = $(window).outerWidth();
      if (newWinSize === currentWinSize) {
        repositionDropdowns();
      }
    }, delay);
  });

  function repositionDropdowns() {
    // get position of the far right edge of the container the menu items are in
    // this will be the edge the dropdown items will be adjusted to if they are too wide
    const navWidth = $('.site-header-right-bottom').offset().left + $('.site-header-right-bottom').outerWidth();

    // get each dropdown and loop thorugh them
    $('.site-header-main-nav-dropdown').not('.-utility-menu').map((i, item) => {
      // check the item's right position by getting the left and adding the width
      // also the transform object in case we have already moved this item
      const itemWidth = $(item).offset().left + $(item).outerWidth(),
            itemTransform = $(item).css('transform');
      let itemOffset = 0;

      if (itemTransform !== 'none') {
        const matrix = $(item).css('transform').split(/[()]/)[1];
        itemOffset = matrix.split(',')[4];
      }
      // compare the position of the dropdown with the edge of the nav container
      // move it with translate if needed by the difference
      if (itemWidth - itemOffset > navWidth) {
        $(item).css('transform', `translateX(-${itemWidth - navWidth - itemOffset}px)`);
      } else {
        $(item).css('transform', 'translateX(0px)');
      }
    });
  }
};
