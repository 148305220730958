export default () => {
  if ($('.disciplined-list').length) {
    // splits the content into the columns specified by data attribute
    $('.js-content-column').map((i, column) => {
      const columnNumber = $(column).attr('data-content-column');
      $(`.disciplined-list-block[data-column="${columnNumber}"]`).appendTo(column);
    });

    // click event for scrolling back to top
    $('.disciplined-list-block-scrolltop').on('click', (e) => {
      e.preventDefault();
      // store id of block you are on for focusing after scrolling
      const blockID = $(e.currentTarget).parents('.disciplined-list-block').attr('id');

      const $target = $('.disciplined-list-locations');
      // scrolling back to target (list of states)
      if ($target.length) {
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top - 100
        }, 300, () => {
          let scrollV, scrollH;
          const loc = window.location;

          // resetting hash in url
          if ('pushState' in history)
            history.pushState('', document.title, loc.pathname + loc.search);
          else {
            // Prevent scrolling by storing the page's current scroll offset
            scrollV = document.body.scrollTop;
            scrollH = document.body.scrollLeft;
            loc.hash = '';
            // Restore the scroll offset, should be flicker free
            document.body.scrollTop = scrollV;
            document.body.scrollLeft = scrollH;
          }

          // focusing back on state after scrolling up for keybaord accessibility
          $target.find(`a[href="#${blockID}"]`).focus();
        });
      }
    });
  }
};
