/* global ageTableData stateTableData ethnicityTableData*/
export default () => {
  if ($('.demographic-tables').length) {
    buildTableRows('.js-age-table', ageTableData);
    buildTableRows('.js-state-table', stateTableData);
    buildTableRows('.js-ethnicity-table', ethnicityTableData);
  }

  function buildTableRows(table, data) {
    const $table = $(table),
          keys = Object.keys(data[0]);

    const tableRowMarkup = data.map(row => {
      const tr = keys.map(cell => {
        return `<td>${row[cell]}</td>`;
      });

      return `<tr>${tr.join('')}</tr>`;
    });

    $table.append(tableRowMarkup.join(''));
  }
};
