export default () => {
  let lastClicked = 0,
      mobileOnce = true,
      desktopOnce = true,
      desktopNeedsContent = true
    ;

  if ($('.text-rotator').length) {

    $('.text-rotator-tab-link').on('click', (e) => {
      e.preventDefault();

      const $self = $(e.currentTarget),
            $textContent = $self.next(),
            mobile = $(window).outerWidth() < 768,
            $desktopContainer = $('.text-rotator-accordion.-single')
        ;

      lastClicked = $self.attr('data-index');

      if (mobile) {
        // $self.toggleClass('-active');
        $textContent.slideToggle();
      } else {
        $('.text-rotator-tab-link').removeClass('-active');
      }

      $desktopContainer
        .animate({opacity: 0}, 300, () => {
          $desktopContainer
            .empty()
            .append($textContent.children().clone())
            .css('opacity', 1)
          ;
        });

      $self.toggleClass('-active');

    });

    resizeLoadActivation();

    $(window).on('resize', () => {
      resizeLoadActivation();
    });
  }

  function resizeLoadActivation() {
    const currSize = $(window).outerWidth();

    if (currSize >= 768 && desktopOnce) {
      $('.text-rotator-accordion.-tabbed').hide();
      $('.text-rotator-tab-link').removeClass('-active');
      $(`.text-rotator-tab-link[data-index="${lastClicked}"]`).addClass('-active');

      if (desktopNeedsContent) {
        $(`.text-rotator-tab-link[data-index="${lastClicked}"]`).click();
        desktopNeedsContent = false;
      }

      desktopOnce = !desktopOnce;
      mobileOnce = true;
    } else if (currSize < 768 && mobileOnce) {
      $('.text-rotator-tab-link').removeClass('-active');
      mobileOnce = !mobileOnce;
      desktopOnce = true;
    }
  }
};
