/* eslint-disable no-unused-vars */
import Gumshoe from 'gumshoejs';
import isSticky from '@plugins/isSticky';

export default () => {
  if ($('.code-standards').length) {
    isSticky();

    $('.code-standards-toc-toggle')
      .isSticky({
        zIndex: 8,
        stopper: $('.code-standards-sticky-stop'),
        stuckClass: '-stuck',
        offset: 0
      })
      .init();

    $('html')
      .on('click', '.code-standards-toc-toggle', (e) => {
        e.preventDefault();
        $('html').attr('data-toc-open', 'true');
      })
      .on('click', '.js-close-toc', (e) => {
        e.preventDefault();
        $('html').attr('data-toc-open', 'false');
      })
      .on('click', '.code-standards-toc-parents a', (e) => {
        setTimeout(() => {
          $($(e.currentTarget).attr('href')).focus();
        }, 700);
        if ($(window).width() < 700) {
          $('html').attr('data-toc-open', 'false');
        }
      })
    ;

    const spy = new Gumshoe('.code-standards-toc-parents a', {
      navClass: '-active',
      offset: 100
    });

  }
};
