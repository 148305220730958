/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */

import { Config } from 'is-search';
import FacetedSearchBase from '../faceted-search-results/faceted-search-base';

export default class ScholarshipLibraryResults extends FacetedSearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.SCHOLARSHIP_LIBRARY_ENDPOINT;
    config.searchURL          = process.env.SCHOLARSHIP_LIBRARY_URL;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    // Facets
    config.facetOptions = [{
      name: 'application_window_cmp',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'eligibility_criteria_cmp',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'level_of_study_cmp',
      isMultiCardinality: true,
      default: null
    }];

    const prefix = '.scholarship-library-results';
    super(config, prefix);

    this.cssPrefix = prefix;

  }

  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    // Build and output each individual search result.
    results.map((item) => {

      let description;

      if (item.summary) {
        description = item.summary.length > 250 ? item.summary.substring(0, 247) + '...' : item.summary;
      } else {
        description = '';
      }

      // in case there are null values in data
      const title = item.title || '',
            deadline = item.deadline || '',
            amount = item.award_amount || '',
            slURL = item.item_url || '';

      const deadlineStr = deadline ? `<div class="sl-result-item-body-column-heading h6 -gray-dark">Deadline</div>
      <p class="-small -gray-dark">${deadline}</p>` : '';
      const amountStr = amount ? `<div class="sl-result-item-body-column-heading h6 -gray-dark">Award Amount</div>
      <p class="-small -gray-dark">${amount}</p>` : '';

      const template = `
      <div target="_blank" class="sl-result-item js-card-link">
        <div class="sl-result-item-heading">
          <div class="sl-result-item-heading-inner">
            <h2 class="h5 sl-result-item-heading-title">${title}</h2>
          </div>
        </div>
        <div class="sl-result-item-body">
          <div class="sl-result-item-body-column -description">
            <div class="sl-result-item-body-column-heading h6 -gray-dark">Description</div>
            <p class="-small -gray-dark">${description}</p>
          </div>
          <div class="sl-result-item-body-column -right-column">
            ${deadlineStr}
            ${amountStr}
            <a href="${slURL}" class="-caps -arrow-after"><span>Learn More</span></a>
          </div>
        </div>
      </div>
      `;

      $container.append(template);

    });
  }
}
