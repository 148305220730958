/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */

import { Config } from 'is-search';
import FacetedSearchBase from '../../faceted-search-results/faceted-search-base';

export default class InternshipResults extends FacetedSearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.INTERNSHIP_ENDPOINT;
    config.searchURL          = process.env.INTERNSHIP_URL;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    // Facets
    config.facetOptions = [{
      name: 'location',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'salary_range',
      isMultiCardinality: true,
      default: null
    }];

    const prefix = '.internship-results';
    super(config, prefix);

    this.cssPrefix = prefix;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    // Build and output each individual search result.
    results.map((item) => {
      const template = `
        <div class="internship-item">
          <div class="internship-item-body">
            <h5 class="h5 internship-item-title">${item.Title}</h5>
            <h6 class="h6">${item.Company}</h6>
            <p class="-small internship-item-summary">${item.Description}</p>
          </div>
          <div class="internship-item-footer">
            <p class="pay -small ">${item.Salary}</p>
            <ul>
              <li class="posted">
                <span class="icon-clock"></span>
                ${item.PostedDaysAgo}
              </li>
              <li class="position-type">
                <span class="icon-timer"></span>
                ${item.JobType}
              </li>
              <li class="location">
                <span class="icon-map-pin"></span>
                ${item.State}
              </li>
              <li class="apply-link">
                <a href="${item.Url}" class="-caps -arrow-after">
                  <span>View Details</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      `;

      $container.append(template);

    });
  }
}
