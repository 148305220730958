/* eslint-disable no-unused-vars */
/* global process */
import easyAutocomplete from 'easy-autocomplete';

export default () => {

  // sticky sidebar
  if ($('.account-registration-sidebar').length) {

    $(window).on('load scroll resize', (e) => {
      const scrollPos = $(window).scrollTop(),
            windowHeight = $(window).innerHeight(),
            scrollPosBottom = scrollPos + windowHeight,
            stickyElement = $('.account-registration-sidebar-inner'),
            stickyElOffset = $('.site-header').height(),
            stickyElHeight = stickyElement.outerHeight(),
            stickyElBottomOffset = stickyElOffset + stickyElHeight,
            stopElement = $('.site-footer'),
            stopElOffset = stopElement.offset().top,
            heightControl = stickyElHeight >= windowHeight
          ;

      // applying stuck class
      if (scrollPos >= stickyElOffset) {
        stickyElement.addClass('-stuck');
      } else {
        stickyElement.removeClass('-stuck');
      }

      // stopping and scrolling
      if ($(window).innerWidth() > 1199) {
        // keep moving sticky element up if there is more of it to see
        if (scrollPos > stickyElOffset && scrollPosBottom < stickyElBottomOffset) {
          const offset = scrollPos - stickyElOffset;
          stickyElement.css('top', -offset);
        // if bottom scroll position is past the bottom of the sticky element but not yet to footer and the element shouldnt scroll anymore, stick thing to the bottom
        } else if (scrollPosBottom >= stickyElBottomOffset && scrollPosBottom < stopElOffset && heightControl) {
          stickyElement.css('bottom', 0);
          stickyElement.css('top', 'auto');

        // handles when the top of the stop element comes into view at the bottom of the page
        } else if (scrollPosBottom >= stopElOffset) {

          // in situations where the sticky element is actually shorter than the height then we attack it to the top when scrolling back up
          if (scrollPos <= stickyElement.offset().top && e.type !== 'load' && (scrollPos + stickyElHeight) < stopElOffset) {
            // console.log(scrollPos + stickyElHeight, stopElOffset);
            stickyElement.css('top', 0);
            stickyElement.css('bottom', '');
            // otherwise just pushing the element up from the bottom as we scroll further past it
          } else {
            const offset = scrollPosBottom - stopElOffset;
            stickyElement.css('top', 'auto');
            stickyElement.css('bottom', offset);
          }
        // resetting top and bottom because we are above the sticky point
        } else if (scrollPos < stickyElOffset) {
          stickyElement.css('top', '');
          stickyElement.css('bottom', '');
        }
      }
    });

    // form question branching
    $('input[name="Advisor"]').on('click', () => {

      if ($('#reg-advisor-yes').is(':checked')) {
        $('.-branch-advisor').slideDown(300).attr('data-active', 'true');
        $('.-branch-advisor .fieldset-checks').attr('data-required', true);
        $('#reg-experience, .-branch-advisor .fieldset-checks input[type="checkbox"]').attr('required', true);
      } else {
        $('.-branch-advisor').slideUp(300).attr('attr-active', 'false');
        $('.-branch-advisor .fieldset-checks').data('data-required', true);
        $('#reg-experience,  .-branch-advisor .fieldset-checks input[type="checkbox"]').attr('required', false);
      }

    });

    $('input[name="CurrentlyEnrolled"]').on('click', () => {

      if ($('#reg-enrolled-yes-completed').is(':checked') || $('#reg-enrolled-yes-enrolled').is(':checked')) {
        $('.-branch-enrollment').slideDown(300).attr('data-active', 'true');
        $('#reg-institution, #reg-program-type, #reg-program-name, #reg-program-completion-date-month, #reg-program-completion-date-year').attr('required', true);
      } else {
        $('.-branch-enrollment').slideUp(300).attr('data-active', 'false');
        $('#reg-institution, #reg-program-type, #reg-program-name, #reg-program-completion-date-month, #reg-program-completion-date-year').attr('required', false);
      }

    });

    $('#reg-email').on('focusin focusout', (e) => {
      const $this = $(e.currentTarget),
            email = $this.val(),
            endpoint = `${process.env.REGISTRATION_EMAIL_ENDPOINT}?email=${email}`;

      if (e.type === 'focusout' && $this.val().length) {

        $.get(endpoint, (response) => {
          // console.log(response);

          if (!response) {
            if ($this.parent().find('.error-message').length)
              $this.parent().find('.error-message').remove();
            return;
          } else if (!$this.parent().find('.error-message').length) {
            $this.after('<div class="error-message">This email already exists in our system</div>');
          }
        });
      }
    });
    const $stateDropdownClone = $('#reg-state').clone().empty();

    $('#reg-country').on('change', (e) => {
      const $this = $(e.currentTarget),
            country = $this.val(),
            endpoint = `${process.env.REGISTRATION_STATES_ENDPOINT}?cnCode=${country}`,
            $stateDropdown = $('#reg-state');

      if ($('#reg-state').parent().find('.error-message').length) {
        $('#reg-state').parent().find('.error-message').remove();
      }

      if (country.length) {
        $stateDropdown.empty();

        $.get(endpoint, (response) => {

          // add state dropdown clone if it does not exist
          if ($('select#reg-state').length === 0) {
            $('label[for="reg-state"]').after($stateDropdownClone);
          }

          // remove state text input if it exists
          if ($('input#reg-state').length) {
            $('input#reg-state').remove();
          }

          if (response.length > 1) {
            response.map((state) => {

              $('#reg-state').append(`<option value="${state.code}">${state.name}</option>`);
            });
          } else if (response.length === 1 && response[0].code !== '') {
            // if response is 1 and does not equal blank
            $('#reg-state').append(`<option value="${response[0].code}">${response[0].name}</option>`);
          } else {
            // if only one response and has an empty code or no response, remove dropdown add text input
            $('#reg-state').remove();
            $('label[for="reg-state"]').after('<input type="text" id="reg-state" name="reg-state">');
          }
        });
      }
    });
  }

  let registrationError = true;

  if ($('#reg-institution').length) {
    $('#reg-institution').easyAutocomplete({
      url(e) {
        return `${process.env.PROVIDER_NAME_ENDPOINT}?org=${e}`;
      },
      getValue: 'organization_name',
      requestDelay: 300,
      minCharNumber: 2,
      listLocation: 'results',
      list: {
        maxNumberOfElements: 5,
        match: {
          enabled: true
        },
        showAnimation: {
          type: 'slide',
          time: 250
        },
        hideAnimation: {
          type: 'slide',
          time: 250
        },
        onChooseEvent() {
          registrationError = false;
          $('#reg-institution-error').hide();
          $('#reg-institution').removeClass('-invalid');

          const cstKey = $('#reg-institution').getSelectedItemData().org_cst_key;
          if ($('#reg-institution-key').length) {
            $('#reg-institution-key').val(cstKey);
          }

          if ($('#reg-program-name').length) {

            $('#reg-program-name').attr('disabled', false);
            $('#reg-institution').attr('readonly', true);
            $('.field-container.-program-name').slideDown();
            $('#clear-provider').show();

            $.ajax({
              url: `${process.env.PROGRAM_NAME_ENDPOINT}?orgCstKey=${cstKey}&program=`
            }).done((data) => {
              $('#reg-program-name').empty();
              $('#reg-program-name').append('<option value="" selected>Select Program Name</option>');
              data.results.forEach((item) => {
                $('#reg-program-name').append(`<option value="${item.c23_key}">${item.program_title}</option>`);
              });
            });
          }
        }
      }
    });


    $('#clear-provider').on('click', (e) => {
      registrationError = true;

      e.preventDefault();
      $('#reg-institution').val('');
      $('#reg-institution').attr('readonly', false);
      $('#reg-institution-key').val('');
      $('.field-container.-program-name').slideUp();
      $('#reg-program-name').attr('disabled', true);
      $('#reg-program-name').empty();
      $('#clear-provider').hide();
      setTimeout(() => {
        $('#reg-institution').focus();
      }, 50);
    });

    $('#reg-institution').on('blur', (e) => {

      if (registrationError) {
        $('#reg-institution-error').show();
        $('#reg-institution').val('').trigger('keyup').addClass('-invalid');
      } else {
        $('#reg-institution-error').hide();
        $('#reg-institution').removeClass('-invalid');
      }
    });
  }
};
