/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import NewsListing from '@components/news-listing';

export default class NewsListingCenter extends NewsListing {
  /**
   * Initialize the Search.
   */
  constructor() {
    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.CENTER_NEWS_ENDPOINT;
    config.searchURL          = window.location.pathname;
    // eslint-disable-next-line camelcase
    config.defaultQueryParams = { limit: 20, pg: 1 };

    // Facets
    config.facetOptions = [{
      name: 'type',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.news-listing-center';
    super(config, prefix);
  }

}
