/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import SearchBase from '@components/search';
import { Config } from 'is-search';

export default class SearchResults extends SearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.SEARCH_ENDPOINT;
    config.searchURL          = process.env.SEARCH_URL;
    config.defaultQueryParams = { limit: 20, pg: 1, q: '' };

    super(config);

    this.cssPrefix = '.search-results';
    this._pageClassModifiers(this.cssPrefix);
    this.paginationEvents();

    $('html')
      // keyword searching
      .on('click', this.searchSubmit, this._keywordSearch.bind(this))
      .on('keyup', this.searchInput, (e) => {
        if (e.keyCode === 13) {
          $(this.searchSubmit).click();
        }
      })
      .on('click', '.js-search-input-clear', (e) => {
        e.preventDefault();
        $('.js-search-field').val('');
        $('.js-search-submit').click();
      })
    ;
  }

  paginationEvents() {
    // UI based click events for pagination.

    $('html')
      .on('click', this.paginationContainer + ' a', this._paginationScrollTop.bind(this))
      .on('click', this.paginationContainer + ' .pagination-prev', this.previousPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-next', this.nextPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-pages a', this._paginationTrigger.bind(this))
    ;
  }

  _pageClassModifiers(prefix) {
    this.searchInput = prefix + ' .js-search-field';
    this.searchSubmit = prefix + ' .js-search-submit';

    // container to add items to not sure if this is needed or should be different?
    this._searchContainer = prefix + ' .search-results-container';
    // container to add items to
    this._searchContainerItems = prefix + ' .search-results-container-listing';
    // container to show number of results
    // loader container
    this.searchLoading = prefix + ' .search-results-container .loading';
    this.noKeywords = prefix + ' .search-results-no-keywords';
    // no results message
    this.noResults = prefix + ' .search-results-none';
    this.paginationContainer = prefix + ' .search-results-container .pagination';

  }

  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);
    results.map((item) => {
      const summary = item.summary !== null ? `<p class="p -small">${item.summary}</p>` : '';
      const template = `
        <a href="${item.item_url}" class="search-results-item">
          <h3 class="h5 search-results-item-title">${item.title}</h5>
          <div class="search-results-item-url">${item.item_url}</div>
            ${summary}
        </a>
        `;

      $container.append(template);
    });

  }
}

