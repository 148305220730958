/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchListing from '@components/search-listing';

export default class InsightsListing extends SearchListing {
  /**
   * Initialize the Search.
   */
  constructor(config = false, prefix = '.insights-listing') {

    // All available default query parameters.

    if (!config) {
      config = new Config();
      config.endpoint           = process.env.INSIGHTS_ENDPOINT;
      config.searchURL          = window.location.pathname;
      config.defaultQueryParams = { limit: 10, pg: 1 };

      // Facets
      config.facetOptions = [{
        name: 'type',
        isMultiCardinality: false,
        default: null
      }];
    }

    super(config, prefix);

    this.cssPrefix = prefix;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {

    const $container = $(this._searchContainerItems);

    results.map((item) => {
      let teaser = '',
          image = '';

      let publication = '';
      if (item.publication) {
        publication = `<span class="p -small -italics">${item.publication}</span>`;
      }
      if (item.teaser) {
        teaser = `<p class="-small">${item.teaser}</p>`;
      }

      if (item.imageUrl) {
        image = `<div class="for-employers-listing-item-image">
          <img src="${item.imageUrl}" alt="${item.imageAlt || ''}"/>
        </div>`;
      }

      const template = `
        <a class="for-employers-listing-item" href="${item.url}">
          <div class="for-employers-listing-item-info">
            ${image}
            <div>
              <div class="for-employers-listing-item-eyebrow eyebrow">${item.type}</div>
              <h2 class="h5 for-employers-listing-item-title">${item.title}</h2>
              <div class="insights-listing-item-meta">
                <span class="p -small">${item.month} ${item.day}, ${item.year}</span>
                ${publication}
              </div>
              ${teaser}
            </div>
          </div>
        </a>`;

      $container.append(template);

    });
  }
}
