export default () => {
  if ($('.js-card-link').length || $('.scholarship-library-results').length) {
    $('html').on('click', '.js-card-link', (e) => {

      const $link = $(e.currentTarget).find('a'),
            href = $link.attr('href'),
            newTab = $link.attr('target') === '_blank' ? true : false
      ;

      if (newTab) {
        window.open(href, '_blank');
      } else {
        window.location = href;
      }

    });

    $('.js-card-link a').on('focusin focusout', (e) => {
      $(e.currentTarget).closest('.js-card-link').toggleClass('-hovered');
    });
  }
};