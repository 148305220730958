export default () => {
  if ($('.certification-path').length) {
    $('.certification-path-tab').on('click', (e) => {
      e.preventDefault();

      const $self = $(e.currentTarget),
            descriptionText = $self.find('.certification-path-tab-text').html(),
            activeReqs = $self.attr('data-active-reqs').replace(/ /g, '').split(',')
        ;
      $('.certification-path-tab').removeClass('-active');
      $self.addClass('-active');
      $('.certification-path-mobile-text').html(descriptionText);

      $('.certification-path-requirements-check').removeClass('-active').map((i, item) => {
        if (activeReqs.indexOf((i + 1).toString()) !== -1) {
          $(item).addClass('-active');
        }
      });

    });

    $('.certification-path-tab').eq(1).click();
  }
};
