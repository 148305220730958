export default () => {
  if ($('.search-facet').length) {
    $('html').on('click', '.search-facet-toggle', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      $this.parent().parent().toggleClass('-closed');
      $this.parent().next().slideToggle();
    });
  }
};
