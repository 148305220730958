import gtmHelper from '@plugins/gtm-helper';

export default () => {

  if ($('.hero-find-ce').length) {
    $('.js-ce-select-all').on('click', (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget),
            setName = $this.attr('data-set'),
            selected = $this.attr('data-selected') === 'false' ? false : true,
            setItems = $(`.${setName} input`),
            $span = $this.find('span')
          ;

      setItems.map((i, item) => {
        if (selected) {
          $(item).prop('checked', false);
        } else {
          $(item).prop('checked', true);
        }
      });

      selected ? $span.html('Select All') : $span.html('Unselect All');
      $this.attr('data-selected', !selected);
    });

    $('html').on('change', '.hero-find-ce-form-field-container.topics', (e) => {
      const element = $(e.target),
            { name, value } = element[0];
      if (element.is(':checked')) {
        let rename = name;
        const formatNames = ['plain_live_sub_type', 'plain_ctt_code', 'plain_self_study_sub_type'];

        if (formatNames.indexOf(name) !== -1) {
          rename = 'format';
        }

        const dataMap = {
          event: 'Facet Selection',
          pageType: 'find-ce',
          facetGrouping: rename,
          facetValue: value
        };

        gtmHelper(dataMap);
      }
    });
  }
};