/* eslint-disable camelcase */
/* global Helpers process rpCompDatepicker urlHash */
/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "easyAutocomplete" }] */
import DashboardBase from '../dashboard';
import easyAutocomplete from 'easy-autocomplete';
import moment from 'moment';

const dummyObject = {
  photoUrl: 'https://login.cfp.net/eweb/photos/333616.jpg',
  AllCertRequirementsMet: true,
  evt_title: 'Mar20 - Initial Cert Exam',
  c21_description: 'Registered Program',
  ExamHistoryFlag: false,
  evt_end_date: '/Date(1584403200000)/',
  evt_end_date_fmt: '03/17/2020',
  evt_early_registration_date: '/Date(1578355200000)/',
  evt_early_registration_date_fmt: '01/07/2020',
  evt_cutoff_date: '/Date(1582588800000)/',
  evt_cutoff_date_fmt: '02/25/2020',
  evt_late_registration_date: '/Date(1581379200000)/',
  evt_late_registration_date_fmt: '02/11/2020',
  evt_change_deadline_ext: '/Date(1581465600000)/',
  evt_change_deadline_ext_fmt: '02/12/2020',
  evt_start_date: '/Date(1583798400000)/',
  evt_start_date_fmt: '03/10/2020',
  evt_end_date_2: null,
  evt_end_date_fmt_2: '03/17/2020',
  evt_early_registration_date_2: '/Date(1578355200000)/',
  evt_early_registration_date_fmt_2: '01/07/2020',
  evt_cutoff_date_2: '/Date(1582588800000)/',
  evt_cutoff_date_fmt_2: '02/25/2020',
  evt_late_registration_date_2: '/Date(1581379200000)/',
  evt_late_registration_date_fmt_2: '02/11/2020',
  evt_change_deadline_ext_2: '/Date(1581465600000)/',
  evt_change_deadline_ext_fmt_2: '02/12/2020',
  evt_start_date_2: null,
  evt_start_date_fmt_2: '03/10/2020',
  c18_rp_compdate: '/Date(1561939200000)/',
  c18_rp_compdate_fmt: '07/01/2019',
  c18_student_id: '32452342',
  c18_program_email_address: 'testuser@domain.com',
  c31_ce_end_date: null,
  c31_ce_end_date_fmt: '',
  c31_ce_metdate: null,
  c31_ce_metdate_fmt: '',
  DegreeMessage: 'Submit Transcript',
  CourseMessage: 'Complete Education',
  CEMessage: '',
  ExamMessage: 'Take Advantage of Resources',
  RegisterMessage: 'Register for Exam',
  ExpMessage: 'Complete Experience',
  EthicsMessage: 'Submit Application',
  c18_org_cst_key: '879eb595-1fb6-413c-8915-7c7741e75c5d',
  c18_c23_key: null,
  ExamStatus: '',
  ind_cst_key: '84a29fc6-b3f0-482d-99fd-783221d501eb',
  ind_first_name: 'Lily',
  ind_certstatus_ext: 'N/A',
  c19_appren_hrs_roll_up: 0,
  ApprenticeThreshold: 4000,
  c19_stand_hrs_roll_up: 0,
  StandardThreshold: 6000,
  c31_ce_accepted: 0,
  c31_ce_required: 0,
  c31_ce_code_of_ethics: 0,
  c31_ce_coe_required: 2,
  ind_last4SSN_ext: null,
  DegreePerc: 50,
  CoursePerc: 50,
  CEPerc: 0,
  ExamPerc: 31,
  RegisterPerc: 0,
  ExpPerc: 0,
  EthicsPerc: 0,
  cst_recno: 333616,
  DegreeVerified: false,
  deg_has_degree_flag_ext: true,
  deg_transcript_requested_flag_ext: false,
  CourseVerified: false,
  p44_handout_flag: true,
  p44_study_plan_flag: true,
  p44_talk_employer_flag: false,
  p44_forum_flag: false,
  p44_practice_exam_flag: false,
  p44_review_course_flag: false,
  hasPracticeExam: false,
  evt_release_exam_status_online_flag_ext: false,
  CandidateStatus: 8,
  CandidateStatusName: 'CompleteYourCoursework',
  ExamRegEnabled: false,
  ExamRegTooManyAttempts: true,
  ExamRegTooManyRecent: false,
  ExamRegDatesNotMet: false,
  ExamRegNoOpenExam: false
};

export default class CandidateDashboard extends DashboardBase {
  constructor(heroOnly = false) {
    super(heroOnly);
    this.contentEndpoint = process.env.CANDIDATE_DASH_ENDPOINT;
    // inputs to watch
    this.inputs = {
      degree: $('#confirm-degree'),
      transcript: $('#transcript-sent'),
      handout: $('#handout'),
      studyPlan: $('#study-plan'),
      talkEmployer: $('#talk-employer'),
      forum: $('#forum-support'),
      practiceExam: $('#practice-exam'),
      reviewCourse: $('#review-course')
    };
    this.tabs = ['education', 'exam', 'experience', 'ethics'];
    this.deepLink = urlHash ? urlHash : false;
    this.resizeRunOnceDesktop = true;
    this.resizeRunOnceMobile = true;
    this.url = typeof process.env.CANDIDATE_URL !== 'undefined' ? process.env.CANDIDATE_URL : '/account/my-account';
    this.refreshCount = 0;
  }

  getData() {
    if (typeof Helpers !== 'undefined') {
      // builds once in sitecore and Helpers are vailable
      Helpers.UserHelper.getDashboard(this.buildCandidateDash.bind(this));
    } else {
      // builds for static
      const params = new URLSearchParams(window.location.search),
            status = params.get('s');
      $.ajax({
        url: 'https://test-mock-server.herokuapp.com/candidate_dash'
      }).done((data) => {
        // dash example scenarios
        // /candidate/candidate.html?s=new
        // /candidate/candidate.html?s=rp_new
        // /candidate/candidate.html?s=allowed_to_register
        // /candidate/candidate.html?s=registered_not_passed
        // /candidate/candidate.html?s=passed

        if (status in data) {
          this.buildCandidateDash(true, null, null, null, data[status]);
        } else {
          this.buildCandidateDash(true, null, null, null, dummyObject);
        }
      });
    }
  }

  /**
   * Main callback to be passed to the Helper function, gets the data and builds out dashboard
   * @param {boolean} success - if call was successful or not
   * @param {string} failureReason - reason for failure
   * @param {object} dashboard - common dashboard JSON
   * @param {object} proDashboard - pro dashboard JSON
   * @param {object} candidateDashboard - candidate dashboard JSON
   */
  buildCandidateDash(success, failureReason, dashboard, proDashboard, candidateDashboard) {
    // console.log(candidateDashboard);

    if (success && this.heroOnly) {
      this.updateProgressTracker(candidateDashboard);
      this.updateHero(candidateDashboard);
    } else if (success) {
      this.response = candidateDashboard;
      this.dashEvents();
      this.updateProgressTracker(candidateDashboard);
      this.updateHero(candidateDashboard);
      this.candidateEducationTab(candidateDashboard);
      this.candidateExamTab(candidateDashboard);
      this.candidateExperienceTab(candidateDashboard);
      this.candidateEthicsTab(candidateDashboard);
      // finish loading
      this.loading(false);
      this.refreshCount += 1;
    } else {

      // eslint-disable-next-line no-console
      console.log(failureReason);
    }
  }

  /**
   * Adds all event listeners, called upon initialization
   */
  eventListeners() {
    this.verifyDegreeEvents();
    this.courseWorkEvents();
    this.prepareEvents();
  }

  // checks progress to determine which tab to open on page load
  getCurrentStep() {
    if (this.response.CoursePerc !== 100 || this.response.DegreePerc !== 100) {
      return 0;
    }
    if (this.response.RegisterPerc !== 100 || this.response.ExamPerc !== 100) {
      return 1;
    }
    if (this.response.ExpPerc !== 100) {
      return 2;
    }
    return 3;
  }

  dashEvents() {
    // checks if we found a hash in the url in order to deep link to different parts of the dashboard
    if (this.deepLink) {
      this.navigateDeeplink(this.deepLink);
    } else {
      window.lastOpenedTab = this.getCurrentStep();
    }

    $(window).on('load resize', () => {
      this.changeTab(window.lastOpenedTab);
    });

    // updates global variable on accordion changes
    $('.content-accordion-item-title').on('click', (e) => {
      window.lastOpenedTab = $(e.currentTarget).data('index');
    });

    // progress tracker jump links click event
    $('html').on('click', '.dashboard-table-progress-status .button', (e) => {
      if (window.location.pathname === this.url) {
        e.preventDefault();
        const target = e.currentTarget.hash;
        this.navigateDeeplink(target);
      }
    });
  }

  // changes tab and updates mobile/desktop tabs based on window size
  changeTab(tab) {
    if ($(window).width() > 899 && this.resizeRunOnceDesktop) {
      this.resizeRunOnceMobile = true;
      $('.subnav-tabs-link').eq(tab).click();
      this.resizeRunOnceDesktop = false;
    } else if ($(window).width() < 900 && this.resizeRunOnceMobile) {
      this.resizeRunOnceDesktop = true;
      $('.content-accordion-item').removeClass('-open');
      $('.content-accordion-item-content').css('display', '');
      $('.content-accordion-item-title').eq(tab).click();
      this.resizeRunOnceMobile = false;
    }
  }

  navigateDeeplink(hash) {
    const $hash = $(hash);
    if ($hash.length) {
      const hashSplit = hash.replace('#', '').split('-');
      const tab = this.tabs.indexOf(hashSplit[0]);
      this.resizeRunOnceDesktop = true;
      this.resizeRunOnceMobile = true;
      this.changeTab(tab);
      $(`.dashboard-mobile-tabs-link[data-id="${hashSplit[1]}"]`).click();
      setTimeout(() => {
        $('html, body').stop().animate({
          'scrollTop': $hash.offset().top - 50
        }, 300);
        window.location.hash = hash;
      }, 100);
    } else {
      window.lastOpenedTab = window.lastOpenedTab || this.getCurrentStep();
    }
  }

  /**
   * Updates percentages in progress tracker
   * @param {*} response
   */
  updateProgressTracker(response) {
    const degreeProgress = '#candidate-progress-degree',
          degreeStatusContainer = '#candidate-progress-degree-status',
          courseworkProgress = '#candidate-progress-coursework',
          courseworkStatusContainer = '#candidate-progress-coursework-status',
          prepareProgress = '#candidate-progress-prepare',
          prepareStatusContainer = '#candidate-progress-prepare-status',
          examProgress = '#candidate-progress-exam',
          examStatusContainer = '#candidate-progress-exam-status',
          experienceProgress = '#candidate-progress-experience',
          experienceStatusContainer = '#candidate-progress-experience-status',
          ethicsProgress = '#candidate-progress-ethics',
          ethicsStatusContainer = '#candidate-progress-ethics-status';

    // EDUCATION
    // Degree
    this.updateTrackerBar(degreeProgress, response.DegreePerc);
    this.updateTrackerMessage(response.DegreeMessage, degreeStatusContainer, 'degree');

    // Coursework
    this.updateTrackerBar(courseworkProgress, response.CoursePerc);
    this.updateTrackerMessage(response.CourseMessage, courseworkStatusContainer, 'coursework');

    // EXAM
    // Prepare
    this.updateTrackerBar(prepareProgress, response.ExamPerc);
    this.updateTrackerMessage(response.ExamMessage, prepareStatusContainer, 'prepare');

    // Register & Pass
    this.updateTrackerBar(examProgress, response.RegisterPerc);
    this.updateTrackerMessage(response.RegisterMessage, examStatusContainer, 'register');

    // EXPERIENCE
    this.updateTrackerBar(experienceProgress, response.ExpPerc);
    this.updateTrackerMessage(response.ExpMessage, experienceStatusContainer, 'experience');

    // ETHICS
    this.updateTrackerBar(ethicsProgress, response.EthicsPerc);
    this.updateTrackerMessage(response.EthicsMessage, ethicsStatusContainer, 'ethics');

    if (!this.heroOnly) {
      $('.dashboard-table-progress-candidate-id').html(`CFP Board ID # ${this.response.cst_recno}`);
    } else {
      $('.dashboard-table-progress-candidate-id').remove();
    }
  }

  checkPerc(perc) {
    return perc > 100 ? 100 : perc;
  }

  updateTrackerMessage(msg, id, key) {
    const buttonMapping = {
      degree: {
        'Verfied': false,
        'Pending Verification': false,
        'Complete Degree': '#education-verify',
        'Submit Transcript': '#education-verify'
      },
      coursework: {
        'Verified': false,
        'Pending Verification': false,
        'Complete Education': '#education-coursework',
        'Find a Program': '#education-coursework'
      },
      prepare: {
        'Complete': false,
        'Take Advantage of Resources': '#exam-prepare',
        'Find Resources': '#exam-prepare'
      },
      register: {
        'Verified': false,
        'Results Pending': false,
        'Continue to Prepare': '#exam-prepare',
        'Register for Exam': '#exam-register'
      },
      experience: {
        'Verified': false,
        'Report Experience': '#experience-report',
        'Pending Verification': '#experience-report',
        'Complete Experience': '#experience-report'
      },
      ethics: {
        'Verified': false,
        'Pending Review': false,
        'Submit Application': '#ethics-payment'
      }
    };

    const markup = buttonMapping[key][msg] ? this.insertProgressButton(msg, buttonMapping[key][msg]) : msg;
    $(id).html(markup);
  }

  updateTrackerBar(el, num) {
    const percentage = this.checkPerc(num) + '%';
    $(el).width(percentage);
    const printPercentage = $(el).find('.dashboard-table-progress-bar-print');
    if (printPercentage.length) {
      printPercentage.html(percentage);
    } else {
      $(el).html(`<span class="dashboard-table-progress-bar-print print-only">${percentage}</span>`);
    }
  }
  // -----------------------------
  //  EDUCATION TAB
  // -----------------------------

  /**
   * Calls all sections of the education tab to build
   * @param {*} response
   */
  candidateEducationTab(response) {
    // Verify Degree
    this.verifyDegree(response);
    this.courseWork(response);
    this.ceProgress(response);

  }

  /**
   *  Verify Degree Section
   * @param {*} param
   *
   * Default state: 2 checkboxes for confirming degree and that transcripts were requested
   * If both degree flag and transcript requested flag, show unverified message from CMS
   * If degree has been confirmed then show verified message from CMS
   */
  verifyDegree({DegreeVerified, deg_has_degree_flag_ext, deg_transcript_requested_flag_ext}) {
    const $tableBody = $('.confirm-degree .dashboard-table-body'),
          $tableHeader = $('.confirm-degree .dashboard-table-header-title');

    if (this.refreshCount === 0) {
      this.verifyDegreeMsgDefault = $('.confirm-degree-msg-default').clone();
      this.verifyDegreeMsgProcessing = $('.confirm-degree-msg-processing').clone();
    }

    if (deg_has_degree_flag_ext) {
      this.inputs.degree.attr('checked', true);
    }

    if (deg_transcript_requested_flag_ext) {
      this.inputs.transcript.attr('checked', true);
      $('.confirm-degree-msg').html(this.verifyDegreeMsgProcessing).show();
    } else {
      $('.confirm-degree-msg').html(this.verifyDegreeMsgDefault).show();
    }

    this.verifyDegreeBody = $tableBody.html();

    if (DegreeVerified) {
      $tableBody.html(this.content.verify_degree.verified.body);
      $tableHeader.html(this.content.verify_degree.verified.header);
    }
  }

  verifyDegreeEvents() {

    $('html')
      .on('change', '#confirm-degree, #transcript-sent', (e) => {
        const $this = $(e.currentTarget),
              $parent = $this.parent(),
              degreeIsChecked = this.inputs.degree.is(':checked'),
              transcriptIsChecked = this.inputs.transcript.is(':checked')
            ;

        this.disableDashboard($parent);
        if (typeof Helpers !== 'undefined') {
          // expects degree (boolean), transcript (boolean), and callback
          Helpers.UserHelper.updateDegree(degreeIsChecked, transcriptIsChecked, this.updateDashboardCallback.bind(this));

        } else {
          setTimeout(() => {
            dummyObject.deg_has_degree_flag_ext = degreeIsChecked;
            dummyObject.deg_transcript_requested_flag_ext = transcriptIsChecked;
            this.candidateEducationTab(dummyObject);
            this.enableDashboard($parent);
          }, 1150);
        }
      })
    ;
  }

  /**
   * Coursework Section
   * @param {object} response
   */
  courseWork({CourseMessage, c21_description, c18_org_cst_key, c18_c23_key, c18_rp_compdate, ind_last4SSN_ext, StudentID, ProgramEmailAddress}) {
    const $tableBody = $('.coursework .dashboard-table-body'),
          $tableTitle = $('.coursework .dashboard-table-header-title'),
          $headerLink = $('.find-rp-dashboard-enroll-link');

    if (CourseMessage === 'Verified') {
      $headerLink.remove();
      $tableBody.html(this.content.complete_coursework.verified.body);
    // If someone is on an alternate education path than RP
    } else if (c21_description !== null && c21_description !== '' && c21_description !== 'Registered Program') {
      $headerLink.remove();
      $tableBody.html(this.content.complete_coursework.alternate.body);
      $tableTitle.html(c21_description);
    // If someone has indicated RP path, show them the coursework submit form
    } else if (c21_description === 'Registered Program' && (CourseMessage === 'Complete Education' || CourseMessage === 'Pending Verification')) {
      $headerLink.remove();
      $tableTitle.html('Program Details');

      $('.find-rp-dashboard-search').remove();
      $('.find-rp-dashboard-submit').show().addClass('-active');

      // update save button text to update and remove cancel
      $('.js-submit-coursework').text('Update');
      $('.js-cancel-coursework-submit').remove();

      // if they have an org key find the name for it and then also program name if they have that
      if (c18_org_cst_key !== null && c18_org_cst_key.length) {
        $.ajax({
          url: `${process.env.PROVIDER_NAME_BY_KEY_ENDPOINT}?key=${c18_org_cst_key}`
        }).done((data) => {

          $('#provider-key').val(c18_org_cst_key);
          $('#program-name').attr('disabled', false);
          $('#provider-name').attr('readonly', true);
          $('.field-container.-program-name').slideDown();
          $('#clear-provider').show();
          $('#provider-name').val(data.result.organization_name);

          $.ajax({
            url: `${process.env.PROGRAM_NAME_ENDPOINT}?orgCstKey=${c18_org_cst_key}&program=`
          }).done((data) => {
            $('#program-name').empty();
            $('#program-name').append('<option value="" selected>Select Program Name</option>');
            data.results.forEach((item) => {
              const selected = item.c23_key === c18_c23_key ? ' selected' : '';
              $('#program-name').append(`<option value="${item.c23_key}"${selected}>${item.program_title}</option>`);
            });

            this.validateCoursework();
          });
        });
      }

      // update completion date if exists
      if (c18_rp_compdate !== null && c18_rp_compdate.length) {
        const compDate = moment.utc(c18_rp_compdate).format('MM/DD/YYYY');
        $('#completion-date').val(compDate);
        rpCompDatepicker.setDate(compDate);
      }

      // update SSN if exists
      if (ind_last4SSN_ext !== null && ind_last4SSN_ext.length) {
        $('#ssn-1').val(ind_last4SSN_ext[0]);
        $('#ssn-2').val(ind_last4SSN_ext[1]);
        $('#ssn-3').val(ind_last4SSN_ext[2]);
        $('#ssn-4').val(ind_last4SSN_ext[3]);
      }

      if (StudentID !== null && StudentID.length) {
        $('#c18_student_id').val(StudentID);
      }

      if (ProgramEmailAddress !== null && ProgramEmailAddress.length) {
        $('#c18_program_email_address').val(ProgramEmailAddress);
      }

      this.validateCoursework();
    }
  }

  /**
   * Coursework Section Events
   * all the events for submitting coursework
   * @param {object} response
   */
  courseWorkEvents() {
    $('#provider-name').easyAutocomplete({
      url(e) {
        return `${process.env.PROVIDER_NAME_ENDPOINT}?org=${e}`;
      },
      getValue: 'organization_name',
      requestDelay: 300,
      minCharNumber: 2,
      listLocation: 'results',
      list: {
        maxNumberOfElements: 5,
        match: {
          enabled: true
        },
        showAnimation: {
          type: 'slide',
          time: 250
        },
        hideAnimation: {
          type: 'slide',
          time: 250
        },
        onChooseEvent() {
          const cstKey = $('#provider-name').getSelectedItemData().org_cst_key;
          $('#provider-key').val(cstKey);
          $('#program-name').attr('disabled', false);
          $('#provider-name').attr('readonly', true);
          $('.field-container.-program-name').slideDown();
          $('#clear-provider').show();

          $.ajax({
            url: `${process.env.PROGRAM_NAME_ENDPOINT}?orgCstKey=${cstKey}&program=`
          }).done((data) => {
            $('#program-name').empty();
            $('#program-name').append('<option value="" selected>Select Program Name</option>');
            data.results.forEach((item) => {
              $('#program-name').append(`<option value="${item.c23_key}">${item.program_title}</option>`);
            });
          });
        }
      }
    });

    $('#clear-provider').on('click', (e) => {
      e.preventDefault();
      $('#provider-name').val('');
      $('#provider-name').attr('readonly', false);
      $('#provider-key').val('');
      $('.field-container.-program-name').slideUp();
      $('#program-name').attr('disabled', true);
      $('#program-name').empty();
      $('#clear-provider').hide();
      setTimeout(() => {
        $('#provider-name').focus();
      }, 50);
    });

    $('.js-submit-coursework').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);

      const { preventSubmit,
        providerKey,
        programKey,
        completionDate,
        ssn,
        studentID,
        emailAddress } = this.validateCoursework();

      if (preventSubmit) {
        return false;
      } else if (typeof Helpers !== 'undefined') {
        $this.attr('data-text', $this.text())
          .addClass('-loading')
          .text('Loading');

        // provider key example "46FDCE2C-A7E6-4DFC-A778-DC730338F35C",
        // program key example "C7550090-15DD-43D7-8FE6-BE2521DF62AE",
        // date format example "2019-01-03",
        // ssn format example "3452"
        return Helpers.UserHelper.updateEducation(providerKey, programKey, completionDate, ssn, studentID, emailAddress, this.updateDashboardCallback.bind(this));
      } else {
        return $this.addClass('-loading');
        // return console.log(providerKey, programKey, completionDate, ssn);
      }
    });
  }

  validateCoursework() {
    let preventSubmit = false;
    // provider and program keys
    const providerKey = $('#provider-key').val(),
          programKey = $('#program-name').val();

    // validate provider key
    if (providerKey === null || providerKey.length <= 0) {
      $('.field-container.-provider-name .error-message').show();
      preventSubmit = true;
    } else {
      $('.field-container.-provider-name .error-message').hide();
    }

    // validate provider key
    if (programKey === null || programKey.length <= 0) {
      $('.field-container.-program-name .error-message').show();
      preventSubmit = true;
    } else {
      $('.field-container.-program-name .error-message').hide();
    }

    // validate completion date
    let completionDate = $('#completion-date').val();

    if (!completionDate && completionDate.length !== 10) {
      $('.field-container.-date .error-message').html('Completion date is incomplete or invalid.<br>Please enter in MM/DD/YYYY format.').show();
      preventSubmit = true;
    } else {
      const completionDateArr = completionDate.split('/');
      completionDate = `${completionDateArr[2]}-${completionDateArr[0]}-${completionDateArr[1]}`;
      $('.field-container.-date .error-message').hide();
    }

    // validate SSN
    const numericTest = RegExp('^\\d{1,45}$');
    let ssn = '';
    $('#ssn input').map((i, item) => {
      ssn += $(item).val();
    });

    if (ssn.length !== 4) {
      $('.field-container.-ssn .error-message').html('SSN is incomplete or invalid').show();
      preventSubmit = true;
    } else if (!numericTest.test(ssn)) {
      $('.field-container.-ssn .error-message').html('Invalid or non-numeric SSN.').show();
      preventSubmit = true;
    } else {
      $('.field-container.-ssn .error-message').hide();
    }

    let studentID = '';
    let emailAddress = '';
    let useOptionalFields = false;
    useOptionalFields = ($('.find-rp-dashboard-submit.-active').first().attr('optionalfields') === 'true');

    if (useOptionalFields) {
      studentID = $('#c18_student_id').val();
      emailAddress = $('#c18_program_email_address').val();

      const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

      // validate program email address
      if (emailAddress !== null && emailAddress.length > 0) {
        if (!validateEmail(emailAddress)) {
          $('.field-container.-email-address .error-message').show();
          preventSubmit = true;
        } else {
          $('.field-container.-email-address .error-message').hide();
        }
      } else {
        $('.field-container.-email-address .error-message').hide();
        emailAddress = '';
      }
    }

    return { preventSubmit,
      providerKey,
      programKey,
      completionDate,
      ssn,
      studentID,
      emailAddress};
  }

  /**
   * Continuing Ed Progress Section
   * @param {object} response
   */
  ceProgress({c31_ce_required}) {
    const ceNotRequired = c31_ce_required === 0 ? true : false;

    // CE Progress
    if (ceNotRequired) {
      $('.ce-progress').remove();
    } else {
      const ceProgress = [
        {
          selector: '#ce-hours-earned',
          property: 'c31_ce_accepted'
        },
        {
          selector: '#ce-hours-required',
          property: 'c31_ce_required'
        },
        {
          selector: '#ce-ethics-hours-earned',
          property: 'c31_ce_code_of_ethics'
        },
        {
          selector: '#ce-ethics-hours-required',
          property: 'c31_ce_coe_required'
        },
        {
          selector: '#ce-due-date',
          property: 'c31_ce_end_date_fmt'
        }
      ];
      this.updateValues(ceProgress);
    }
  }

  // -----------------------------
  //  EXAM TAB
  // -----------------------------

  /**
   * Calls all sections of the education tab to build
   * @param {*} response
   */
  candidateExamTab(response) {
    this.register(response);
    this.prepare(response);
  }

  /**
   * Register Section
   * Review logic here:
   * https://interactivestrategies.atlassian.net/wiki/spaces/CFP/pages/404684943/Candidate+Dashboard+-+Exam
   * @param {string} ExamStatus - should provide status of exam, something like passed, failed, registered, withdrawn
   */
  register({ExamStatus, evt_release_exam_status_online_flag_ext, ExamRegEnabled}) {
    const $registerBtn = $('#register-exam-button'),
          $updateBtn = $('#update-test-details-button'),
          $tableBody = $('.exam-dates .dashboard-table-body'),
          $tableHeader = $('.exam-dates .dashboard-table-header-title'),
          status = ExamStatus.toLowerCase();

    // register04 screen, shows that they passed the exam
    if (evt_release_exam_status_online_flag_ext && status === 'passed') {
      $registerBtn.remove();
      $updateBtn.remove();
      $tableHeader.html(this.content.register.passed.header);
      $tableBody.html(`<div class="dashboard-table-body-inner">${this.content.register.passed.body}</div>`);
    // results03 screen, shows results are pending until they are verified
    } else if (!evt_release_exam_status_online_flag_ext && (status === 'passed' || status === 'failed')) {
      $registerBtn.remove();
      $updateBtn.remove();
      $tableHeader.html(this.content.register.pending.header);
      $tableBody.html(`<div class="dashboard-table-body-inner">${this.content.register.pending.body}</div>`);
    // results02 just shows they are registered for the exam, only thing different is that they will have a button to update test details
    } else if (status === 'registered') {
      $registerBtn.remove();
      this.updateExamDates();
    // disabling button
    } else if (!ExamRegEnabled) {
      $updateBtn.remove();
      this.disableRegButton();
      this.updateExamDates();
    // default, just remove the existing update button
    } else {
      $updateBtn.remove();
      this.updateExamDates();
    }
  }

  disableRegButton() {
    const $registerBtn = $('#register-exam-button'),
          $msgContainer = $('#exam-reg-disabled'),
          $textContainer = $('#exam-reg-disabled .wysiwyg-content');

    if (this.response.ExamReqTooManyAttempts) {
      $textContainer.html(this.content.exam_reg.too_many_attempts);
    } else if (this.response.ExamReqTooManyRecent) {
      $textContainer.html(this.content.exam_reg.too_many_recent);
    } else if (this.response.ExamReqDatesNotMet) {
      $textContainer.html(this.content.exam_reg.dates_not_met);
    } else if (this.response.ExamReqNoOpenExam) {
      $textContainer.html(this.content.exam_reg.no_open_exam);
    }

    $registerBtn.attr('disabled', true);
    $msgContainer.show();
  }

  updateExamDates() {
    const startDateObj = this.returnDateObject(this.response.evt_start_date),
          endDateObj = this.returnDateObject(this.response.evt_end_date),
          dateString = this.returnDateRangeString(startDateObj, endDateObj);
    const examPairs = [
      {
        selector: '#next-exam-date',
        property: dateString,
        string: true
      },
      {
        selector: '#exam-date-early-bird',
        property: 'evt_early_registration_date_fmt'
      },
      {
        selector: '#exam-date-standard',
        property: 'evt_cutoff_date_fmt'
      },
      {
        selector: '#exam-date-late',
        property: 'evt_late_registration_date_fmt'
      },
      {
        selector: '#exam-date-verification',
        property: 'evt_change_deadline_ext_fmt'
      }
    ];

    if (this.checkProp(this.response, 'evt_start_date_2') && this.checkProp(this.response, 'evt_end_date_2')) {
      const startDateObj2 = this.returnDateObject(this.response.evt_start_date_2),
            endDateObj2 = this.returnDateObject(this.response.evt_end_date_2),
            dateString2 = this.returnDateRangeString(startDateObj2, endDateObj2);
      examPairs.push({
        selector: '#next-exam-date-2',
        property: dateString2,
        string: true
      });
    } else {
      $('#next-exam-date-2').remove();
    }

    this.updateValues(examPairs);
  }

  /**
   * Prepare Section
   * @param {object} response
   */
  prepare(response) {
    const prepChecks = $('.exam-prep-steps input[type="checkbox"]');
    prepChecks.map((i, input) => {
      const property = $(input).attr('data-property');
      if (response[property]) {
        $(input).attr('checked', true);
      } else {
        $(input).attr('checked', false);
      }
    });
  }

  prepareEvents() {
    $('html')
      .on('change', '.exam-prep-steps input[type="checkbox"]', (e) => {
        const $this = $(e.currentTarget),
              $parent = $this.parent(),
              handoutIsChecked = this.inputs.handout.is(':checked'),
              studyPlanIsChecked = this.inputs.studyPlan.is(':checked'),
              talkEmployerIsChecked = this.inputs.talkEmployer.is(':checked'),
              forumChecked = this.inputs.forum.is(':checked'),
              practiceExamIsChecked = this.inputs.practiceExam.is(':checked'),
              reviewCourseIsChecked = this.inputs.reviewCourse.is(':checked');

        this.disableDashboard($parent);
        if (typeof Helpers !== 'undefined') {
          // parameters: p44_handout_flag: boolean, p44_study_plan_flag: boolean, p44_talk_employer_flag: boolean, p44_forum_flag: boolean, p44_practice_exam_flag: boolean, p44_review_course_flag: boolean, dashBoardUpdateCallback
          Helpers.UserHelper.updateExamPrep(handoutIsChecked, studyPlanIsChecked, talkEmployerIsChecked, forumChecked, practiceExamIsChecked, reviewCourseIsChecked, this.updateDashboardCallback.bind(this));

        } else {
          setTimeout(() => {
            this.enableDashboard($parent);
          }, 1150);
        }
      });
  }

  // -----------------------------
  //  EDUCATION TAB
  // -----------------------------

  /**
   * Calls all sections of the education tab to build
   * @param {*} response
   */
  candidateExperienceTab(response) {
    this.experience(response);
  }

  /**
   * Experience Section
   * @param {object} response
   */
  experience() {
    // calculating hours remaining by subtracting from threshold and showing that number unless below 0, in which we show 0
    let standardHoursRemaining = this.response.StandardThreshold - this.response.c19_stand_hrs_roll_up;
    standardHoursRemaining = standardHoursRemaining <= 0 ? 0 : this.commafy(standardHoursRemaining);

    let apprenticeHoursRemaining = this.response.ApprenticeThreshold - this.response.c19_appren_hrs_roll_up;
    apprenticeHoursRemaining = apprenticeHoursRemaining <= 0 ? 0 : this.commafy(apprenticeHoursRemaining);

    const experienceHrs = [
      {
        selector: '#standard-hours',
        property: standardHoursRemaining,
        string: true
      },
      {
        selector: '#standard-hours-threshold',
        property: 'StandardThreshold',
        number: true
      },
      {
        selector: '#apprentice-hours',
        property: apprenticeHoursRemaining,
        string: true
      },
      {
        selector: '#apprentice-hours-threshold',
        property: 'ApprenticeThreshold',
        number: true
      }
    ];

    this.updateValues(experienceHrs);
  }

  // -----------------------------
  //  ETHICS TAB
  // -----------------------------

  /**
   * Calls all sections of the education tab to build
   * @param {*} response
   */
  candidateEthicsTab(response) {
    this.application(response);
    this.payment(response);
  }

  application(response) {
    // if there has been some ethics progress then remove application submission button
    if (response.EthicsPerc > 0) {
      $('#cert-application-button').remove();
      $('#ethics-commitment-wysiwyg').remove();
    } else {
      // else remove the view submission button and confirmation message
      $('#ethics-view-submitted-app-wysiwyg').remove();
      $('#view-submitted-application-button').remove();
    }
  }

  payment(response) {
    if (!response.AllCertRequirementsMet) {
      $('#payment-button').attr('disabled', true);
    }
  }
}
