/* global homeHeroItems */
export default () => {
  if ($('.hero-tracker').length && typeof homeHeroItems !== 'undefined') {
    const randNum = Math.floor(Math.random() * homeHeroItems.length);
    $('.hero-tracker-left-title').html(homeHeroItems[randNum].title);
    $('.hero-tracker-promo-text').html(homeHeroItems[randNum].text);

    const shouldHideImage = !homeHeroItems[randNum].image.url;
    if (shouldHideImage) {
      $('.hero-tracker-promo-image').remove();
    } else {
      $('.hero-tracker-promo-image img').attr('src', homeHeroItems[randNum].image.url);
      $('.hero-tracker-promo-image img').attr('alt', homeHeroItems[randNum].image.alt);
    }

    const shouldHideLink = !homeHeroItems[randNum].link.url || !homeHeroItems[randNum].link.text;
    if (shouldHideLink) {
      $('.hero-tracker-promo-link').remove();
    } else {
      $('.hero-tracker-promo-link').attr('href', homeHeroItems[randNum].link.url);
      $('.hero-tracker-promo-link').attr('target', homeHeroItems[randNum].link.target);
      $('.hero-tracker-promo-link span').text(homeHeroItems[randNum].link.text);
    }
  }
};
