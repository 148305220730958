export default () => {
  $('.dashboard-mobile-tabs-link').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget),
          itemIndex = $this.data('index'),
          accordionSection = $this.closest('.content-accordion-item-content'),
          tabSections = accordionSection.find('.dashboard-section'),
          sectionTabs = accordionSection.find('.dashboard-mobile-tabs-link')
        ;
    sectionTabs.attr('data-active', 'false');
    $this.attr('data-active', 'true');
    tabSections.attr('data-active', 'false');
    tabSections.eq(itemIndex).attr('data-active', 'true');

  });
};