/*
 * isSticky
 * Plugin
*/
export default () => {

  $.fn.isSticky = function(options) {
    const defaults = {
      element: $(this),
      // Z-index of element
      zIndex: '',
      // Stopper element selector, also accepts number value
      stopper: '',
      stopPoint: 0,
      // Class applied to element when it's stuck
      stuckClass: '-stuck',
      // offset from the top in case you have something like a fixed header
      offset: 0,
      table: false,
      // force stickiness without needing to scroll to element
      force: false,
      // if true stick to bottom
      bottom: false,
      // delay to be used when also using force
      delay: false
    };
    // Accepts custom stopper id or class
    const settings = $.extend({}, defaults, options);

    // Elements width
    let thisWidth = settings.element.outerWidth(),
        stickyPoint = settings.element.offset().top - settings.offset
    ;

    // Cache a clone sticky element
    const placeholder = $(this).clone()
      .width(thisWidth)
      .height(settings.element.outerHeight())
      .addClass('-cloned')
      .hide()
    ;

    // Where we want it to start being sticky
    settings.setStickyPoint = function() {
      stickyPoint = settings.element.offset().top - settings.offset;
    };

    // Checks if stopper was defined
    settings.hasStopper = function() {
      return 0 < $(this.stopper).length || typeof this.stopper === 'number' ? true : false;
    };

    // Inserts a placeholder where the element used to be to prevent content from moving
    settings.insertPlaceholder = function() {
      return this.element.after(placeholder);
    };
    settings.insertPlaceholder();

    // Sets position fixed vs static
    settings.setPosition = function() {
      return stickyPoint < $(window).scrollTop() ? 'fixed' : '';
    };

    // Sets top position
    settings.setStop = function(newStop) {
      if (newStop) {
        this.stopper = newStop;
      }

      if (typeof this.stopper === 'number') {
        this.stopPoint = this.stopper;
      } else if (0 < $(this.stopper).length) {
        this.stopPoint = $(this.stopper).offset().top - this.element.outerHeight() - this.offset;

      }
    };

    // Sets element's width when stuck
    settings.setWidth = function(e) {

      if (e.type === 'resize') {

        // Re-calculate width
        thisWidth = settings.element.outerWidth();

        // Reset width/height of placeholder
        placeholder.width(thisWidth).height(settings.element.height());
        settings.setStickyPoint();
      }

      return stickyPoint < $(window).scrollTop() ? thisWidth : '';
    };

    // Sets element's z-index when stuck
    settings.setIndex = function() {
      return typeof this.zIndex === 'number' && stickyPoint < $(window).scrollTop() ? this.zIndex : '';
    };

    // Sets element's class when stuck
    settings.setClass = function() {
      return stickyPoint < $(window).scrollTop() ? this.element.addClass(this.stuckClass) : this.element.removeClass(this.stuckClass);
    };

    // Appplies position, top, width and z-index when stuck
    settings.applyCSS = function(e) {
      const styleObject = {
        'position': 'fixed',
        'width': settings.setWidth(e),
        'z-index': settings.setIndex()
      };

      if (this.bottom) {
        styleObject.bottom = this.offset + 'px';
      } else {
        styleObject.top = this.offset + 'px';
      }

      return $(placeholder).css(styleObject);
    };

    // Resets CSS of element
    settings.resetCSS = function() {
      return this.element.css({
        'position': '',
        'top': '',
        'bottom': '',
        'width': '',
        'z-index': ''
      });
    };

    // Removes placeholder element
    settings.removePlaceholder = function() {
      return placeholder.remove();
    };

    // If this is for a table header, then we need to make
    // sure we are also keeping the inner cells widths updated
    settings.updateTableHeaders = function() {
      if (this.table) {
        const clonedThs = this.element.find('th');
        $(placeholder).find('th').map((i, th) => {
          $(th).width(clonedThs.eq(i).width());
        });
      }
    };

    // Actually does the turning on and off of the stickiness
    // set force to true if you just want to force stickiness
    settings.toggleSticky = function() {
      const windowTop = this.bottom ? ($(window).scrollTop() + $(window).innerHeight() - this.element.outerHeight()) : $(window).scrollTop();

      // if using force and also delay then check when scroll position gets past 1.5 times window height and show the thing
      if (this.force && this.delay) {
        if (($(window).height() * 1.5) < windowTop) {
          $(placeholder).css('transform', 'translateY(0)');
        } else {
          $(placeholder).css('transform', 'translateY(100%)');
        }
      }

      if (stickyPoint < windowTop || this.force) {
        $(placeholder).show();
        if (settings.hasStopper() && this.stopPoint < windowTop) {
          $(placeholder).hide();
        }
      } else {
        $(placeholder).hide();
      }
    };

    // Wrapper function to call necessary functions for functionality
    settings.stickyScroll = function(e) {
      settings.applyCSS(e);
      settings.updateTableHeaders();
      settings.setClass();
      settings.setStop();
      settings.toggleSticky();
    };

    // Initializes the sticky function and binds
    settings.init = function() {
      if ($(window).innerHeight() > this.element.outerHeight()) {
        $(window).on('scroll load resize', this.stickyScroll);
      }
    };

    // Removes event binding for sticky function, removes placeholder, and resets CSS of element
    settings.destroy = function() {
      $(window).off('scroll load resize', this.stickyScroll);
      settings.resetCSS();
      settings.removePlaceholder();
    };

    return settings;
  };

};
