export default () => {
  if ($('.faceted-search-results').length) {
    $('html')
      .on('click', '.js-toggle-filter', (e) => {
        e.preventDefault();
        $('html').attr('data-facets-open', 'true');
      })
      .on('click', '.js-filters-close', (e) => {
        e.preventDefault();
        $('html').attr('data-facets-open', 'false');
      })
      .on('click', '.js-toggle-sorting', (e) => {
        e.preventDefault();
        $('html').attr('data-sorting-open') === 'true' ? $('html').attr('data-sorting-open', 'false') : $('html').attr('data-sorting-open', 'true');
        $('.faceted-search-results-mobile-sort').slideToggle();
      })
    ;

    $(window).on('resize', (e) => {
      if ($(e.currentTarget).width() > 599) {
        $('html').attr('data-sorting-open', 'false');
        $('.faceted-search-results-mobile-sort').hide();
      }
      if ($(e.currentTarget).width() > 1099) {
        $('html').attr('data-facets-open', 'false');
      }
    });
  }
};
