/* eslint-disable camelcase */
/* global Helpers process*/
import moment from 'moment';
import DashboardBase from '../dashboard';
const dashObject = {
  'ind_cst_key': '5bc612cb-c6d8-4163-bea5-c6a08a47080c',
  'LandingPage': 'Certified',
  'ShowEmeritus': false,
  'ShowRPLink': false,
  'ShowCELink': false
};

const dummyObject = {
  'ApplicationEnabled': false,
  'ApplicationRequired': false,
  'CERequired': false,
  'CertInvoiceBalance': 0,
  'PaymentEnabled': false,
  'PriorCEPeriodFlag': true,
  'RenewalEnabled': true,
  'RenewalType': 'Regular',
  'c20_initial_certification_date': '/Date(1097452800000)/',
  'c20_initial_certification_date_fmt': '10/11/2004',
  'c31_ce_accepted': 12,
  'c31_ce_code_of_ethics': 0,
  'c31_ce_coe_required': 2,
  'c31_ce_end_date': '/Date(1609372800000)/',
  'c31_ce_end_date_fmt': '12/31/2020',
  'c31_ce_metdate': null,
  'c31_ce_metdate_fmt': '',
  'c31_ce_required': 30,
  'c32_fee_met_thru_date': '/Date(1577750400000)/',
  'c32_fee_met_thru_date_fmt': '12/31/2019',
  'c34_accredible_id': 12347725,
  'ceDueDate': '12/31/2020',
  'certDate': '10/11/2004',
  'cst_recno': 93495,
  'ind_certstatus_ext': 'Certified',
  'ind_cst_key': 'c0ba220d-3ef5-4d8f-b01f-fba96c78a94b',
  'ind_first_name': 'David',
  'photoUrl': 'https://login.cfp.net/eweb/photos/93495.jpg',
  'renewalDate': '12/31/2019',
  'c99_reinstatement_due_date_fmt': '7/1/2020'
};

export default class ProDashboard extends DashboardBase {
  constructor(heroOnly = false) {
    super(heroOnly);
    this.contentEndpoint = process.env.PRO_DASH_ENDPOINT;
    this.ceMessage = '#ce-message';
    this.appMessage = '#application-message';
    this.appButton = '#application-button';
    this.paymentMessage = '#payment-message';
    this.paymentButton = '#payment-button';
    this.ceComplete = false;
    this.appComplete = false;
  }

  getData() {
    if (typeof Helpers !== 'undefined') {
      // do the stuff right here
      Helpers.UserHelper.getDashboard(this.buildDash.bind(this));
    } else {
      const params = new URLSearchParams(window.location.search),
            status = params.get('s');

      $.ajax({
        url: 'https://test-mock-server.herokuapp.com/pro_dash'
      }).done((data) => {
        // console.log(data);

        if (status in data) {
          this.buildDash(true, null, data[status].dash, data[status].pro, null);
        } else {
          this.buildDash(true, null, dashObject, dummyObject, null);
        }
      });

    }
  }

  /**
   * Main callback to be passed to the Helper function, gets the data and builds out dashboard
   * @param {boolean} success - if call was successful or not
   * @param {string} failureReason - reason for failure
   * @param {object} dashboard - common dashboard JSON
   * @param {object} proDashboard - pro dashboard JSON
   * @param {object} candidateDashboard - candidate dashboard JSON
   */
  // eslint-disable-next-line no-unused-vars
  buildDash(success, failureReason, dashboard, proDashboard, candidateDashboard) {
    // console.log(dashboard, proDashboard);

    this.response = proDashboard;
    this.dashboard = dashboard;

    if (success && this.heroOnly) {
      this.updateProgressTracker();
      this.updateHero(proDashboard);
      this.updateCe();
    } else if (success) {
      this.dashboard = dashboard;
      this.updateProgressTracker();
      this.updateHero(proDashboard);
      this.updateCe();
      this.updateApplication();
      this.updatePayment();
      this.reorder();
      // finish loading
      this.loading(false);
      $('.dashboard-sections').slideDown(500);

    } else {
      // eslint-disable-next-line no-console
      console.log(failureReason);
    }
  }

  /**
   * Adds all event listeners, called upon initialization
   */
  eventListeners() {
    // add listeners here
  }

  /**
   * Updates percentages in progress tracker
   * @param {*} response
   */
  updateProgressTracker() {
    this.response.certDate = moment.utc(this.response.c20_initial_certification_date).format('M/D/YYYY');

    if ($('.hero-dashboard').length && this.response.RenewalType === 'Reinstate' && this.dashboard.LandingPage === 'ReinstateRenew') {
      this.response.renewalDate = this.response.c99_reinstatement_due_date_fmt;
      $('#renewal-deadline').prev().html('Reinstatement Deadline:');
    } else {
      this.response.renewalDate = moment.utc(this.response.c32_fee_met_thru_date).format('M/D/YYYY');
    }

    const progressPairs = [
      {
        selector: '#cfp-board-id',
        property: 'cst_recno'
      },
      {
        selector: '#cert-date',
        property: 'certDate'
      },
      {
        selector: '#renewal-deadline',
        property: 'renewalDate'
      },
      {
        selector: '#cert-status',
        property: 'ind_certstatus_ext'
      }
    ];
    this.updateValues(progressPairs);
  }

  /**
   * Function updates CE section
   */
  updateCe() {
    this.response.ceDueDate = moment.utc(this.response.c31_ce_end_date).format('M/D/YYYY');
    const cePairs = [
      {
        selector: '#ce-hours-earned',
        property: 'c31_ce_accepted'
      },
      {
        selector: '#ce-hours-needed',
        property: 'c31_ce_required'
      },
      {
        selector: '#ethics-hours-earned',
        property: 'c31_ce_code_of_ethics'
      },
      {
        selector: '#ethics-hours-needed',
        property: 'c31_ce_coe_required'
      },
      {
        selector: '#ce-reqs-due-date',
        property: 'ceDueDate'
      }
    ];
    this.updateValues(cePairs);

    if (this.response.RenewalType === 'Midterm') {
      $('#ce-requirements-status').html('Not Required for Renewal');
      const buttonText = $('#report-ce-link').text();
      $('#report-ce-link')
        .removeClass('button')
        .removeClass('-full')
        .addClass('-caps -arrow-after')
        .html(`<span>${buttonText}</span>`);

    } else if (this.response.c31_ce_metdate) {
      $('#ce-requirements-status').html('Complete');
      this._updateStatusIcon('#ce-requirements-section', 'check');
      const buttonText = $('#report-ce-link').text();
      $('#report-ce-link')
        .removeClass('button')
        .removeClass('-full')
        .addClass('-caps -arrow-after')
        .html(`<span>${buttonText}</span>`);
    } else {
      $('#ce-requirements-status').html('In Progress');
      this._updateStatusIcon('#ce-requirements-section', 'in-progress');
    }
  }

  /**
   * Function updates application section
   */
  updateApplication() {
    // defaults to Certification Renewal/Application - Awaiting CE Completion if none of the conditions below are met
    this.ceComplete = this.response.c31_ce_metdate ? true : false;
    let status = 'Awaiting CE Completion';
    let disableSection = true;

    // Certified - Not In Renewal or Certified Midterm Renewal
    if (this.response.RenewalEnabled === false) {
      status = 'Approved';
      this.arrowLink(this.content.view_cert_link.text, this.content.view_cert_link.url);
      this.appComplete = true;
      this._updateStatusIcon('#application-section', 'check');
      disableSection = false;

    // Certification Renewal/Application - Complete
    } else if (!this.response.ApplicationEnabled && (this.response.ApplicationRequired || this.response.RenewalType === 'Reinstate')) {
      status = 'Submitted';
      // checking if it's a reinstate renewal and background check is in progress
      if (this.response.RenewalType === 'Reinstate' && this.ceComplete && !this.response.PaymentEnabled) {
        $(this.appMessage).html(`<p class="-small">${this.content.step_2.app_review}</p>`);
      } else {
        $(this.appMessage).html(`<p class="-small">${this.content.step_2.complete}</p>`);
      }

      $(this.appButton).remove();
      this.appComplete = true;
      this._updateStatusIcon('#application-section', 'check');
      disableSection = false;

    // Certification Renewal/Application - Awaiting Application
    } else if (this.response.ApplicationEnabled) {
      $(this.appMessage).remove();
      $(this.appButton).attr('disabled', false);
      status = 'Awaiting Application';
      this._updateStatusIcon('#application-section', 'in-progress');
      disableSection = false;
    }

    disableSection ? $('#application-section').attr('data-disabled', 'true') : $('#application-section').attr('data-disabled', 'false');
    $('#application-status').html(status);
  }

  /**
   * Function updates payment section
   */
  updatePayment() {
    let status = 'Awaiting CE & Application';
    let disableSection = true;

    // check if payment button should be enabled
    if (this.response.PaymentEnabled) {
      $(this.paymentMessage).remove();
      $(this.paymentButton).attr('disabled', false);
    } else if (this.response.RenewalType === 'Reinstate') {
      $(this.paymentMessage).html(`<p class="-small">${this.content.step_3.app_review}</p>`);
    }

    if (!this.response.RenewalEnabled && this.response.ind_certstatus_ext === 'Certified') {
      status = 'Paid';
      this._updateStatusIcon('#fee-section', 'check');
      disableSection = false;

    // application is complete and CE is complete
    } else if (this.appComplete && (this.response.RenewalType === 'Midterm' || this.ceComplete)) {
      status = 'Awaiting Payment';
      this._updateStatusIcon('#fee-section', 'in-progress');
      disableSection = false;

    // only CE is complete or renewal type is midterm in which case only the application is required for fee payment
    } else if (this.ceComplete || this.response.RenewalType === 'Midterm') {
      status = 'Awaiting Application';

      if (this.response.RenewalType === 'Midterm' && this.response.ApplicationEnabled) {
        const midtermPaymentDesc = 'This button will be disabled until you have filled out the CFP® Certification Application in the previous step.';
        $(this.paymentMessage).html(`<p class="-small">${midtermPaymentDesc}</p>`);
      }

    // only application is complete
    } else if (this.appComplete) {
      status = 'Awaiting CE Completion';
    }

    disableSection ? $('#fee-section').attr('data-disabled', 'true') : $('#application-section').attr('data-disabled', 'false');
    $('#fee-status').html(status);
  }

  reorder() {
    if (this.dashboard.LandingPage === 'ReinstateRenew') {
      this._reorderSections('#application-section', '#ce-requirements-section', '#fee-section');
    } else if (this.response.RenewalType === 'Midterm') {
      this._reorderSections('#application-section', '#fee-section', '#ce-requirements-section');
      $('#ce-requirements-section').attr('data-gray-bg', 'true');
    }
  }

  _updateStatusIcon(section, icon) {
    $(section).find('.dashboard-section-steps-status-icon').attr('data-icon', icon);
  }

  _reorderSections(first, second, third) {
    const clones = [$(first).clone(), $(second).clone(), $(third).clone()];
    $('.dashboard-sections').empty();
    clones.map((section) => {
      $('.dashboard-sections').append(section);
    });
  }
}
