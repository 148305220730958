/* eslint-disable no-unused-vars */
/* global process */
import easyAutocomplete from 'easy-autocomplete';

export default () => {
  $(document).ready(function() {

    // $('#reg-college-uni-name-key, #test').on('input change', function() {
    //   if ($(this).val().trim() !== '') {
    //     $('#cant-find-college').prop('required', false);
    //   } else {
    //     $('#cant-find-college').prop('required', true);
    //   }
    // });

    // Add required attribute to input fields with data-req="true"
    $('[data-req="true"]').each(function() {
      const inputOrSelect = $(this).find('input, select').first();

      if (inputOrSelect.length > 0) {
        inputOrSelect.prop('required', true);
      }
    });

    let preselectedGroup = 'group_1a';

    $('#reg-type').val(preselectedGroup);

    function updateInputFields(selectedGroup) {
      $('.field-container').each(function() {
        const inputGroups = $(this).data('group');
        // const isActive = $(this).data('active');

        if ($(this).hasClass('-hide-on-load')) {
          $(this).slideUp();
          $(this).find('input, select').prop('required', false);
          return;
        }

        if (!inputGroups) {
          $(this).slideDown();
          return;
        }

        const groups = inputGroups.split(' ');
        if (groups.includes(selectedGroup)) {
          $(this).slideDown();
          $(this).find('input, select').prop('required', true);
        } else {
          $(this).slideUp();
          $(this).find('input, select').prop('required', false);
        }
      });

      $('#reg-type-container').show();
    }

    updateInputFields(preselectedGroup);

    $('.yes-no-radio').change(function(e) {
      const value = $(this).val();
      let $container = $(this).closest('.field-container').next('.field-container.graduation-date');

      // If this field is the bachelors graduation date, and the user
      // has selected one of the financial options in the reg-type field,
      // return early.
      const doesGroupAndContainerExists = !!($container.length > 0 && $container.data('group'));
      if (doesGroupAndContainerExists && !$container.data('group').includes(preselectedGroup)) {
        return;
      }

      // Reassigning the container to slide up or down when the graduation date container is not found.
      $container = $(this).closest('.field-container').next('.field-container.-hide-on-load');

      if (value.toLowerCase().includes('yes')) {
        $container.slideDown();
        $container.find('input, select').prop('required', true);
      } else {
        $container.slideUp();
        $container.find('input, select').prop('required', false);
      }
      // updateInputFields(preselectedGroup);
    });

    // Show/hide reg-other-prof based on reg-current-prof selection
    $('#reg-current-prof').change(function() {
      const selectedOption = $(this).val();
      const $otherProfContainer = $('#reg-other-prof').closest('.field-container');
      if (selectedOption === 'Other') {
        $otherProfContainer.slideDown();
        $(this).find('input, select').prop('required', true);
      } else {
        $otherProfContainer.slideUp();
        $(this).find('input, select').prop('required', false);
      }
    });

    // Update input fields when the page loads
    updateInputFields(preselectedGroup);

    // Show/hide input fields when the select option changes
    // Deselect radio buttons when the select option changes
    $('#reg-type').change(function() {
      const selectedGroup = $(this).val();
      preselectedGroup = selectedGroup;

      updateInputFields(selectedGroup);
      $('input[type="radio"]').prop('checked', false);
    });

    // Show/hide reg-field-of-study-other based on reg-field-of-study selection
    $('#reg-field-of-study').change(function() {
      const selectedOption = $(this).val();
      const $otherFieldContainer = $('#reg-field-of-study-other').closest('.field-container');
      if (selectedOption === 'other') {
        $otherFieldContainer.slideDown();
      } else {
        $otherFieldContainer.slideUp();
      }
    });

    // Show/hide input fields when the select option changes
    $('#reg-type').change(function() {
      const selectedField = $(this).val();
    });

    // Typeahead for institution name
    let registrationError = true;

    if ($('#reg-college-uni-name').length) {
      $('#reg-college-uni-name').easyAutocomplete({
        url(e) {
          const stateValue = $('#reg-college-uni-state').val();
          const lowerCaseE = e.toLowerCase();
          return `${process.env.REGISTRATION_COLLEGE_ENDPOINT}?sta_code=${stateValue}&institute=${lowerCaseE}`;
        },
        getValue: 'ins_institution',
        requestDelay: 300,
        minCharNumber: 2,
        listLocation: 'results',
        list: {
          maxNumberOfElements: 5,
          match: {
            enabled: false
          },
          showAnimation: {
            type: 'slide',
            time: 250
          },
          hideAnimation: {
            type: 'slide',
            time: 250
          },
          onChooseEvent() {
            registrationError = false;
            $('#reg-college-uni-name-error').hide();
            $('#reg-college-uni-name').removeClass('-invalid');
            const cstKey = $('#reg-college-uni-name').getSelectedItemData().ins_key;
            if ($('#reg-college-uni-name-key').length) {
              $('#reg-college-uni-name-key').val(cstKey);
            }
            const selectedItemValue = $('#reg-college-uni-name').getSelectedItemData().ins_institution;
            $('#reg-college-uni-name').val(selectedItemValue);
          }
        }
      });


      $('#clear-provider').on('click', (e) => {
        registrationError = true;

        e.preventDefault();
        $('#reg-college-uni-name').val('');
        $('#reg-college-uni-name').attr('readonly', false);
        $('#reg-college-uni-name-key').val('');
        $('.field-container.-program-name').slideUp();
        $('#reg-program-name').attr('disabled', true);
        $('#reg-program-name').empty();
        $('#clear-provider').hide();
        setTimeout(() => {
          $('#reg-college-uni-name').focus();
        }, 50);
      });

      // Event handler for the checkbox
      $('#cant-find-college').change(function() {
        const fieldContainer = $(this).closest('.field-container').prev('.field-container');
        const inputElement = fieldContainer.find('#reg-college-uni-name');
        const fieldContainerType = $(this).closest('.field-container').next('.field-container');
        const inputElementType = fieldContainerType.find('#reg-college-uni-name-manual-entry');

        if ($(this).is(':checked')) {
          fieldContainer.slideUp();
          inputElement.prop('required', false);
        } else {
          fieldContainer.slideDown();
          inputElement.prop('required', true);
        }

        fieldContainerType.addClass('foo');

        if (!$(this).is(':checked')) {
          fieldContainerType.slideUp();
          inputElementType.prop('required', false);
        } else {
          fieldContainerType.slideDown();
          inputElementType.prop('required', true);
        }
      });

      // Event handler for the input field blur
      $('#reg-college-uni-name').on('blur', function() {
        // Delay the execution of the blur event handler slightly
        setTimeout(() => {
          // Your existing blur event handler logic here
          if (registrationError) {
            $('#reg-college-uni-name-error').show();
            $('#reg-college-uni-name').val('').trigger('keyup').addClass('-invalid');
          } else {
            $('#reg-college-uni-name-error').hide();
            $('#reg-college-uni-name').removeClass('-invalid');
          }
        }, 100);
      });
    }

    $('#reg-college-uni-name').on('input change', function() {
      // Update the required attribute based on the input value
      updateRequiredAttribute();
    });

    function updateRequiredAttribute() {
      if ($('#reg-college-uni-name').val().trim() !== '') {
        $('#cant-find-college').prop('required', false);
      } else {
        $('#cant-find-college').prop('required', true);
      }
    }

    // Clear the input field when the checkbox is checked/unchecked
    $('#cant-find-college').change(function() {
      if ($(this).is(':checked')) {
        $('#reg-college-uni-name').val('');
        $('#reg-college-uni-name-key').val('');
      } else {
        $('#reg-college-uni-name-manual-entry').val('');
      }
    });
  });

};
