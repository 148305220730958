/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */

import { Config } from 'is-search';
import FacetedSearchBase from '../../faceted-search-results/faceted-search-base';

export default class FindCeResults extends FacetedSearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.CE_ENDPOINT;
    config.searchURL          = process.env.CE_URL;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    // Facets
    config.facetOptions = [{
      name: 'credit_hours',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'plain_self_study_sub_type',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'plain_live_sub_type',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'plain_ctt_code',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'complexity',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'primary_topics',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'state',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.find-ce-results';
    super(config, prefix);

    this.cssPrefix = prefix;

    // UI based events
    $('html')
      .on('click', '.hero-find-ce-form .js-search-submit', this._keywordSearch.bind(this))
      .on('keyup', '.hero-find-ce-form .js-search-field-hero', (e) => {
        if (e.keyCode === 13) {
          $('.hero-find-ce-form .js-search-submit').click();
        }
      })
      .on('click', '.hero-find-ce-form .js-hero-submit', this._heroFacetSearch.bind(this))
      .on('keyup', '.hero-find-ce-form input[type="checkbox"]', (e) => {
        if (e.keyCode === 13) {
          $('.hero-find-ce-form .js-hero-submit').click();
        }
      })
    ;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    // Build and output each individual search result.
    results.map((item) => {
      let approvedText = '',
          subtype = '';

      if (item.code_of_ethics !== '0') {
        approvedText = '<div class="ce-result-item-heading-approved">Approved Ethics Program</div>';
      }

      if (item.live_sub_type || item.self_study_subtype) {
        const subString = item.live_sub_type || item.self_study_subtype;
        subtype = ' / ' + subString;
      }

      const description = item.description.length > 250 ? item.description.substring(0, 247) + '...' : item.description;
      const featured = item.preferred ? 'true' : 'false';

      const template = `
      <a href="${item.item_url}" target="_blank" class="ce-result-item" data-featured="${featured}">
        <div class="ce-result-item-heading">
          ${approvedText}
          <div class="ce-result-item-heading-inner">
            <h2 class="h5 ce-result-item-heading-title">${item.cec_name}</h2>
            <div class="p -large -gray-dark ce-result-item-heading-org">${item.org_name}</div>
          </div>
        </div>
        <div class="ce-result-item-body">
          <div class="ce-result-item-body-column -description">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Description</div>
            <p class="-small -gray-dark">${description}</p>
          </div>
          <div class="ce-result-item-body-column -topic">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Topic</div>
            <p class="-small -gray-dark">${item.primary_topic}</p>
          </div>
          <div class="ce-result-item-body-column -program-id">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Program ID</div>
            <p class="-small -gray-dark">${item.cec_id}</p>
          </div>
          <div class="ce-result-item-body-column -hours">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Hours</div>
            <p class="-small -gray-dark">${item.total_ce_hours_granted_cp}</p>
          </div>
          <div class="ce-result-item-body-column -format">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Format</div>
            <p class="-small -gray-dark">${item.ctt_code}${subtype}</p>
          </div>
          <div class="ce-result-item-body-column -complexity">
            <div class="ce-result-item-body-column-heading h6 -gray-dark">Complexity</div>
            <p class="-small -gray-dark">${item.complexity}</p>
          </div>
        </div>
      </a>
      `;

      $container.append(template);

    });
  }

  /**
   * Triggered by on submit click on hero landing page to build url and send selecte facets to search results page
   *
   * @method _heroFacetSearch
   * @param {Object} e (event)
   */

  _heroFacetSearch(e) {
    e.preventDefault();
    const paramObject = {};
    $('.hero-find-ce-form input:checked').map((i, item) => {
      const name = $(item).attr('name'),
            val  = $(item).val();
      if (!(name in paramObject)) {
        paramObject[name] = [];
      }
      paramObject[name].push(val);
    });

    if (window.location.pathname !== this._searchURL) {
      const { defaultQueryParams, query, queryParams } = this,
            defaults = Object.keys(defaultQueryParams),
            options = defaults.concat(queryParams),
            compact = this.compact(options),
            uniqe   = this.uniq(compact)
      ;

      this.queryParams = uniqe;
      this.query       = this._initQuery();

      Object.keys(paramObject).map((item) => {
        this.query[item] = paramObject[item].join(',');
      });

      return window.location = this._buildURL();
    }
  }
}
