export default () => {
  if ($('.subnav').length || $('.subnav-center').length) {
    $('.subnav select, .subnav-center select').on('change', (e) => {
      const url = $(e.currentTarget).val();
      if (url) {
        window.location = url;
      }
      return false;
    });
  }
};