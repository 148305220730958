export default () => {
  const $component = $('.content-expander');

  if ($component.length) {

    $component.each((index, item) => {
      const $self = $(item),
            $toggle = $self.find('.content-expander-toggle'),
            $expandContainer = $self.find('.content-expander-bottom'),
            $text = $toggle.find('span');

      $toggle.on('click', (e) => {
        e.preventDefault();
        $expandContainer.slideToggle();
        const currentText = $text.text(),
              isExpanded = $toggle.attr('data-expanded');
        $text.text($toggle.attr('data-alt'));
        $toggle.attr('data-alt', currentText);
        $toggle.attr('data-expanded', isExpanded ? '' : 'true');
      });
    });
  }
};
