export default () => {
  if ($('.breadcrumbs').length) {
    setTimeout(() => {
      repositionDropdowns();
    }, 500);

    $('.breadcrumbs-wrapper').not('.-current, .-home').on('mouseenter mouseleave', (e) => {
      $('.breadcrumbs-wrapper').filter('.-current').toggleClass('-clip');

      // this sets a height on the wrapper equal to the absolute position dropdown
      // so that we can use overflow-x without getting a scroll bar on the y
      const $this = $(e.currentTarget);
      if (e.type === 'mouseenter') {
        const dropHeight = $this.find('.breadcrumbs-dropdown').height();
        $this.height($this.outerHeight() + 50 + dropHeight);
      } else if (e.type === 'mouseleave') {
        $this.height('auto');
      }

    });

    // open/close dropdowns on tabbing
    $('html').on('focus blur', '.breadcrumbs-wrapper.-has-children', (e) => {
      const $parent = $(e.currentTarget);
      if (e.type === 'mouseenter' || e.type === 'focusin') {
        $parent.addClass('-dropdown-open -clip');
        const dropHeight = $parent.find('.breadcrumbs-dropdown').height();
        $parent.height($parent.outerHeight() + 50 + dropHeight);
      } else {
        $parent.removeClass('-dropdown-open -clip');
      }
    });
    const delay = 50;

    $(window).on('resize', () => {
      const currentWinSize = $(window).outerWidth();
      setTimeout(() => {
        const newWinSize = $(window).outerWidth();
        if (newWinSize === currentWinSize) {
          repositionDropdowns();
        }
      }, delay);
    });
  }

  function repositionDropdowns() {
    // get position of the far right edge of the container the menu items are in
    // this will be the edge the dropdown items will be adjusted to if they are too wide
    const navWidth = $('.breadcrumbs-inner').offset().left + $('.breadcrumbs-inner').outerWidth();

    // get each dropdown and loop thorugh them
    $('.breadcrumbs-dropdown').map((i, item) => {
      // check the item's right position by getting the left and adding the width
      // also the transform object in case we have already moved this item
      const itemWidth = $(item).offset().left + $(item).outerWidth(),
            itemTransform = $(item).css('transform');
      let itemOffset = 0;

      if (itemTransform !== 'none') {
        const matrix = $(item).css('transform').split(/[()]/)[1];
        itemOffset = matrix.split(',')[4];
      }
      // compare the position of the dropdown with the edge of the nav container
      // move it with translate if needed by the difference
      if (itemWidth - itemOffset > navWidth) {
        $(item).css('transform', `translateX(-${itemWidth - navWidth - itemOffset}px)`);
      } else {
        $(item).css('transform', 'translateX(0px)');
      }
    });
  }

};
