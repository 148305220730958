/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */

import { Config } from 'is-search';
import FacetedSearchBase from '../../faceted-search-results/faceted-search-base';

export default class AchResults extends FacetedSearchBase {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.ACH_ENDPOINT;
    config.searchURL          = window.location.pathname;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };
    config.defaultSortParams  = { s: '' };
    config.sortParam          = 's';

    // Facets
    config.facetOptions = [{
      name: 'order_type',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'decision_values',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'private_censure',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'keyword_values',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'violation_values',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'matter_values',
      isMultiCardinality: true,
      default: null
    }, {
      name: 'decision_date',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'decision_date_lte',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'decision_date_gte',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.ach-results';
    super(config, prefix);

    this.cssPrefix = prefix;
    this.showMoreOption = true;

    // UI based events
    $('html')
      .on('click', '.js-ach-search-input-clear', (e) => {
        e.preventDefault();
        $('.ach-results .js-search-field').val('');
        $('.ach-results .js-search-submit').click();
      })
    ;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    // Build and output each individual search result.
    results.map((item) => {
      let decisionCol = '',
          keywordCol = '',
          matterCol = '',
          violationCol = '',
          caseContent = '',
          administrativeCol = ''
        ;

      const downloadPdf = item.case_document.length ? `<a href="${item.case_document}" class="-caps -pdf"><span>Download Case History</span></a>` : '';

      if (item.case_document_content && item.case_document_content.length) {
        caseContent = `<div class="ach-results-item-case-content"><div class="h6">Search Terms</div><p>${item.case_document_content}</p></div>`;
      }

      if (item.decisions.length) {
        let decisions = [];
        decisions = decisions
          .concat(item.decision_date, ', ', item.decisions.map(this._templateSpans))
          .join('');

        decisionCol = this._templateCol('Decision', 'decision', decisions);
      }

      if (item.keywords.length) {
        let keywords = [];
        keywords = keywords
          .concat(item.keywords.map(this._templateSpans))
          .join('');
        const keywordTitle = item.keywords.length > 1 ? 'Keywords' : 'Keyword';
        keywordCol = this._templateCol(keywordTitle, 'keywords', keywords);
      }

      if (item.matters.length) {
        let matters = [];
        matters = matters
          .concat(item.matters.map(this._templateSpans))
          .join('');
        const matterTitle = item.matters.length > 1 ? 'Matters' : 'Matter';
        matterCol = this._templateCol(matterTitle, 'matters', matters);
      }

      if (item.violations.length) {
        let violations = [];
        violations = violations
          .concat(item.violations.map(this._templateSpans))
          .join('');
        const violationTitle = item.violations.length > 1 ? 'Standards Violated' : 'Standard Violated';
        violationCol = this._templateCol(violationTitle, 'violations', violations);
      }
      if (item.administrative) {
        administrativeCol = this._templateAdministrative();
      }
      const template = `
        <div class="ach-results-item">
          <div class="ach-results-item-left">
            <div class="h5 ach-results-item-case">#${item.case_num}</div>
            <div class="h6">Summary</div>
            <p class="-small ach-results-item-summary">${item.summary}</p>
            <div class="buttons-wrapper">
              ${downloadPdf}
              <button class="show-more">Show More</button>
            </div>
            ${caseContent}
          </div>
          <div class="ach-results-item-right">
            ${decisionCol}
            ${keywordCol}
            ${matterCol}
            ${violationCol}
            ${administrativeCol}
          </div>
        </div>`;

      $container.append(template);

    });
  }

  _templateSpans(item) {
    return `<span>${item}</span>`;
  }

  _templateCol(title, className, content) {
    return `<div class="ach-results-item-right-col -${className}">
              <h6 class="h6">${title}</h6>
              <p class="-small">
                ${content}
              </p>
            </div>`;
  }
  _templateAdministrative() {
    return `<div class="btn ach-results-item-right-col -tooltip-btn -gray">
                        Administrative Order. Do not Cite.
                        <div class="tooltip -left-aligned -wide">
                          <a href="#" class="tooltip-toggle"><span class="sr-text">Toggle Tooltip</span></a>
                          <div class="tooltip-container">
                            <div class="tooltip-inner">
                              <a href="#" class="tooltip-close"><span class="sr-text">Close Tooltip</span></a>
                              <div class="p -small -gray-dark wysiwyg-content">Case Histories of Administrative Orders are for information purposes only and should not be cited. Orders of Administrative Suspension, Administrative Temporary Bar, Administrative Revocation, and Administrative Permanent Bar may be issued by DEC Counsel to Respondents who are in "default" as defined by Article 4.1 of the Procedural Rules.</div>
                            </div>
                            <div class="tooltip-triangle"></div>
                          </div>
                        </div>
                      </div>`;
  }


  /**
   * Triggered by on submit click on hero landing page to build url and send selecte facets to search results page
   *
   * @method _heroFacetSearch
   * @param {Object} e (event)
   */

  _heroFacetSearch(e) {
    e.preventDefault();
    const paramObject = {};
    $('.hero-find-ce-form input:checked').map((i, item) => {
      const name = $(item).attr('name'),
            val  = $(item).val();
      if (!(name in paramObject)) {
        paramObject[name] = [];
      }
      paramObject[name].push(val);
    });

    if (window.location.pathname !== this._searchURL) {
      const { defaultQueryParams, query, queryParams } = this,
            defaults = Object.keys(defaultQueryParams),
            options = defaults.concat(queryParams),
            compact = this.compact(options),
            uniqe   = this.uniq(compact)
      ;

      this.queryParams = uniqe;
      this.query       = this._initQuery();

      Object.keys(paramObject).map((item) => {
        this.query[item] = paramObject[item].join(',');
      });

      return window.location = this._buildURL();
    }
  }
}
