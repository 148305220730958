export default (dataMap = {}, condition = null) => {
  window.dataLayer = window.dataLayer || [];

  if (condition) {
    if (condition()) {
      return window.dataLayer.push(dataMap);
    }

    return null;
  }

  return window.dataLayer.push(dataMap);
};