/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchListing from '@components/search-listing';

export default class ComplianceResourceListing extends SearchListing {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.CR_ENDPOINT;
    config.searchURL          = window.location.pathname;
    config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    // Facets
    config.facetOptions = [{
      name: 'topic',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'type',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.compliance-resource-listing';
    super(config, prefix);

    this.cssPrefix = prefix;
    this.searchInput = prefix + ' .js-search-field';
    this.searchSubmit = prefix + ' .js-search-submit';
    this.clearAll = prefix + ' .js-filter-clear-all';

    $('html')
      // keyword search
      .on('click', '.js-keyword-clear', (e) => {
        e.preventDefault();
        this._helperUpdateKeywords('');
      })
      .on('click', this.searchSubmit, this._keywordSearch.bind(this))
      .on('keyup', '.compliance-resource-listing .js-search-field', (e) => {
        if (e.keyCode === 13) {
          $('.compliance-resource-listing .js-search-submit').click();
        }
      })
      // open mobile filters
      .on('click', '.js-search-listing-filter-open', (e) => {
        e.preventDefault();
        $('html').attr('data-facets-open', 'true');
      })
      .on('click', '.js-filters-close', (e) => {
        e.preventDefault();
        $('html').attr('data-facets-open', 'false');
      })
      .on('click', this.clearAll, this._clearFilters.bind(this))
    ;

    $(window).on('resize', () => {
      if ($(window).innerWidth() > 1199) {
        $('html').attr('data-facets-open', 'false');
      }
    });
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    results.map((item) => {
      let publication = '';

      if (item.publication) {
        publication = `<span class="p -small -italics">${item.publication}</span>`;
      }

      const template = `
        <a class="compliance-resource-listing-item" href="${item.url}">
          <div class="compliance-resource-listing-item-info">
            <div class="compliance-resource-listing-item-eyebrow eyebrow">${item.type}</div>
            <h5 class="h5 compliance-resource-listing-item-title">${item.title}</h5>
            <div class="compliance-resource-listing-item-meta">
              <span class="p -small">${item.month} ${item.day}, ${item.year}</span>
              ${publication}
            </div>
          </div>
        </a>
      `;

      $container.append(template);

    });
  }
}
