export default () => {
  if ($('.alert-banner').length) {

    const $alertBanner = $('.alert-banner'),
          $alertBannerMain = $('.alert-banner-main'),
          $alertBannerClose = $('.js-alert-banner-close'),
          // Expected format: "promo-banner-{F27FE11D-3195-4738-890E-8E6E8BE83C80}-v1"
          bannerID = $alertBanner.attr('id');

    const localStorageItem = localStorage.getItem(bannerID);
    if (!localStorageItem) {
      $alertBannerMain.show();
      $alertBannerMain.attr('aria-hidden', 'false');
      $alertBanner.attr('data-active', 'true');
    } else {
      $alertBannerMain.attr('aria-hidden', 'true');
      $alertBanner.attr('data-active', 'false');

      const storageDataObj = JSON.parse(localStorageItem);

      const dismissalTimestamp = new Date(parseInt(storageDataObj.timestampDismissed));
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
      const thirtyDaysAgo = new Date(Date.now() - thirtyDaysInMilliseconds);

      const didDismissMoreThanThirtyDaysAgo = dismissalTimestamp < thirtyDaysAgo;
      if (didDismissMoreThanThirtyDaysAgo) {
        // Show the full banner again & clear cookie
        $alertBannerMain.slideDown(0, () => {
          $alertBannerMain.attr('aria-hidden', 'false');
          $alertBanner.attr('data-active', 'true');
        });

        localStorage.removeItem(bannerID);
      }
    }

    $alertBannerClose.on('click', () => {
      $alertBannerMain.slideUp(500, () => {
        $alertBannerMain.attr('aria-hidden', 'true');
        $alertBanner.attr('data-active', 'false');
      });

      const bannerInteractionInfo = {
        timestampDismissed: String(Date.now())
      };

      localStorage.setItem(bannerID, JSON.stringify(bannerInteractionInfo));
    });
  }
};