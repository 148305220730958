
export default () => {
  // function contents go here

  if ($('.pie-chart').length) {
    // pie chart help
    // https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936

    let cumulativePercent = 0;
    // if you change these colors please also update the mapping in _pie-chart.scss
    const colors = ['#FFCC33', '#59C9ED', '#4E4E4E', '#979797', '#d8d8d8'],
          $svg = $('.pie-chart-svg')
        ;


    $('.pie-chart-key').map((i, product) => {
      const slicePercent = $(product).data('value') / 100;

      const [startX, startY] = getCoordinatesForPercent(cumulativePercent);
      cumulativePercent += slicePercent;

      const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = slicePercent > .5 ? 1 : 0;

      // create path data for slice
      const pathData = `M ${startX} ${startY} A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY} L 0 0`;

      // create a <path> and append it to the <svg> element
      const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      pathEl.setAttribute('d', pathData);
      pathEl.setAttribute('fill', colors[i]);
      pathEl.setAttribute('title', $(product).text());
      $svg.append(pathEl);
    });
  }

  function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }

};
