export default () => {
  // desktop display
  if ($('.categories-accordion').length) {

    $('.categories-accordion').map((i, item) => {

      // display the first accordion on pageload & style the left category
      $(item).find('.categories-accordion-right-accordion:first').addClass('-show-accordion');
      $(item).find('.categories-accordion-left-category:first').addClass('-selected');

      // click events on category
      $(item).find('.categories-accordion-left-category').on('click', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget),
              value = $this.attr('data-category'),
              mobileAccordion = $(item).find(`.categories-accordion-mobile-accordion[data-accordion='${value}']`),
              mobileCategory = $(item).find(`.categories-accordion-mobile-category[data-category='${value}']`);

        // update both desktop and mobile category styling
        updateSelected(item, $this, mobileCategory);

        // display the corresponding accordion for both mobile and desktop
        $(item).find(`.categories-accordion-right-accordion[data-accordion='${value}']`).addClass('-show-accordion');
        $(item).find('.categories-accordion-mobile-accordion').not(mobileAccordion).slideUp();
        mobileAccordion.slideToggle();
      });

      // mobile display

      // show first category & accordion with styles
      $(item).find('.categories-accordion-mobile-accordion:first').slideToggle();
      $(item).find('.categories-accordion-mobile-category:first').addClass('-selected');

      $(item).find('.categories-accordion-mobile-category').on('click', (e) => {
        e.preventDefault();

        const $this = $(e.currentTarget),
              value = $this.attr('data-category'),
              desktopAccordion = $(item).find(`.categories-accordion-right-accordion[data-accordion='${value}']`),
              desktopCategory = $(item).find(`.categories-accordion-left-category[data-category='${value}']`);

        // update both desktop and mobile category styling
        updateSelected(item, $this, desktopCategory, true);

        // display the corresponding accordion for both mobile and desktop
        $(item).find(`.categories-accordion-mobile-accordion[data-accordion='${value}']`).slideToggle();
        desktopAccordion.addClass('-show-accordion');
      });
    });
  }

  // removes styles from all categories, and adds specific style to the selected category
  const updateSelected = (currentAccordion, currentCategory, otherCategory, mobile) => {
    $(currentAccordion).find('.categories-accordion-right-accordion').removeClass('-show-accordion');
    $(currentAccordion).find('.categories-accordion-left-category').removeClass('-selected');


    if (mobile) {
      if (currentCategory.hasClass('-selected')) {
        currentCategory.removeClass('-selected');
      } else {
        currentCategory.addClass('-selected');
      }
    } else {
      $(currentAccordion).find('.categories-accordion-mobile-category').removeClass('-selected');
      currentCategory.addClass('-selected');
    }
    otherCategory.addClass('-selected');
  };
};