/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';

export default () => {
  if ($('.logo-slider-slide').length) {

    $('.logo-slider-slides').slick({
      autoplay: false,
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      fade: false,
      pauseOnHover: false,
      draggable: true,
      cssEase: 'ease',
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
};
