/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchListing from '@components/search-listing';

export default class NewsListing extends SearchListing {
  /**
   * Initialize the Search.
   */
  constructor(config = false, prefix = '.news-listing') {

    // All available default query parameters.

    if (!config) {
      config = new Config();
      config.endpoint           = process.env.NEWS_ENDPOINT;
      config.searchURL          = window.location.pathname;
      config.defaultQueryParams = { limit: 20, pg: 1 };

      // Facets
      config.facetOptions = [{
        name: 'type',
        isMultiCardinality: false,
        default: null
      }];
    }

    super(config, prefix);

    this.cssPrefix = prefix;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {

    const $container = $(this._searchContainerItems);

    results.map((item) => {
      let publication = '';

      if (item.publication) {
        publication = `<span class="p -small -italics">${item.publication}</span>`;
      }

      const template = `
        <a class="news-listing-item" href="${item.url}">
          <div class="news-listing-item-info">
            <div class="news-listing-item-eyebrow eyebrow">${item.type}</div>
            <h2 class="h5 news-listing-item-title">${item.title}</h2>
            <div class="news-listing-item-meta">
              <span class="p -small">${item.month} ${item.day}, ${item.year}</span>
              ${publication}
            </div>
          </div>
        </a>
      `;

      $container.append(template);

    });
  }
}
