/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchListing from '@components/search-listing';

export default class ForEmployersListing extends SearchListing {
  /**
   * Initialize the Search.
   */
  constructor(config = null, prefix = '.for-employers-listing') {

    // All available default query parameters.

    if (!config) {
      config = new Config();
      config.endpoint           = process.env.FOR_EMPLOYERS_ENDPOINT;
      config.searchURL          = window.location.pathname;
      config.defaultQueryParams = { limit: 5, pg: 1 };


      // Facets (if faceting is needed at some point, uncomment this and add to it)
      // config.facetOptions = [{
      //   isMultiCardinality: false,
      //   default: null
      // }];
    }

    super(config, prefix);

    this.cssPrefix = prefix;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {

    const $container = $(this._searchContainerItems);

    results.map((item) => {
      let teaser = '',
          image = '';

      if (item.teaser) {
        teaser = `<p class="-small">${item.teaser}</p>`;
      }

      if (item.imageUrl) {
        image = `<div class="for-employers-listing-item-image">
          <img src="${item.imageUrl}" alt="${item.imageAlt || ''}"/>
        </div>`;
      }

      const template = `
        <a class="for-employers-listing-item" href="${item.url}">
          <div class="for-employers-listing-item-info">
            ${image}
            <div>
              <div class="for-employers-listing-item-eyebrow eyebrow">Article</div>
              <h2 class="h5 for-employers-listing-item-title">${item.title}</h2>
              <div class="for-employers-listing-item-meta">
                <span class="p -small">${item.month} ${item.day}, ${item.year}</span>
              </div>
              ${teaser}
            </div>
          </div>
        </a>
      `;

      $container.append(template);

    });
  }
}
