/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import {Config, Facets, Pagination} from 'is-search';

export default class SearchBase extends Facets {
  /**
   * Initialize the Search.
   */
  constructor(config) {

    // All available default query parameters.
    config.keywordParam       = 'q';
    config.pagerParam         = 'pg';
    config.sortParam          = 's';
    config.scrollToTop        = true;

    super(config);

    this.lastFacetId = false;

    // Event callback for Fetch requests which builds the results and pager.
    $(this)
      // Loading
      .on('isLoading', this._mixinIsLoading.bind(this))
      // Main search response
      .on('HandleResponse', this._searchResponse.bind(this))
      // Build facets
      .on('HandleResponse', this._buildFacets.bind(this))
      // Build pagination
      .on('HandleResponse', this._buildPagination.bind(this))
    ;
  }

  get searchContainer() {
    return this._searchContainer || null;
  }

  set searchContainer(container) {
    this._searchContainer = container;
  }

  get limit() {
    return Number(this.query.limit);
  }

  /* ========================================================================
   *  Pagination
   * ======================================================================*/

  /**
   * Callback for build the pagintation based off the returned results.
   *
   * @private
   * @method _buildPagination
   */
  _buildPagination() {
    $(`${this.cssPrefix} .pagination-pages`).empty();

    // Hide pagination arrows by default.
    $(`${this.cssPrefix} .pagination-nav a`).hide();
    $(`${this.cssPrefix} .pagination-mobile-current`).html(this.currentPage);
    $(`${this.cssPrefix} .pagination-mobile-total`).html(this.totalPages);

    // Do not load the pager if we get 1 or less pages to display.
    if (this.totalPages <= 1) {
      return;
    }

    $(`${this.cssPrefix} .pagination-nav a`).show().attr('data-disabled', 'false');

    if (this.currentPage === 1) {
      $(`${this.cssPrefix} .pagination-prev`).attr('data-disabled', 'true');
    }

    if (this.currentPage === this.totalPages) {
      $(`${this.cssPrefix} .pagination-next`).attr('data-disabled', 'true');
    }

    this.buildPager().map(this._buildPaginationItem.bind(this));
  }

  /**
   * Callback for building an individual pager item.
   *
   * @private
   * @method _buildPaginationItem
   * @param { Object } page The page object context for the current search results.
   */
  _buildPaginationItem(page) {

    const {current, link, text, value} = page,
          currentHTML = `<a href="#" data-value="${value}" data-current="true"><span class="sr-text">Page </span>${text}</a>`;

    let defaultHTML = `<a href="${link}" data-value="${value}"><span class="sr-text">Page </span>${text}</a>`;

    if (text === '...') {
      defaultHTML = `<span class="pagination-pages-dots">${text}</span>`;
    }

    $(`${this.cssPrefix} .pagination-pages`).append(current ? currentHTML : defaultHTML);
  }

  /**
   * Callback being used to as the click event for the pagintation.
   *
   * @private
   * @method _paginationTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _paginationTrigger(event) {
    event.preventDefault();
    const page = $(event.target).data('value');
    this._helperUpdatePage(page);
  }

  /**
   * Scroll to the top of search on pagination click.
   *
   * @private
   * @method _paginationScrollTop
   * @param { Object } event The jQuery event passed from on().
   */
  _paginationScrollTop(event) {
    event.preventDefault();
    $(event.currentTarget).trigger('blur');

    $('html, body').animate({
      scrollTop: $(this.searchContainer).offset().top - 30
    }, 300);
  }

  /* ========================================================================
   *  Facets
   * ======================================================================*/

  /**
   * Builds facet HTML on load.
   *
   * @private
   * @method _buildFacets
   */
  _buildFacets() {

    if (this.facetContainer) {
      const wrapper = $(this.facetContainer);
      // Empty all facets
      wrapper.empty();

      // Map through facets and build them
      this.facets.map(this._buildFacetItem.bind(this, wrapper));
    }

    if (this._buildActiveFilters) {
      this._buildActiveFilters();
    }

    if (this.lastFacetId) {
      $('#' + this.lastFacetId).focus();
    }
  }


  /**
   * Triggers the updating of the search on facet click/change.
   *
   * @private
   * @method _facetTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _facetTrigger(event) {
    event.preventDefault();

    const element = $(event.target),
          {name, value, id} = element[0];
    this.lastFacetId = id;

    const newValue = value.toLowerCase() === 'all' ? '' : value;
    this._helperUpdateFacet(name, newValue);

    $('html, body').animate({
      scrollTop: $(this.searchContainer).offset().top - 30
    }, 300);
  }


  /* ========================================================================
   *  Loading
   * ======================================================================*/

  /**
   * Callback that allows for a progress loaded to be initialized and deinitialized.
   *
   * @private
   * @method _mixinIsLoading
   * @param { Object } event The jQuery event passed from on().
   * @param { Boolean } state true|false
   */
  _mixinIsLoading(event, state) {

    switch (state) {
    case true:
      // Show progress html.
      $(this.searchLoading).show();
      $(this._searchContainerItems).hide();
      $(this.paginationContainer).hide();
      break;

    default:
      // Hide progress html.
      $(this.searchLoading).hide();
      $(this._searchContainerItems).show();
      break;
    }
  }

  /* ========================================================================
   *  Clearing
   * ======================================================================*/
  /**
   * Clear all facets
   *
   * @private
   * @method _clearFilters
   * @param { Object } event The jQuery event passed from on().
   */
  _clearFilters(event) {
    event.preventDefault();
    event.stopPropagation();

    // Set to page 1
    this.query._page = 1;
    this.query.q = '';

    // Re-init facets
    this._initFacets();

    // Update push state for back/forward buttons
    this._updatePushState();
    this.showActiveFilters = false;
    // Hide active filters container

    $(this.activeFilters).hide();
  }


  /* ========================================================================
   *  Keyword search
   * ======================================================================*/


  /**
   * Callback for processing the keywords entered into the search.
   *
   * @private
   * @method _keywordSearch
   * @param { Object } event The jQuery event passed from on().
   */
  _keywordSearch(event) {
    event.preventDefault();

    // Redirect the user to the search page if they are using the header search.
    if (window.location.pathname !== this._searchURL) {
      const { defaultQueryParams, query, queryParams } = this,
            defaults = Object.keys(defaultQueryParams),
            options = defaults.concat(queryParams),
            compact = this.compact(options),
            uniqe   = this.uniq(compact)
      ;

      this.queryParams = uniqe;
      this.query       = this._initQuery();
      this.query.q = encodeURIComponent($('.js-search-field-hero').val());
      return window.location = this._buildURL();
    }

    const keywords = encodeURIComponent($(this.searchInput).val());

    this._helperUpdateKeywords(keywords);
  }

  /* ========================================================================
   *  Search Response
   * ======================================================================*/

  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {
    // empty active filters and reset boolean
    $(this.activeFiltersLinks).empty();
    this.showActiveFilters = false;


    const { results } = response,
          { keywords, firstResult, lastResult, totalResults } = this,
          $container = $(this._searchContainerItems),
          $resultsNum = $(this.searchResultsText),
          $noKeywords = $(this.noKeywords);

    const keywordsClean = decodeURIComponent(keywords);

    // Empty out the current search results and stats.
    $container.empty();
    $resultsNum.empty();

    $resultsNum.html(this._commafy(totalResults));

    if (keywords) {
      $noKeywords.hide();

      $(this.searchInput).val(keywordsClean);
      $(this.activeFiltersLinks).prepend($(`
        <a href="#" class="-filter-button js-filter-clear" data-type="keyword" data-id="js-search-field"></a>
      `).text(keywordsClean));
      this.showActiveFilters = true;

    } else {
      $(this.searchInput).val('');
    }

    // search listing results found meta
    if ($('.search-results').length && results.length) {
      $('.page-range').text(`${firstResult}-${lastResult}`);
      $('.total-results').text(totalResults);
      $('.search-term').text(`"${keywordsClean}"`);
      $('.search-results-found').show();
      $('.search-results-found.-none').hide();
    }

    // Stop if there are no results to show.
    if (!results.length) {
      $(this.noResults).show();
      $(this.sortContainer).hide();
      $(this.paginationContainer).hide();

      if ($('.search-results').length) {
        $('.search-term').text(`"${keywordsClean}"`);
        $('.search-results-found').hide();
        $('.search-results-found.-none').show();
      }

      return;
    }


    // Hide no results message
    $(this.noResults).hide();
    $(this.sortContainer).show();

    this._templateResults(results);

    // Show pagination
    $(this.paginationContainer).css('display', 'flex');

  }

  /**
   * Adds commas to numbers with 4+ digits
   *
   * @method _commafy
   * @param {Number} num
   */
  _commafy(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

