// Smooth scroll internal links
export default () => {

  $('html').on('click', 'body:not(.kss-body) a[href*="#"]:not([href="#"])', (e) => {
    const target = e.currentTarget.hash,
          $target = $(target);

    if (target === '#main-content' || $('.hero-dashboard[data-status="candidate"').length) {
      return;
    }

    if ($target.length) {
      e.preventDefault();
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 100
      }, 500, () => {
        if (window.history.pushState) {
          window.history.pushState(null, null, target);
        } else {
          window.location.hash = target;
        }
        $target.find(':focusable').focus();
      });

      return;
    }
    return;
  });

};
