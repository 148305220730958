import gtmHelper from '@plugins/gtm-helper';

export default () => {

  const dataMap = {
    event: 'CE Detail Page Load',
    pageType: 'ce-detail',
    programName: $('.ce-detail-heading h1').html(),
    preferred: $('.ce-detail').attr('data-preferred'),
    programSponsor: $('.ce-detail').attr('data-program-sponsor'),
    topic: $('.ce-detail .-topic p').html(),
    programId: $('.ce-detail .-program-id p').html(),
    hours: $('.ce-detail .-hours p').html(),
    format: $('.ce-detail .-format p').html(),
    complexity: $('.ce-detail .-complexity p').html()
  };

  const runCondition = () => {
    return $('.ce-detail').length;
  };

  gtmHelper(dataMap, runCondition);
};