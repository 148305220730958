/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import SearchBase from '@components/search';

export default class SearchListing extends SearchBase {
  /**
   * Initialize the Search.
   */
  constructor(config, prefix = '') {
    // config.defaultQueryParams = { limit: 10, pg: 1, q: '' };

    super(config);
    this._pageClassModifiers(prefix);

    // UI based events
    $('html')
      // Facet changes
      .on('change', this.facetInputs, this._facetTrigger.bind(this))
      // clearing active filters/facets
      .on('click', `${this.activeFilters} .js-filter-clear`, this._clearActiveFilter.bind(this))
    ;

    // Pagination events
    this.paginationEvents();
  }

  _pageClassModifiers(prefix) {

    // container to add items to not sure if this is needed or should be different?
    this._searchContainer = prefix + ' .search-listing-results';
    // container to add items to
    this._searchContainerItems = prefix + ' .search-listing-results-inner';
    // loader container
    this.searchLoading = prefix + ' .search-listing-container .loading';
    // no results message
    this.noResults = prefix + ' .search-lising-results-none';
    this.paginationContainer = prefix + ' .search-listing-container .pagination';
    // facet container
    this.facetContainer = prefix + ' .search-listing-facets-inner';
    // facet inputs
    this.facetInputs = `${prefix} .search-listing-facets input[type="radio"], ${prefix} .search-listing-facets select`;

    // active filters
    this.activeFilters = prefix + ' .search-listing-filtered-by';
    this.activeFiltersLinks = prefix + ' .search-listing-filtered-by-container';
    this.showActiveFilters = false;
    this.activeFiltersAvailable = $(this.activeFilters).length;
  }


  paginationEvents() {
    // UI based click events for pagination.

    $('html')
      .on('click', this.paginationContainer + ' a', this._paginationScrollTop.bind(this))
      .on('click', this.paginationContainer + ' .pagination-prev', this.previousPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-next', this.nextPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-pages a', this._paginationTrigger.bind(this))
    ;
  }

  /**
   * Builds an individual facet HTML element on load.
   *
   * @private
   * @method _buildFacetItem
   * @param { String } wrapper jQuery element.
   * @param { Object } facet The facet context for one option.
   */
  _buildFacetItem(wrapper, { name, options, title, type }) {
    let data = [],
        mobileSelect = [],
        selectLabel = '';

    /* eslint-disable indent */
    switch (type) {

      // Build radio facets
      case 'radios':
        data = data.concat(options.map(this._buildTypeRadio.bind(this, name)));
        selectLabel = `<select id="${name}" name="${name}" title="${title}">`;
        mobileSelect = mobileSelect.concat(selectLabel, options.map(this._searchBuildTypeSelectOption.bind(this, name)), '</select>');
        break;

    }
    /* eslint-enable indent */

    // Join all of the individual facets created and store for injection into the wrapper
    const ouput = data.join('');

    // Add title to the facet section if it's not an empty string
    let titleMarkup = '';
    if (title !== '') {
      titleMarkup =  `<div class="h6 search-listing-facet-title">${title}</div>`;
    }

    // Add the facet section to the wrapper
    wrapper.append(`
      <div class="search-listing-facet -${name}">
        ${titleMarkup}
        <div class="search-listing-mobile-select">
          ${mobileSelect.join('')}
        </div>
        <div class="search-listing-facet-radios">
          ${ouput}
        </div>
      </div>
    `);
  }


  /**
   * Formats and constructs an individual radio options.
   *
   * @private
   * @method _buildTypeRadio
   * @param { String } name The name of the facet.
   * @param { Object } item The facet option context.
   * @return { HTML } The formated HTML Radio.
   */
  _buildTypeRadio(name, { label, value }) {
    value = value.length === 0 ? 'all' : value;
    const labelHTML = `<label for="${name}-${value}">${label}</label>`,
          isChecked = this._isFacetActive(name, value) || value === 'all' ? ' checked' : '',
          contents  = `<input id="${name}-${value}" type="radio" name="${name}" value="${value}"${isChecked}>${labelHTML}`;

    if (this.activeFiltersAvailable && isChecked && label !== 'All') {
      $(this.activeFiltersLinks).append(`
        <a href="#" class="-filter-button js-filter-clear" data-type="radio" data-name="${name}">${label}</a>
      `);
      this.showActiveFilters = true;
    }

    return `<div class="radio">${contents}</div>`;
  }

  /**
   * Formats and constructs an individual checkbox element.
   *
   * @private
   * @method _searchBuildTypeSelect
   * @param { String } name The name of the facet.
   * @param { Object } item The facet option context.
   * @return { HTML } The formated HTML checkbox.
   */
  _searchBuildTypeSelectOption(name, { label, value }) {
    const selected = this._isFacetActive(name, value) ? ' selected' : '',
          contents = `<option name="${label}" value="${value}"${selected}>${label}</option>`;
    return contents;
  }

  /* ========================================================================
   *  Clearing
   * ======================================================================*/
  /**

  /**
   * Clears an individual axctive filter on click
   *
   * @private
   * @method _clearActiveFilter
   */
  _clearActiveFilter(event) {
    event.preventDefault();
    event.stopPropagation();
    const $target = $(event.currentTarget);
    const facetType = $target.data('type');
    const id = $target.data('id');
    const name = $target.attr('data-name');

    switch (facetType) {
    case 'radio':
      $('#' + name + '-all').click();
      break;
    case 'keyword':
      $('.' + id).val('');
      $(this.searchSubmit).click();
      break;
    default:
      break;
    }
  }

  _buildActiveFilters() {
    // show the active filters if this variable is still true
    $(this.activeFiltersLinks).append('<a href="#" class="-filter-button -all js-filter-clear-all" data-type="all" data-id="all">Clear All</a>');
    this.showActiveFilters ? $(this.activeFilters).show().css('opacity', 1) : $(this.activeFilters).hide();
  }

  /* ========================================================================
   *  Search Response
   * ======================================================================*/

  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {
    // empty active filters and reset boolean
    $(this.activeFiltersLinks).empty();
    this.showActiveFilters = false;

    const { results } = response,
          $container = $(this._searchContainerItems),
          { keywords } = this
          ;

    if (keywords) {
      const keywordsClean = decodeURIComponent(keywords);
      $(this.searchInput).val(keywordsClean);
      $(this.activeFiltersLinks).prepend($(`
        <a href="#" class="-filter-button js-filter-clear" data-type="keyword" data-id="js-search-field"></a>
      `).text(keywordsClean));
      this.showActiveFilters = true;
    } else {
      $(this.searchInput).val('');
    }

    // Empty out the current search results and stats.
    $container.empty();

    // Stop if there are no results to show.
    if (!results.length) {
      $(this.noResults).show();
      $(this.paginationContainer).hide();
      return;
    }

    // Hide no results message
    $(this.noResults).hide();

    this._templateResults(results);

    // Show pagination
    $(this.paginationContainer).css('display', 'flex');

  }

}
