/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchBase from '@components/search';
import isSticky from '@plugins/isSticky';

export default class VerifyCfpResults extends SearchBase {

  // Sticky table header.
  stickyTableHeader() {
    isSticky();

    if ($(this.cssPrefix + '-table').length) {
      const cssPrefixPart = this.cssPrefix.substr(1);

      $('.js-' + cssPrefixPart + '-table-head')
        .isSticky({
          zIndex: 8,
          stopper: $('#' + cssPrefixPart + '-container tr:last-of-type'),
          stuckClass: '-stuck',
          offset: 0,
          table: true
        })
        .init();
    }
  }


  // Loading and result population.
  constructor() {

    // All available default query parameters.
    const config = new Config();
    config.endpoint           = process.env.VERIFY_CFP_ENDPOINT;
    config.searchURL          = process.env.VERIFY_CFP_URL;
    config.defaultQueryParams = { limit: 20, pg: 1, lastName: 'thomas' };
    config.pagerParam         = 'pg';
    config.sortParam          = 's';

    config.facetOptions = [{
      name: 'lastName',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'firstName',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'org',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'city',
      isMultiCardinality: false,
      default: null
    }, {
      name: 'state',
      isMultiCardinality: false,
      default: null
    }];


    super(config);

    const prefix = '.verify-cfp-results';
    this._pageClassModifiers(prefix);

    $(this)
      .on('HandleResponse', this._buildSorts.bind(this))
    ;

    $('html')
      .on('change', this.sortSelect, this._sortingTrigger.bind(this))
      .on('click', '.js-toggle-sorting', (e) => {
        e.preventDefault();
        $('html').attr('data-sorting-open') === 'true' ? $('html').attr('data-sorting-open', 'false') : $('html').attr('data-sorting-open', 'true');
        $('.verify-cfp-results-mobile-sort').slideToggle();
      })
      // mobile sorting trigger
      .on('click', '.verify-cfp-results-mobile-sort-option', (e) => {
        e.preventDefault();
        const value = $(e.currentTarget).attr('data-value');
        $(this.sortSelect).val(value);
        $(this.sortSelect).trigger('change');
        $('.verify-cfp-results-mobile-sort').hide();
        $('html').attr('data-sorting-open', 'false');
      })
      // hero page submission events
      .on('submit', '.hero-verify-cfp-form', this._heroFacetSearch.bind(this))
      .on('keyup', '.hero-verify-cfp-form input', (e) => {
        if (e.keyCode === 13) {
          $('.js-hero-submit').click();
        }
      })
    ;

    this.stickyTableHeader();
    this.paginationEvents();
  }

  _pageClassModifiers(prefix) {
    this.cssPrefix = prefix;
    this._searchContainer = '#' + prefix.substr(1) + '-container';
    this.searchResultsText = prefix + '-heading-left-count';
    this.searchLoading = prefix + ' .loading';
    this.noResults = prefix + '-none';
    this.paginationContainer = prefix + ' .pagination';
    this.sortSelect = prefix + '-heading-sort select';
    this.heroInputs = '.hero-verify-cfp-form input[type="text"], .hero-verify-cfp-form select';
  }

  paginationEvents() {
    // UI based click events for pagination.

    $('html')
      .on('click', this.paginationContainer + ' a', this._paginationScrollTop.bind(this))
      .on('click', this.paginationContainer + ' .pagination-prev', this.previousPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-next', this.nextPage.bind(this))
      .on('click', this.paginationContainer + ' .pagination-pages a', this._paginationTrigger.bind(this))
    ;
  }

  /* ========================================================================
   *  Search Response
   * ======================================================================*/

  /**
   * Callback for the primary Fetch responses.
   *
   * @private
   * @method _searchResponse
   * @param { Object } event The jQuery event passed from on().
   * @param { JSON } response The raw response object.
   */
  _searchResponse(event, response) {
    $('.verify-cfp-results-table').hide();
    const { results } = response,
          { totalResults } = this,
          $container = $(this._searchContainer),
          $resultsNum = $(this.searchResultsText);

    $container
      .empty();

    $resultsNum
      .empty()
      .html(totalResults + ' Result' + (totalResults === 1 ? '' : 's'));

    if (results.length === 0) {
      $(this.noResults).show();

      $(this.paginationContainer).hide();
      return;
    }

    $(this.noResults).hide();
    $('.verify-cfp-results-table').show().css('opacity', 1);
    const currentQuery = window.location.search;

    results.map((item) => {

      const company = (item.cst_org_name_dn ? item.cst_org_name_dn : '');

      const template = `
        <tr>
          <td><a href="${item.item_url}${currentQuery}" class="-black">${item.cst_ind_full_name_dn}</a> ${company}</td>
          <td>${item.ind_certstatus_ext}</td>
          <td>${item.hasDiscipline ? 'Yes' : 'No'}</td>
          <td>${item.hasBankruptcy ? 'Yes' : 'No'}</td>
        </tr>
      `;

      $container.append(template);
    });

    $(this.paginationContainer).css('display', 'flex');
  }


  /* ========================================================================
   *  Sorting
   * ======================================================================*/

  /**
   * Builds the sorting HTML on load.
   *
   * @private
   * @method _buildSorts
   */
  _buildSorts() {
    const wrapper = $(this.sortSelect);
    wrapper.empty();
    $('.faceted-search-results-mobile-sort-inner').empty();
    // endpoint isn't sending facets so need to define something
    this.facets = [];
    this.sorts.map(this._buildSortItem.bind(this, wrapper));
  }

  /**
   * Builds the sorting select list.
   *
   * @private
   * @method _buildSortItem
   * @param { String } wrapper jQuery element.
   * @param { Object } sort The individual sort item.
   */
  _buildSortItem(wrapper, { label, value }) {

    const selected   = this._isFacetActive('s', value) ? ' selected' : '';
    wrapper.append(`<option value="${value}"${selected}>${label}</option>`);
    const mobileWrapper = $('.faceted-search-results-mobile-sort-inner');
    mobileWrapper.append(`<a href="#" class="h6 faceted-search-results-mobile-sort-option" data-value="${value}">${label}</a>`);
  }

  /**
   * Trigger the search to update on sorting change.
   *
   * @private
   * @method _sortingTrigger
   * @param { Object } event The jQuery event passed from on().
   */
  _sortingTrigger(event) {
    event.preventDefault();

    const element = $(event.target),
          sort    = element[0].value,
          order   = element[0].selectedOptions[0].getAttribute('data-order');

    this._helperUpdateSorting(sort, order);
  }

  _paginationScrollTop(event) {
    event.preventDefault();
    $(event.currentTarget).trigger('blur');

    $('html, body').animate({
      scrollTop: $(this.searchContainer).offset().top - 100
    }, 300);
  }

  /**
   * Triggered by on submit click on hero landing page to build url and send selecte facets to search results page
   *
   * @method _heroFacetSearch
   * @param {Object} e (event)
   */

  _heroFacetSearch(e) {
    e.preventDefault();

    const paramObject = {};

    $(this.heroInputs).map((i, item) => {
      const name = $(item).attr('name'),
            val  = $(item).val();

      if (!(name in paramObject)) {
        paramObject[name] = val;
      } else {
        paramObject[name] = paramObject[name] + ',' + val;
      }
    });

    if (window.location.pathname !== this._searchURL) {
      const { defaultQueryParams, query, queryParams } = this,
            defaults = Object.keys(defaultQueryParams),
            options = defaults.concat(queryParams, Object.keys(paramObject)),
            compact = this.compact(options),
            uniqe   = this.uniq(compact)
      ;

      this.queryParams = uniqe;
      this.query       = this._initQuery();

      Object.keys(paramObject).map((item) => {
        this.query[item] = paramObject[item];
      });

      return window.location = this._buildURL();
    }
  }

}
