export default () => {
  if ($('.expander-toggle').length) {
    $('.expander-toggle').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget),
            $parent = $this.parent(),
            $toggle = $parent.find('.expander-toggle')
          ;
      $toggle.toggleClass('-opened');
      $parent.next().slideToggle();
    });
  }
};
