/* global process */
/* eslint-disable no-multi-spaces, object-curly-spacing, object-property-newline, no-unused-vars, consistent-return */
import { Config } from 'is-search';
import SearchListing from '@components/search-listing';

export default class NewsListing extends SearchListing {
  /**
   * Initialize the Search.
   */
  constructor() {

    // All available default query parameters.
    const config = new Config();

    config.endpoint           = process.env.EVENTS_ENDPOINT;
    config.searchURL          = window.location.pathname;
    config.defaultQueryParams = { limit: 10, pg: 1 };

    // Facets
    config.facetOptions = [{
      name: 'type',
      isMultiCardinality: false,
      default: null
    }];

    const prefix = '.event-listing';
    super(config, prefix);


    this.cssPrefix = prefix;
  }


  /**
   * Templates each result
   *
   * @method _templateResults
   * @param {array} results // results array
   */
  _templateResults(results) {
    const $container = $(this._searchContainerItems);

    results.map((item) => {
      const textDate = `<span class="p -small">${item.month} ${parseInt(item.day)}, ${parseInt(item.year)}</span>`;
      let multiday = '',
          time = '',
          location = ''
        ;

      if (item.multiday) {
        multiday = '<span class="event-listing-item-bigdate-starts">Starts</span>';
      }

      if (item.time) {
        time = `<span class="p -small">${item.time}</span>`;
      }

      if (item.location.length) {
        location = `<span class="p -small">${item.location}</span>`;
      }

      const template = `
        <a class="event-listing-item" href="${item.url}">
          <div class="event-listing-item-bigdate">
            ${multiday}
            <span class="event-listing-item-bigdate-month">${item.month.substring(0, 3)}</span>
            <span class="event-listing-item-bigdate-day">${parseInt(item.day)}</span>
          </div>

          <div class="event-listing-item-info">
            <div class="event-listing-item-eyebrow eyebrow">${item.type}</div>
            <h5 class="h5 event-listing-item-title">${item.title}</h5>
            <div class="event-listing-item-meta">
              ${textDate}
              ${time}
              ${location}
            </div>
          </div>
        </a>
      `;

      $container.append(template);

    });
  }
}
