import tippy from 'tippy.js';

export default () => {

  $('html')
    .on('click', '.tooltip', (e) => {
      e.stopPropagation();
    })
    .on('click', '.tooltip-toggle', (e) => {
      e.preventDefault();
      const $target = $(e.currentTarget),
            $parent = $target.parent();

      if ($('.tooltip').not($parent).hasClass('-tooltip-open')) {
        $('.tooltip').removeClass('-tooltip-open');
      }
      positionTooltip($target);

      if ($parent.hasClass('-tooltip-open')) {
        $parent.removeClass('-tooltip-open').removeClass('-flipped');
        $('html').attr('data-tooltip-open', 'false');
      } else {
        $parent.addClass('-tooltip-open');
        $('html').attr('data-tooltip-open', 'true');
      }
    })
    .on('click', '.tooltip-close', (e) => {
      e.preventDefault();
      $(e.currentTarget)
        .parent()
        .parent()
        .parent()
        .removeClass('-tooltip-open')
        .removeClass('-flipped');
      $('html').attr('data-tooltip-open', 'false');
    });

  $(document).on('click', () => {

    if ($('html').attr('data-tooltip-open') === 'true') {
      $('.tooltip.-tooltip-open')
        .removeClass('-tooltip-open')
        .removeClass('-flipped');
      $('html').attr('data-tooltip-open', 'false');
    }
  });

  function positionTooltip(target) {
    const posLeft = target.offset().left,
          $tooltipParent = target.parent(),
          $tooltip = $tooltipParent.find('.tooltip-inner'),
          tooltipWidth = $tooltip.outerWidth(),
          tooltipHeight = $tooltip.outerHeight() + 25,
          posTop = target.offset().top - tooltipHeight,
          scrollPos = $(window).scrollTop(),
          headerHeight = $(window).width() > 1099 ? $('.site-header').height() : $('.mobile-nav-header').height(),
          offset = 22
        ;

    if (posTop < headerHeight || posTop < scrollPos) {
      $tooltipParent.addClass('-flipped');
    }

    $tooltip.css('transform', 'translateX(0)');
    if ((posLeft + offset) < tooltipWidth) {
      $tooltip.css('transform', `translateX(${tooltipWidth - posLeft - offset }px)`);
    }

  }

  if ($('.tooltip-rte').length) {
    tippy('.tooltip-rte', {
      trigger: 'click',
      animation: 'translate',
      content(ref) {
        const content = $(ref).attr('data-tooltip');

        const template = `
          <div class="tooltip-container -tippy">
            <div class="tooltip-inner">
              <a href="#" class="tooltip-close"><span class="sr-text">Close Tooltip</span></a>
              <p class="-small -gray-dark">${content}</p>
            </div>
          </div>`;
        return template;
      }
    });
  }
};
