/* global process */
// -----------------------------------------------------------------------------
// Component Includes
// -----------------------------------------------------------------------------
import extendjQuery from '@plugins/extend-jquery';
import siteHeader from '@components/site-header';
import mobileNav from '@components/mobile-nav';
import heroHome from '@heros/hero-home';
import modals from '@components/modals';
import tooltip from '@components/tooltip';
import SearchResults from './components/search/search-results/search-results';
import FindRpResults from './components/find-rp/find-rp-results/find-rp-results';
import FindCeResults from './components/find-ce/find-ce-results/find-ce-results';
import InternshipResults from './components/internship/internship-results/internship-results';
import AchResults from './components/ach/ach-results/ach-results';
import VerifyCfpResults from './components/verify-cfp/verify-cfp-results/verify-cfp-results';
import cfpDetail from './components/verify-cfp/cfp-detail/cfp-detail';
import NewsListing from '@components/news-listing';
import NewsListingCenter from './components/news-listing/news-listing-center';
import InsightsListing from '@components/insights-listing';
import ForEmployersListing from '@components/for-employers-listing';
import EventListing from '@components/event-listing';
import ComplianceResourceListing from '@components/compliance-resource-listing';
import breadcrumbs from '@components/breadcrumbs';
import subnav from '@components/subnav';
import facetedSearchResults from '@components/faceted-search-results';
import searchFacet from '@components/search-facet';
import heroFindCe from '@heros/hero-find-ce';
import heroVerifyCfp from '@heros/hero-verify-cfp';
import hoverGrid from '@components/hover-grid';
import codeStandards from '@components/code-standards';
import textRotator from '@components/text-rotator';
import certificationPath from '@components/certification-path';
import contentExpander from '@components/content-expander';
import contentAccordion from '@components/content-accordion';
import testimonialSlider from '@components/testimonial-slider';
import formValidation from '@plugins/form-validation';
import smoothScroll from '@plugins/smooth-scroll';
import cardLink from '@plugins/card-link';
import fullWidthPromo from '@components/full-width-promo';
import fileUpload from '@components/file-upload';
import socialShare from '@components/social-share';
import categoriesAccordion from '@components/categories-accordion';
import accountRegistration from '@components/account-registration';
import disciplinedList from '@components/disciplined-list';
import pieChart from '@components/pie-chart';
import demographicTables from '@components/demographic-tables';
import stickyFooter from '@components/sticky-footer';
import logoSlider from '@components/logo-slider';
import subnavCenter from '@components/subnav-center';
import glossary from '@components/glossary';
import textPanel from '@components/text-panel';
import expander from './components/_micros/expander/expander';
import heroTracker from '@heros/hero-tracker';
import ceDetail from './components/find-ce/ce-detail/ce-detail';
import ScholarshipLibraryResults from './components/scholarship-library/scholarship-library-results';
import alertBanner from '@components/alert-banner';
import accountRegistration2024 from './components/account-registration-2024/account-registration-2024';
// DASHBOARD
import findRpDashboard from '@dashboard/find-rp-dashboard';
import candidateDashboard from '@dashboard/candidate';
import proDashboard from '@dashboard/professional';
import heroDashboard from '@dashboard/hero-dashboard';
// import forums from '@dashboard/community-forums';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if (process.env.ENVIRONMENT === 'staging' || process.env.ENVIRONMENT === 'production') {
  // Only init Sentry on non-local environments
  // During upstream builds, the octo token will be included in the bundle and then replaced by Octo for each individual environment
  Sentry.init({
    dsn: 'https://f80d66afa947458faf05938b2a07401f@logs.interactive-strategies.com/5',
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0,
    environment: process.env.SENTRY_ENV_NAME_OCTO_TOKEN
  });
}

window.$ = jQuery;

(($) => {
  // quickly storing and deleting hash to prevent jumping
  window.urlHash = window.location.hash;
  // delete hash so the page won't scroll to it
  if (window.urlHash.length) {
    window.location.hash = '';
  }

  // Document Ready
  $(() => {
    // smooth scroll to anchor links if exists on page ready
    if (window.urlHash) {
      const $urlHashTarget = $(window.urlHash);
      if ($urlHashTarget.length) {
        setTimeout(() => {
          $('html, body').stop().animate({
            scrollTop: $urlHashTarget.offset().top - 100
          }, 500, 'swing');
          window.location.hash = window.urlHash;
        }, 200);
      } else {
        window.location.hash = window.urlHash;
      }
    }

    // -----------------
    // High-priority JS
    // -----------------
    extendjQuery();
    siteHeader();
    heroHome();
    alertBanner();

    if ($('.search-results').length) {
      const Search = new SearchResults();
      Search.init();
    }

    if ($('.find-rp-results').length || $('.hero-find-rp').length || $('.js-find-rp-search').length) {
      const Rp = new FindRpResults();
      Rp.init();
    }

    if ($('.find-ce-results').length || $('.hero-find-ce').length) {
      const Ce = new FindCeResults();
      Ce.init();
    }

    if ($('.verify-cfp-results').length || $('.hero-verify-cfp').length) {
      const Verify = new VerifyCfpResults();
      Verify.init();
    }

    if ($('.ach-results').length && $('.ach-results').attr('data-disabled') !== 'true') {
      const Ach = new AchResults();
      Ach.init();
    }

    // if ($('.ach-results-alt').length && $('.ach-results-alt').attr('data-disabled') !== 'true') {
    //   const Ach = new AchResultsAlt();
    //   Ach.init();
    // }

    if ($('.scholarship-library-results').length) {
      const Sl = new ScholarshipLibraryResults();
      Sl.init();
    }

    // normal news center news
    if ($('.news-listing').length) {
      const News = new NewsListing();
      News.init();
    }

    // news listing specific to the center for financial planning
    if ($('.news-listing-center').length) {
      const CenterNews = new NewsListingCenter();
      CenterNews.init();
    }

    // normal insights center insights
    if ($('.insights-listing').length) {
      const Insights = new InsightsListing();
      Insights.init();
    }

    // For Employers blog
    if ($('.for-employers-listing').length) {
      const ForEmployers = new ForEmployersListing();
      ForEmployers.init();
    }

    if ($('.event-listing').length) {
      const Events = new EventListing();
      Events.init();
    }

    if ($('.compliance-resource-listing').length) {
      const ComplianceResources = new ComplianceResourceListing();
      ComplianceResources.init();
    }

    if ($('.internship-results').length) {
      const In = new InternshipResults();
      In.init();
    }

    // -----------------
    // Low-priority JS
    // -----------------
    formValidation();
    smoothScroll();
    cardLink();
    mobileNav();
    modals();
    tooltip();
    breadcrumbs();
    heroFindCe();
    heroVerifyCfp();
    searchFacet();
    facetedSearchResults();
    hoverGrid();
    codeStandards();
    textRotator();
    certificationPath();
    contentExpander();
    contentAccordion();
    subnav();
    testimonialSlider();
    fullWidthPromo();
    fileUpload();
    categoriesAccordion();
    socialShare();
    accountRegistration();
    disciplinedList();
    cfpDetail();
    pieChart();
    demographicTables();
    stickyFooter();
    logoSlider();
    subnavCenter();
    glossary();
    expander();
    heroTracker();
    textPanel();
    ceDetail();
    accountRegistration2024();


    // -----------------
    // Dashboard specific
    // -----------------
    findRpDashboard();
    candidateDashboard();
    proDashboard();
    heroDashboard();
    // forums();

    // global js for closing js blocker and other things on escape
    $('html')
      .on('keyup', (e) => {
        if (e.key === 'Escape') {
          jsBlockerClose();
        }
      })
      .on('click', '.js-blocker', (e) => {
        e.preventDefault();
        jsBlockerClose();
      })
    ;

    function jsBlockerClose() {
      if ($('html').attr('data-facets-open') === 'true') {
        $('html').attr('data-facets-open', 'false');
      }
      if ($('html').attr('data-utility-menu-open') === 'true') {
        $('html').attr('data-utility-menu-open', 'false');
      }
      if ($('html').attr('data-mobile-nav-open') === 'true') {
        $('html').attr('data-mobile-nav-open', 'false');
        $('.mobile-nav-search-slideout').attr('data-mobile-search-open', 'false');
      }
      if ($('html').attr('data-toc-open') === 'true') {
        $('html').attr('data-toc-open', 'false');
      }
      if ($('.tooltip.-tooltip-open').length) {
        $('.tooltip.-tooltip-open')
          .removeClass('-tooltip-open')
          .removeClass('-flipped');
        $('html').attr('data-tooltip-open', 'false');
      }
    }
  });

})($);
