/* eslint-disable no-unused-vars */
import slick from 'slick-carousel';

export default () => {
  if ($('.testimonial-slider-slides').length) {
    $('.testimonial-slider-slides').map((i, slider) => {
      const numSlides = $(slider).find('.testimonial-slider-slide').length,
            $parent = $(slider).parent();

      $(slider).slick({
        autoplay: false,
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        pauseOnHover: false,
        draggable: true,
        cssEase: 'ease',
        appendArrows: $parent.find('.testimonial-slider-nav'),
        prevArrow: $parent.find('.testimonial-slider-nav-prev'),
        nextArrow: $parent.find('.testimonial-slider-nav-next')
      });

      if (numSlides <= 1) {
        $parent.find('.testimonial-slider-nav').remove();
      }
    });

  }
};
