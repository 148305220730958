export default () => {

  if ($('.hero-dashboard-nav').length) {
    $('.hero-dashboard-nav-mobile-header').on('click', (e) => {
      e.preventDefault();

      $(e.currentTarget).toggleClass('-open');
      $('.hero-dashboard-nav-mobile-dropdown').slideToggle();
    });

    let mobileOnce = false;
    $(window).on('resize', (e) => {

      if ($(e.currentTarget).outerWidth() > 1099) {
        mobileOnce = true;
        $('.hero-dashboard-nav-mobile-dropdown').show();
      } else if ($(e.currentTarget).outerWidth() < 1100 && mobileOnce) {
        $('.hero-dashboard-nav-mobile-dropdown').hide();
        $('.hero-dashboard-nav-mobile-header').removeClass('-open');
        mobileOnce = false;
      }
    });

  }
};
