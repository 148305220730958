import isSticky from '@plugins/isSticky';

export default () => {
  if ($('.sticky-footer').length) {
    isSticky();
    $('.sticky-footer').isSticky({
      zIndex: 8,
      stopper: $('.site-footer'),
      stuckClass: '-stuck',
      offset: 0,
      force: true,
      bottom: true,
      delay: true
    }).init();
  }
};